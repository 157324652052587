<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline>
        <el-form-item>
          <AutoQuery
            v-model="params.name"
            placeholder="Shelves No"
            :params="{ className: 'storeInfo', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <AutoQuery
            v-model="params.client"
            placeholder="Customer"
            :params="{ className: 'clientInfo', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button type="success" @click="showNew" size="small">
            新增
          </el-button>
          <el-button type="info" @click="showMultiClient" size="small">
            批量选择
          </el-button>
          <el-button
            type="warning"
            size="small"
            @click="exportAll"
            :disabled="storeSelection.length == 0"
          >
            批量导出
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        @selection-change="onSelection"
        v-loading="isLoading"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index"></el-table-column>
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          :width="actions.length * 80"
          v-if="actions.length"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="n.click(row)"
              size="mini"
              v-for="(n, i) in actions"
              :key="i"
              :disabled="n.disabled ? n.disabled(row) : false"
              :class="n.type ? 'ui-btn-' + n.type : 'ui-btn-primary'"
            >
              {{ n.label }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog title="新增储位" :visible.sync="isShow" width="50%">
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="储位号">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Detail"
      :visible.sync="isShowDetail"
      width="90%"
      top="10px"
      @closed="errMessage = ''"
    >
      <div class="ui-detail-title">
        <span>In warehouse quantity: {{ detailLen }} pcs</span>
        <span>Total estimate weight: {{ totalWeight }}</span>
        <span>Total estimate CBM: {{ totalCbm }} </span>
      </div>
      <ExpressDetail
        :storeId="currentItem"
        @afterOut="afterOut"
        :len.sync="detailLen"
        :key="currentItem.id"
        :weight.sync="totalWeight"
        :cbm.sync="totalCbm"
      ></ExpressDetail>
    </el-dialog>

    <el-dialog
      title="批量选择"
      :visible.sync="isShowMultiClient"
      @closed="onShowMultiClientClosed"
    >
      <el-form :model="multiClient" label-width="120px">
        <el-form-item label="选择储位">
          <el-select
            v-model="multiClient.store"
            placeholder="输入并选择 (多选)"
            filterable
            remote
            multiple
            :remote-method="getStore"
            class="ui-select-block"
            :disabled="multiClient.client && multiClient.client.length > 0"
          >
            <ElOption
              v-for="n in stores"
              :key="n.get('name')"
              :value="n.get('name')"
              :label="n.get('name')"
            ></ElOption>
          </el-select>
        </el-form-item>
        <ElFormItem label="选择客户">
          <el-select
            v-model="multiClient.client"
            placeholder="输入并选择 (多选)"
            filterable
            remote
            multiple
            :remote-method="getClient"
            class="ui-select-block"
            :disabled="multiClient.store && multiClient.store.length > 0"
          >
            <ElOption
              v-for="n in clients"
              :key="n.id"
              :value="n.get('name')"
              :label="n.get('name')"
            ></ElOption>
          </el-select>
        </ElFormItem>
      </el-form>

      <span slot="footer">
        <el-button type="primary" @click="onSaveClient">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="转移储位" :visible.sync="isShowMove">
      <el-form :model="storeMove" v-if="currentItem.id">
        <ElFormItem label="当前储位">
          {{ currentItem.get("name") }}
        </ElFormItem>
        <el-form-item label="目标储位">
          <AutoQuery
            v-model="storeMove.id"
            placeholder="请选择"
            :params="{ className: 'storeInfo', field: 'name' }"
            @select="onSelect"
          ></AutoQuery>
        </el-form-item>
        <el-form-item
          label="已存信息"
          v-if="currentStore.id && currentStore.get('client')"
        >
          <span style="padding: 0 30px 0 0">
            客户: {{ currentStore.get("client") }}
          </span>
          <span>数量: {{ currentStore.get("count") }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSaveMove" :disabled="canSubmit">{{
          isMoving ? "加载中" : "确定"
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import ExpressDetail from "../components/ExpressDetail.vue";
export default {
  components: {
    ExpressDetail,
  },
  data() {
    return {
      totalCbm: 0,
      currentUser: {},
      detailLen: 0,
      totalWeight: 0,
      password: "atm",
      isShow: false,
      isShowMultiClient: false,
      isShowMove: false,
      storeMove: {},
      currentStore: {},
      multiClient: {
        store: [],
        client: [],
      },
      clients: [],
      stores: [],
      storeSelection: [],
      errMessage: "",
      isLoading: false,
      form: {},
      isShowMulti: false,
      currentSelection: [],
      multiForm: {},
      isMoving: false,
      page: {
        no: 1,
        size: 100,
        total: 0,
      },
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      cargoInfo: {},
      currentParams: {},
      params: {},
      defaultSort: { order: "descending", prop: "updatedAt" },
      actions: [
        {
          label: "明细",
          disabled: (item) => {
            return item.get("count") <= 0;
          },
          click: (item) => {
            this.currentItem = item;
            this.isShowDetail = true;
          },
        },
        {
          label: "转移",
          click: (item) => {
            this.currentItem = item;
            this.isShowMove = true;
          },
        },
        {
          label: "重置",
          type: "info",
          click: (item) => {
            this.$prompt("请输入密码").then(({ value }) => {
              if (value === this.password) {
                this.$confirm("确定重置该储位?", {
                  type: "info",
                }).then(() => {
                  item.set("count", 0);
                  item.save().then(() => {
                    this.$message.success("重置成功");
                    this.query();
                  });
                });
              } else {
                this.$message.error("密码错误");
              }
            });
          },
        },
        {
          label: "删除",
          type: "danger",
          disabled: (item) => {
            return item.get("count") > 0;
          },
          click: (item) => {
            this.$prompt("请输入密码").then(({ value }) => {
              if (value === this.password) {
                this.$confirm("确定删除?").then(() => {
                  item.destroy().then(() => {
                    this.$message.success("删除成功");
                    this.query();
                  });
                });
              } else {
                this.$message.error("密码错误");
              }
            });
          },
        },
      ],
      columns: [
        {
          label: "储位号",
          prop: "name",
          show: ["table", "form"],
        },
        {
          label: "已存客户",
          prop: "client",
          show: ["table"],
        },
        {
          label: "已存数量",
          prop: "count",
          show: ["table"],
        },
        {
          label: "更新时间",
          prop: "updatedAt",
          show: ["table"],
          format: (item) => {
            return this.formatDate(item.updatedAt);
          },
        },
        {
          label: "创建时间",
          prop: "createdAt",
          show: ["table"],
          format: (item) => {
            return this.formatDate(item.createdAt);
          },
        },
        {
          label: "操作者",
          prop: "credit",
          show: ["table"],
          format: (item) => {
            if (item.get("operator")) {
              return item.get("operator").get("username");
            }
          },
        },
      ],
      currentItem: {},

      isShowDetail: false,
      detailList: [],
      detailColumns: [
        {
          label: "Tracking No",
          prop: "id",
        },
        {
          label: "Customer",
          prop: "client",
          format: (item) => {
            let clientId = item.get("clientId");
            return clientId ? clientId.get("name") : "";
          },
        },
        {
          label: "Supplier",
          prop: "company",
        },
        {
          label: "In Date",
          prop: "createdDate",
          format: (value) => {
            return this.formatDate(value.get("createdDate"));
          },
        },
        {
          label: "Status",
          prop: "status",
          format: (item) => {
            let maps = [
              {
                value: "入库",
                label: "In",
              },
              {
                value: "出库",
                label: "Out",
              },
              {
                value: "未确认",
                label: "Unknow",
              },
            ];
            return maps.find((n) => n.value == item.get("status")).label;
          },
        },
      ],
      selection: [],
    };
  },

  inject: ["refresh"],

  computed: {
    tableHeight() {
      return this.$store.state.tableHeight
    },
    canSubmit() {
      return (
        this.isMoving ||
        !(this.currentStore.id && this.currentStore.get("count") == 0)
      );
    },
  },

  watch: {
    "multiForm.cargoNumber"(val) {
      localStorage.cargoNumber = val;
    },
    "multiForm.mark"(val) {
      localStorage.mark = val;
    },
  },

  methods: {
    afterOut() {
      this.isShowDetail = false;
      this.isShow = false;
      this.query();
    },
    onSelect(item) {
      this.currentStore = item.obj;
    },
    onSaveMove() {
      this.isMoving = true;
      let query = new this.AV.Query("expressInfo");
      query.equalTo("storeId", this.currentItem);
      query.equalTo("status", "入库");
      query.find().then((list) => {
        this.$log({
          name: "expressInfo",
          url: "/Store",
          action: "find",
          params: { storeId: this.currentItem, status: "入库" },
        });
        let objs = list.map((n) => {
          n.set("storeId", this.currentStore);
          n.set("storeName", this.currentStore.get("name"));
          return n;
        });
        this.currentStore.set("clientId", this.currentItem.get("clientId"));
        this.currentStore.set("client", this.currentItem.get("client"));
        this.currentStore.set("count", list.length);
        objs.push(this.currentStore);
        this.AV.Object.saveAll(objs).then(() => {
          this.$message.success("转移成功");
          this.currentItem.set("count", 0);
          this.currentItem.unset("clientId");
          this.currentItem.unset("client");
          this.currentItem.save().then(() => {
            this.isMoving = false;
            this.isShowMove = false;
            this.currentItem = {};
            this.currentStore = {};
          });
        });
      });
    },
    onShowMultiClientClosed() {
      this.multiClient = {
        store: [],
        client: [],
      };
    },
    exportAll() {
      if (this.storeSelection.length) {
        let data = [["ATM"], ["COUNT", "STK", "NAME", "QUANTITY"]];
        this.storeSelection.forEach((n, idx) => {
          data.push([idx + 1, n.get("name"), n.get("client"), n.get("count")]);
        });

        let filename = "导出储位.xlsx";
        let ws_name = "SheetJS";
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        XLSX.writeFile(wb, filename);
      }
    },
    onSelection(selection) {
      this.storeSelection = selection;
    },
    getStore(key) {
      if (key) {
        let query = new this.AV.Query("storeInfo");
        key = key.toUpperCase();
        query.startsWith("name", key);
        query.find().then((list) => {
          this.stores = list;
        });
      } else {
        this.stores = [];
      }
    },
    showMultiClient() {
      this.isShowMultiClient = true;
    },
    onSaveClient() {
      let key = "";
      let value = "";
      if (this.multiClient.client && this.multiClient.client.length) {
        key = "client";
        value = this.multiClient.client.map((n) => `"${n}"`).join(",");
      }
      if (this.multiClient.store && this.multiClient.store.length) {
        key = "name";
        value = this.multiClient.store.map((n) => `"${n}"`).join(",");
      }
      if (key && value) {
        let cql = `select * from storeInfo where ${key} in (${value}) order by client asc`;
        this.AV.Query.doCloudQuery(cql).then((data) => {
          let { results } = data;
          this.dataList = results;
          this.isShowMultiClient = false;
        });
      }
    },
    getClient(key) {
      if (key) {
        let query = new this.AV.Query("clientInfo");
        key = key.toUpperCase();
        query.startsWith("name", key);
        query.find().then((list) => {
          this.$log({
            name: "clientInfo",
            url: "/Store",
            action: "find",
            params: { name: key },
          });
          this.clients = list;
        });
      } else {
        this.clients = [];
      }
    },
    checkClient(name) {
      if (name) {
        let query = new this.AV.Query("clientInfo");
        name = name.toUpperCase();
        query.equalTo("name", name);
        return query.first().then((ret) => {
          this.$log({
            name: "clientInfo",
            url: "/Store",
            action: "first",
            params: { name },
          });
          return ret ? true : false;
        });
      }
      return false;
    },
    // 批量导入客户
    // importClient() {
    //   let list = "";
    //   let objs = list.split(",").forEach((n) => {
    //     let name = n.toUpperCase();
    //     this.checkClient(name).then((ret) => {
    //       if (!ret) {
    //         let obj = new this.clientInfo();
    //         obj
    //           .save({
    //             name,
    //           })
    //           .then(
    //             () => {
    //               console.log("success");
    //             },
    //             (err) => {
    //               console.log(err);
    //             }
    //           );
    //         return;
    //       }
    //       console.log("已存在" + name);
    //     });
    //   });
    // },
    // 批量导出储位
    multiSave() {
      let list = "";
      let objs = list.split(",").map((n) => {
        let obj = new this.storeInfo();
        obj.set("name", n);
        return obj;
      });
      this.AV.Object.saveAll(objs).then((ret) => {
        console.log(ret);
      });
    },
    // 批量出库
    getCargoInfo(params) {
      let query = new this.AV.Query("cargoInfo");
      query.equalTo("cargoNumber", params.cargoNumber);
      query.equalTo("mark", params.mark);
      query.equalTo("clientId", params.clientId);
      return query.first().then((ret) => {
        return ret;
      });
    },
    onSelectionChange(selection) {
      this.selection = selection;
    },
    onPageChange(page) {
      this.page.no = page;
      this.query();
    },
    onSizeChange(size) {
      this.page.size = size;
      this.query();
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item);
      } else {
        return item.get(col.prop);
      }
    },
    formatDate(d) {
      if (!d) {
        return "";
      }
      let fm = (n) => {
        return n < 10 ? "0" + n.toString() : n.toString();
      };
      let arr = [];
      arr.push(d.getFullYear());
      arr.push(fm(d.getMonth() + 1));
      arr.push(fm(d.getDate()));
      return arr.join("-");
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order };
      this.page.no = 1;
      this.query();
    },
    query() {
      this.isLoading = true;
      let query = new this.AV.Query("storeInfo");
      query.include("clientId");
      query.include("operator");
      query[this.defaultSort.order](this.defaultSort.prop);
      let keys = Object.keys(this.params);
      keys.forEach((key) => {
        let val = this.params[key];
        if (val) {
          query.startsWith(key, val.toUpperCase());
        }
      });

      query.limit(this.page.size);
      query.skip(this.page.size * (this.page.no - 1));

      query.find().then((list) => {
        this.dataList = list;
        this.isLoading = false;
      });
      query.count().then((count) => {
        this.page.total = count;
      });
    },

    showNew() {
      this.currentItem = {};
      this.form = {};
      this.isShow = true;
    },
    showEdit(item) {
      this.currentItem = item;
      this.isDelivery = item.get("status") == "出库";
      this.columns.forEach((n) => {
        if (n.show.includes("form")) {
          this.$set(this.form, n.prop, item.get(n.prop));
        }
      });
      this.isShow = true;
    },

    checkName(name) {
      let query = new this.AV.Query("storeInfo");
      query.equalTo("name", name);
      return query.first().then((ret) => {
        return ret ? true : false;
      });
    },

    onSave() {
      let postData = { ...this.form };

      // 修改已有客户
      if (this.currentItem.id) {
        // 保存可以修改的部分
        this.currentItem.save(postData).then(() => {
          this.$message.success("修改成功");
          this.isShow = false;
          this.query();
        });
        return;
      }

      let { name } = postData;
      this.checkName(name).then((ret) => {
        if (ret) {
          this.$message.error("该储位号已被使用, 请修改重试");
          return;
        }
        let obj = new this.storeInfo();
        let storeInfo = { ...postData, operator: this.currentUser };
        obj.save(storeInfo).then(() => {
          this.$message.success("添加成功");
          this.isShow = false;
          this.query();
        });
        this.$mongo("storeInfo", storeInfo);
      });
    },

    getExpressByStore(storeName, item) {
      let query = new this.AV.Query("expressInfo");
      query.equalTo("storeName", storeName);
      query.notEqualTo("status", "出库");
      query.count().then((count) => {
        this.$log({
          name: "expressInfo",
          url: "/Store",
          action: "count",
          params: { storeName },
        });
        if (count === 0) {
          item.set("count", 0);
          item.save();
        }
      });
    },

    test() {
      let query = new this.AV.Query("storeInfo");
      query.include("clientId");
      query.include("operator");
      query[this.defaultSort.order](this.defaultSort.prop);
      let keys = Object.keys(this.params);
      keys.forEach((key) => {
        let val = this.params[key];
        if (val) {
          query.startsWith(key, val.toUpperCase());
        }
      });

      query.limit(1000);
      query.greaterThan("count", 0);
      query.skip(0);
      query.equalTo("name", "S141");
      // query.skip(this.page.size * (this.page.no - 1))

      query.find().then((list) => {
        list.forEach((item) => {
          let name = item.get("name");
          this.getExpressByStore(name, item);
        });
      });
    },
  },

  mounted() {
    this.currentUser = this.AV.User.current();
    this.expressInfo = this.AV.Object.extend("expressInfo");
    this.storeInfo = this.AV.Object.extend("storeInfo");
    this.cargoInfo = this.AV.Object.extend("cargoInfo");

    if (this.$route.query.name) {
      this.params.client = this.$route.query.name;
    }

    this.query();
    this.multiForm = {
      cargoNumber: localStorage.cargoNumber || "",
      mark: localStorage.mark || "",
    };

    this.test();
  },
};
</script>

<style lang="less">
.ui {
  &-detail-title {
    font-size: 20px;
    margin: 0 0 20px;

    span {
      display: inline-block;
      margin: 0 50px 0 0;
    }
  }
  &-btn {
    &-primary {
      color: #409eff !important;
    }
    &-danger {
      color: #f56c6c !important;
    }
    &-info {
      color: #909399 !important;
    }
  }
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
  &-select-block {
    display: block !important;
  }
}
</style>
