import Vue from 'vue'
import { Message } from 'element-ui'
import Router from 'vue-router'
import List from './views/List.vue'
import Admin from './views/Admin.vue'
import Client from './views/Client.vue'
import Store from './views/Store.vue'
import Company from './views/Company.vue'
import Cargo from './views/Cargo.vue'
import Account from './views/Account.vue'
import Finance from './views/Finance.vue'
import Credit from './views/Credit.vue'
import Setting from './views/Setting.vue'
import SettingCategory from './views/SettingCategory.vue'
import SettingPrice from './views/SettingPrice.vue'
import SettingCredit from './views/SettingCredit.vue'
import SettingExpress from './views/SettingExpress.vue'
import SettingCountry from './views/SettingCountry.vue'
import ProductType from './views/ProductType.vue'
import Package from './views/Package.vue'
import Article from './views/Article.vue'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'main',
      redirect: '/admin/list',
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      redirect: '/admin/list',
      children: [
        {
          path: 'list',
          component: List,
        },
        {
          path: 'client',
          component: Client,
        },
        {
          path: 'store',
          component: Store,
        },
        {
          path: 'company',
          component: Company,
        },
        {
          path: 'cargo',
          component: Cargo,
        },
        {
          path: 'finance',
          component: Finance,
        },
        {
          path: 'credit',
          component: Credit,
        },
        {
          path: 'account',
          component: Account,
        },
        {
          path: 'setting',
          component: Setting,
        },
        {
          path: 'setting/express',
          component: SettingExpress,
        },
        {
          path: 'setting/category',
          component: SettingCategory,
        },
        {
          path: 'setting/price',
          component: SettingPrice,
        },
        {
          path: 'setting/credit',
          component: SettingCredit,
        },
        {
          path: 'setting/country',
          component: SettingCountry,
        },
        {
          path: 'productType',
          component: ProductType,
        },
        {
          path: 'package',
          component: Package,
        },
        {
          path: 'article',
          component: Article,
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  let lastLoginTime = localStorage.lastLoginTime
  let now = new Date().getTime()
  let diff = now - lastLoginTime
  // 超过24小时未刷新将会强制刷新
  if (diff >= 1000 * 60 * 60 * 24) {
    Message.warning('长时间未刷新, 系统将自动刷新当前页面')
    setTimeout(() => {
      location.reload()
    }, 3000)
  }
  next()
})

export default router
