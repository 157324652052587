<template>
  <div class="ui-main">
    <div class="ui-content">
      <div class="ui-query">
        <el-form :model="params" size="mini" inline>
          <el-form-item>
            <el-date-picker
              clearable
              v-model="params.date"
              type="daterange"
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.id"
              placeholder="Track Number"
              :params="{ className: 'expressInfo', field: 'id' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.storeName"
              placeholder="Shelves No"
              :params="{ className: 'storeInfo', field: 'name' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.client"
              placeholder="Customer"
              :params="{ className: 'clientInfo', field: 'name' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <el-select
              clearable
              v-model="params.company"
              placeholder="Supplier"
              filterable
            >
              <el-option
                v-for="item in companyList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="params.status"
              placeholder="Status"
              filterable
              clearable
            >
              <el-option
                v-for="item in status"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.logisticsNumber"
              placeholder="Cargo No"
              clearable
              @keyup.native.enter="query"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.mark"
              placeholder="Mark"
              clearable
              @keyup.native.enter="query"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="params.username"
              placeholder="Operator"
              clearable
              filterable
            >
              <el-option
                v-for="(n, i) in profileList"
                :key="i"
                :label="n.username"
                :value="n.username"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-autocomplete
              style="width: 100%"
              v-model="params.address"
              :fetch-suggestions="querySearch"
              placeholder="Client Address"
              clearable
            ></el-autocomplete>
            <!-- <el-select
              v-model="params.address"
              placeholder="Client Address"
              clearable
              filterable
            >
              <el-option
                v-for="(n, i) in addressList"
                :key="i"
                :label="n.label"
                :value="n.value"
              ></el-option>
            </el-select> -->
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="12">
            <!-- <el-button
              type="primary"
              size="mini"
              class="file"
              icon="el-icon-upload2"
            >
              导入出库
              <input
                type="file"
                @change="importDelivery($event)"
                value="导入"
              />
            </el-button>
            <el-button
              type="primary"
              @click="delivery"
              size="mini"
              :disabled="!selection.length"
              icon="el-icon-goods"
              >出库</el-button
                        >-->
            <!-- <el-button
              type="danger"
              @click="removeAll"
              size="mini"
              icon="el-icon-refresh"
              >删除</el-button
                        >-->
            <el-button
              type="warning"
              @click="reload"
              size="mini"
              icon="el-icon-refresh"
              >刷新</el-button
            >

            <el-button
              type="info"
              @click="printAll"
              size="mini"
              icon="el-icon-printer"
              >批量打印</el-button
            >
            <el-button
              type="primary"
              @click="query"
              size="mini"
              icon="el-icon-search"
              >搜索</el-button
            >
            <!-- <el-button type size="mini" class="file">
              识别图片
              <input type="file" @change="decode($event)" value="导入">
                        </el-button>-->
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button
              type="success"
              @click="showNew"
              size="mini"
              icon="el-icon-circle-plus-outline"
              >新增</el-button
            >
          </el-col>
        </el-row>
        <!-- <el-button type="primary" size="small" class="file">
                  导入 <input type="file" @change="importf($event)" value="导入" />
                </el-button>-->
      </div>

      <div class="ui-table">
        <el-table
          size="mini"
          :data="dataList"
          :height="tableHeight"
          @selection-change="onSelectionChange"
          @sort-change="onSortChange"
          v-loading="isLoading"
          :cell-style="{ padding: 0 }"
        >
          <el-table-column type="selection"></el-table-column>
          <template v-for="col in columns">
            <el-table-column
              sortable="custom"
              :prop="col.prop"
              :key="col.id"
              :label="col.label"
              :min-width="col.width || 120"
              v-if="col.show.includes('table')"
            >
              <template slot-scope="{ row }">
                <template v-if="col.props">
                  <template v-for="(p, idx) in col.props">
                    <p v-if="row.get(p)" :key="idx">
                      <el-tag
                        :type="idx == 1 ? 'primary' : 'success'"
                        size="mini"
                      >
                        {{ col.labels[idx] }}
                      </el-tag>
                      {{ formatDate(row.get(p)) }}
                    </p>
                  </template>
                </template>
                <template v-else-if="col.prop == 'status'">
                  <template v-for="(s, i) in status">
                    <el-tag
                      size="mini"
                      :type="s.type"
                      :key="i"
                      v-if="s.label == row.get('status')"
                      @click="showDetail(row)"
                    >
                      {{ row.get('status') }}
                    </el-tag>
                  </template>
                </template>
                <template v-else-if="col.prop === 'mode'">
                  <el-tag
                    size="small"
                    effect="dark"
                    :hit="false"
                    :color="modesColor[row.get('mode').replace('-no', '')]"
                  >
                    {{ row.get('mode').replace('-no', '') }}
                  </el-tag>
                </template>
                <template v-else>{{ formatData(row, col) }}</template>
              </template>
            </el-table-column>
          </template>

          <el-table-column label="操作" :width="220" fixed="right">
            <template slot-scope="{ row }">
              <el-popover
                placement="left"
                trigger="click"
                v-if="row.get('file')"
                popper-class="popper-class"
              >
                <img :src="row.url" slot="reference" class="ui-img-thumbnail" />
                <img :src="row.oUrl" class="ui-img-preview" />
              </el-popover>
              <el-button
                type="text"
                @click="showEdit(row)"
                size="mini"
                :disabled="row.get('status') == '出库'"
                >修改</el-button
              >
              <el-button
                type="text"
                @click="remove(row)"
                size="mini"
                style="color: #f56c6c"
                >删除</el-button
              >
              <el-popconfirm
                confirm-button-text="普通"
                cancel-button-text="加密"
                icon="el-icon-info"
                icon-color="red"
                cancel-button-type="info"
                title="选择打印模式"
                @confirm="onPrint(row)"
                @cancel="onPrint(row, 'encryption')"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="color: #909399"
                >
                  打印
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="ui-page">
        <el-pagination
          layout="total, sizes, prev, pager, next"
          :total="page.total"
          :page-sizes="[50, 100, 500, 1000]"
          :page-size="page.size"
          :current-page="page.no"
          @current-change="onPageChange"
          @size-change="onSizeChange"
        ></el-pagination>
      </div>

      <el-dialog
        title="物流信息"
        :visible.sync="isShow"
        width="80%"
        top="10px"
        :key="isReset"
        @closed="onClosed"
      >
        <div class="loading" v-loading="submitLoading">
          <div>
            <el-form
              :model="form"
              ref="form"
              label-width="90px"
              class="ui-form"
            >
              <el-form-item label="拍照" style="display: block">
                <div class v-if="currentItem.url">
                  <div class>已关联图片</div>
                  <div class>
                    <el-popover placement="top" popper-class="popper-class">
                      <img
                        :src="currentItem.url"
                        slot="reference"
                        class="cursor:pointer"
                      />
                      <img :src="currentItem.oUrl" />
                    </el-popover>
                  </div>
                </div>
                <div class>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="getImg"
                    :disabled="decoding"
                    >{{ decoding ? '加载中..' : '获取最新照片' }}</el-button
                  >
                </div>

                <!-- 图片列表 -->
                <div class="ui-imgs">
                  <template v-for="(n, i) in imgs">
                    <div class="ui-imgs-inner" :key="i">
                      <el-popover
                        placement="top"
                        trigger="click"
                        popper-class="popper-class"
                        :key="i"
                      >
                        <img :src="n.url" slot="reference" class="img" />
                        <img :src="n.oUrl" class="ui-img-preview" />
                      </el-popover>
                      <p>
                        <el-button
                          type="danger"
                          size="mini"
                          @click="removeImg(i)"
                          >删除</el-button
                        >
                      </p>
                    </div>
                  </template>
                </div>
              </el-form-item>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="入库日期">
                    <el-date-picker
                      clearable
                      type="date"
                      v-model="form.createdDate"
                      placeholder="选择日期时间"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="出库日期">
                    <el-date-picker
                      clearable
                      type="date"
                      v-model="form.deliveryDate"
                      placeholder="选择日期时间"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="单号" prop="id" :rules="formRules.text">
                    <el-input
                      v-model.trim="form.id"
                      placeholder="请输入"
                      clearable
                      ref="idInput"
                      @input="toUpper('id', $event)"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item
                    label="客户"
                    prop="client"
                    :rules="formRules.select"
                  >
                    <el-select
                      v-model="form.client"
                      placeholder="请输入客户名/客户id"
                      ref="clientInput"
                      filterable
                      clearable
                      remote
                      :remote-method="getClient"
                      :loading="clientLoading"
                      default-first-option
                      @change="getStore"
                      :disabled="isEdit"
                    >
                      <el-option
                        v-for="c in clients"
                        :key="c.id"
                        :label="c.get('name')"
                        :value="c.get('name')"
                      ></el-option>
                      <el-option
                        label="未确认客户"
                        value="未确认客户"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="储货号"
                    prop="storeName"
                    :rules="formRules.select"
                  >
                    <el-select
                      v-model="form.storeName"
                      placeholder="请输入"
                      clearable
                      :disabled="isEdit"
                    >
                      <el-option
                        v-for="c in stores"
                        :key="c.id"
                        :label="c.get('name')"
                        :value="c.get('name')"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="供应商"
                    prop="company"
                    :rules="formRules.select"
                  >
                    <el-select
                      v-model="form.company"
                      placeholder="请选择"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in companyList"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="状态"
                    prop="status"
                    :rules="formRules.select"
                  >
                    <el-select
                      v-model="form.status"
                      placeholder="请选择"
                      filterable
                      clearable
                      :disabled="isDelivery"
                    >
                      <template v-for="item in status">
                        <el-option
                          :key="item.label"
                          :label="item.label"
                          :value="item.label"
                          v-if="item.label != '出库'"
                        ></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="入仓地址"
                    prop="address"
                    :rules="formRules.select"
                  >
                    <el-select
                      v-model="form.address"
                      placeholder="请选择"
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in address"
                        :key="item.label"
                        :label="item.label"
                        :value="item.label"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="重量"
                    prop="weight"
                    :rules="formRules.text"
                  >
                    <el-input
                      v-model="form.weight"
                      placeholder="请输入"
                      clearable
                      @keyup.enter.native="onWeightInput"
                      ref="weightInput"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="体积重" prop="v_weight" class="lwh">
                    <el-row :gutter="10">
                      <el-col :span="5">
                        <el-input
                          v-model="form.length"
                          placeholder="长"
                          clearable
                        >
                          <span slot="append">长/cm</span>
                        </el-input>
                      </el-col>
                      <el-col :span="5">
                        <el-input
                          v-model="form.width"
                          placeholder="宽"
                          clearable
                        >
                          <span slot="append">宽/cm</span>
                        </el-input>
                      </el-col>
                      <el-col :span="5">
                        <el-input
                          v-model="form.height"
                          placeholder="高"
                          clearable
                        >
                          <span slot="append">高/cm</span>
                        </el-input>
                      </el-col>
                      <el-col :span="7">
                        <el-input v-model="lwh" placeholder="体积重" clearable>
                          <span slot="append">长宽高/6000</span>
                        </el-input>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8"></el-col>
                <el-col :span="8"></el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="运输方式">
                    <el-radio-group v-model="form.mode">
                      <template v-for="n in modes">
                        <el-radio
                          :label="n.value"
                          :key="n.value"
                          style="width: 70px; line-height: 40px"
                        >
                          {{ n.label }}
                        </el-radio>
                      </template>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="CBM(体积)">
                    <el-input v-model="form.cbm" placeholder="请输入" clearable>
                      <span slot="append">立方米</span>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="C/F快递费">
                    <el-input
                      v-model="form.cost"
                      placeholder="请输入"
                      clearable
                    >
                      <span slot="append">元</span>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="备注">
                <el-input
                  v-model="form.remark"
                  placeholder="请输入"
                  type="textarea"
                ></el-input>
              </el-form-item>

              <div class="ui-title">
                <span style="padding: 0 10px 0 0">附加信息</span>
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  icon="el-icon-circle-plus"
                  @click="append"
                  >增加</el-button
                >
              </div>
              <template v-for="(ext, idx) in extraInfo">
                <div class :key="idx">
                  <el-row>
                    <el-col :span="6">
                      <el-form-item label="品名">
                        <ProductType
                          placeholder="请选择"
                          v-model="ext.name"
                          :options="productType"
                          :keys="{
                            label: 'name',
                            value: 'name',
                            info: 'ename',
                          }"
                        ></ProductType>
                        <!-- <el-select
                          v-model="ext.name"
                          placeholder="请选择"
                          filterable
                        >
                          <el-option
                            v-for="(item, i) in productType"
                            :key="item.name + '-' + i"
                            :label="item.name"
                            :value="item.name"
                          >
                            <span style="float: left">{{ item.name }}</span>
                            <span
                              style="
                                float: right;
                                color: #8492a6;
                                font-size: 13px;
                              "
                              >{{ item.ename }}</span
                            >
                          </el-option>
                        </el-select> -->
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="数量">
                        <el-input
                          v-model="ext.count"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="是否牌子">
                        <el-switch
                          v-model="ext.isBrand"
                          active-text="是"
                          inactive-text="否"
                        ></el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item v-if="extraInfo.length > 1">
                        <el-button
                          type="danger"
                          size="mini"
                          icon="el-icon-remove"
                          @click="removeExtra(idx)"
                          >删除</el-button
                        >
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </template>
            </el-form>
          </div>

          <div class="el-dialog__footer">
            <span>
              <el-button @click="isShow = false">取 消</el-button>
              <el-button type="primary" @click="onSave">确 定</el-button>
              <el-button type="info" @click="saveAndPrint"
                >保存并打印</el-button
              >
            </span>
          </div>
        </div>
      </el-dialog>

      <el-dialog title="批量设置出库" :visible.sync="isShowMulti">
        <el-form :model="multiForm" label-width="100px" ref="multiForm">
          <el-form-item
            label="出库单号"
            prop="cargoNumber"
            :rules="formRules.text"
          >
            <el-input
              v-model.trim="multiForm.cargoNumber"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="麦头" prop="mark" :rules="formRules.text">
            <el-input
              v-model.trim="multiForm.mark"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="isShow = false">取 消</el-button>
          <el-button type="primary" @click="onSaveMulti">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="预览" :visible.sync="isShowPreview" fullscreen>
        <div class style="text-align: center">
          <img :src="currentImg.oUrl" alt />
        </div>
      </el-dialog>
    </div>

    <div class="ui-print" v-for="(item, idx) in printList" :key="idx">
      <div class="inner">
        <div class="title">
          <p>ATM CARGO</p>
        </div>
        <table v-if="item.id">
          <tr>
            <td>
              Tracking
              <br />Number <br />(单号)
            </td>
            <td colspan="3">
              <span class="td info">{{ item.get('id') }}</span>
            </td>
          </tr>
          <tr>
            <td>
              Name
              <br />（客户名）
            </td>
            <td colspan="3">
              <span class="td name">{{
                item.printType === 'encryption'
                  ? desensitization(item.get('client'))
                  : item.get('client')
              }}</span>
            </td>
          </tr>
          <tr>
            <td>
              Date
              <br />（入仓日期）
            </td>
            <td>
              <span class="td">{{ item.get('createdDate') }}</span>
            </td>
            <td>
              Supplier
              <br />（供应商）
            </td>
            <td>
              <span class="td">{{ item.get('company') }}</span>
            </td>
          </tr>
          <tr>
            <td>
              STK NO.
              <br />(储位号)
            </td>
            <td colspan="3">
              <span class="td info">{{ item.get('storeName') }}</span>
            </td>
          </tr>
          <tr>
            <td>
              Remark
              <br />(备注)
            </td>
            <td colspan="3">
              <span class="td">{{ item.get('remark') }}</span>
            </td>
          </tr>
          <tr>
            <td>
              Goods List
              <br />(货物明细)
            </td>
            <td colspan="3">
              <span class="td">{{ item.showInfo }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-dialog
      :title="'In warehouse quantity: ' + detailLen + ' pcs'"
      :visible.sync="isShowDetail"
      width="90%"
      top="10px"
    >
      <template slot="title">
        <el-row>
          <el-col :span="5"
            ><label style="padding: 0 10px 0 0">In warehouse quantity:</label>
            {{ detailLen }}</el-col
          >
          <el-col :span="5"
            ><label style="padding: 0 10px 0 0">Total estimate weight:</label>
            {{ weight }}</el-col
          >
        </el-row>
      </template>
      <ExpressDetail
        :storeId="currentItem"
        @afterOut="afterOut"
        :len.sync="detailLen"
        :weight.sync="weight"
        :key="currentItem.id"
      ></ExpressDetail>
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import Quagga from 'quagga'
import ExpressDetail from '../components/ExpressDetail.vue'
import ProductType from '../components/ProductType.vue'

export default {
  components: {
    ExpressDetail,
    ProductType,
  },
  data() {
    return {
      modesColor: {
        AIR: '#409EFF',
        SEA: '#67C23A',
        BT: '#E6A23C',
        KEEP: '#fdf2be',
        DG: '#F56C6C',
        'DG(air)': '#e26bb6',
      },
      clientTimer: null,
      clientLoading: false,
      extraInfo: [{ name: '', ename: '', count: '', isBrand: false }],
      // productType: [],
      profileList: [],
      printList: [],
      currentItem: {},
      detailLen: 0,
      isShowDetail: false,
      liveQuery: {},
      weight: 0,
      ws: null,
      wsTimer: null,
      wsPing: null,
      password: 'atm',
      decoding: false,
      isWsConnected: false,
      currentUser: {},
      timeline: {},
      imgs: [],
      imgsIndex: 0,
      imgsList: [],
      submitLoading: false,
      isShow: false,
      isShowMulti: false,
      isShowPreview: false,
      isReset: false,
      isLoading: false,
      form: {},
      multiForm: {},
      selection: [],
      addressList: [],
      currentSelection: [],
      page: {
        no: 1,
        size: 50,
        total: 0,
      },
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      clientInfo: {},
      cargoInfo: {},
      currentParams: {},
      defaultSort: { order: 'descending', prop: 'createdAt' },
      params: {},
      clients: [],
      stores: [],
      timer: null,
      isDelivery: false,
      address: [
        { label: '嘉添' },
        { label: '桃红' },
        { label: '广花' },
        { label: '夏良' },
      ],
      columns: [
        {
          label: 'Date',
          labels: ['入', '出'],
          prop: 'createdDate',
          props: ['createdDate', 'deliveryDate'],
          format: item => {
            return item.get('createdDate').substr(0, 10)
          },
          show: ['table'],
          width: 130,
        },
        {
          label: '入库日期',
          prop: 'createdDate',
          show: ['form'],
        },
        {
          label: '出库日期',
          prop: 'deliveryDate',
          show: ['form'],
        },
        {
          label: 'Tracking No',
          prop: 'id',
          width: 170,
          show: ['table', 'form'],
        },
        {
          label: 'Cargo No',
          prop: 'logisticsNumber',
          width: 170,
          show: ['table', 'form'],
        },
        {
          label: 'Customer',
          prop: 'client',
          type: 'autocomplete',
          width: 160,
          show: ['table', 'form'],
        },
        {
          label: 'Supplier',
          prop: 'company',
          show: ['table', 'form'],
        },
        {
          label: 'Shelves No',
          prop: 'storeName',
          show: ['table', 'form'],
        },
        {
          label: 'Status',
          prop: 'status',
          format: item => {
            let val = item.get('status')
            let ret = this.status.find(n => n.value == val)
            return ret ? ret.label : val
          },
          show: ['table', 'form'],
        },
        {
          label: 'Transport',
          prop: 'mode',
          show: ['table', 'form'],
          format: item => {
            if (item.get('mode')) {
              return item.get('mode').replace('-no', '')
            }
          },
        },
        {
          label: 'Shipping No',
          prop: 'cargoNumber',
          show: ['table', 'form'],
        },
        {
          label: 'Marks',
          prop: 'mark',
          show: ['table', 'form'],
        },
        {
          label: 'CBM',
          prop: 'cbm',
          show: ['table', 'form'],
        },
        // {
        //   label: "CBM",
        //   prop: "v_weight",
        //   show: ["table", "form"],
        //   format: (item) => {
        //     let length = item.get("length");
        //     let width = item.get("width");
        //     let height = item.get("height");
        //     if (length && width && height) {
        //       return (
        //         (parseInt(length) * parseInt(width) * parseInt(height)) /
        //         6000
        //       ).toFixed(2);
        //     }
        //     return "";
        //   },
        // },
        {
          label: 'Weight',
          prop: 'weight',
          show: ['table', 'form'],
        },
        {
          label: 'C/F',
          prop: 'cost',
          show: ['table', 'form'],
        },
        {
          label: 'Location',
          prop: 'address',
          show: ['table'],
        },
        {
          label: 'Remark',
          prop: 'remark',
          show: ['table', 'form'],
        },
        {
          label: '入库员',
          prop: 'credit',
          show: ['table'],
          format: item => {
            if (item.get('operator')) {
              return item.get('operator').get('username')
            }
          },
        },
      ],
      status: [
        {
          label: '入库',
          value: '1',
          type: 'success',
        },
        {
          label: '出库',
          value: '2',
          type: 'primary',
        },
        {
          label: '未确认',
          value: '3',
          type: 'danger',
        },
      ],
      companyList: [],
    }
  },

  computed: {
    // lwh() {
    //   if (this.form.length && this.form.width && this.form.height) {
    //     return (
    //       (parseInt(this.form.length) *
    //         parseInt(this.form.width) *
    //         parseInt(this.form.height)) /
    //       6000
    //     ).toFixed(2);
    //   }
    //   return 0;
    // },
    productType() {
      return this.$store.state.productType
    },
    storeList() {
      return this.$store.state.storeList
    },
    modes() {
      let ret = this.configList.find(n => n.get('key') === 'mode')
      let value = []
      if (ret) {
        value = JSON.parse(ret.get('value'))
        value = value.map(n => {
          return {
            label: n.value.replace('-no', ''),
            value: n.value,
            color: this.modesColor[n.label],
          }
        })
      }
      return ret ? value : []
    },
    configList() {
      return this.$store.state.config
    },
    tableHeight() {
      return this.$store.state.tableHeight - 50
    },
    currentImg() {
      return this.imgs[0] || {}
    },
    currentImgObj() {
      return this.imgsList[0] || {}
    },
    isEdit() {
      return false
      //   return this.currentItem.id &&
      //     this.currentItem.get("client") != "未确认客户"
      //     ? true
      //     : false;
    },
  },

  inject: ['refresh'],

  watch: {
    'form.address'(val) {
      localStorage.address = val
    },
    'form.company'(val) {
      localStorage.company = val
    },
  },

  methods: {
    desensitization(name) {
      if (!name) {
        return
      }
      let firstChar = name.substring(0, 1)
      let lastChar = name.substring(name.length - 1)
      let middleChars = '*'.repeat(name.length - 2)
      let maskedName = firstChar + middleChars + lastChar
      return maskedName
    },
    querySearch(queryString, cb) {
      cb(this.addressList)
    },
    onWeightInput() {
      if (this.form.weight) {
        let weight = Number(this.form.weight)
        this.form.weight = (weight + 0.1).toFixed(2)
      }
      this.$refs.clientInput.focus()
    },
    removeExtra(idx) {
      this.extraInfo.splice(idx, 1)
    },
    append() {
      this.extraInfo.push({
        name: '',
        ename: '',
        count: '',
        isBrand: false,
      })
    },
    showDetail(item) {
      let status = item.get('status')
      if (status == '入库') {
        this.currentItem = item.get('storeId')
        this.isShowDetail = true
      }
    },
    afterOut() {
      this.isShowDetail = false
      this.query()
    },
    toUpper(prop, value) {
      this.$set(this.form, prop, value.toUpperCase())
    },
    unsubscribe() {
      if (this.liveQuery.unsubscribe) {
        return this.liveQuery.unsubscribe().then(item => {
          this.$message.error('图片服务断开')
          return item
        })
      }
      return Promise.resolve()
    },
    removeImg(idx) {
      this.imgsList.splice(idx, 1)
      this.imgs.splice(idx, 1)
    },
    initWebsocket() {
      if (this.ws) {
        this.ws.close()
      }
      this.ws = new WebSocket('ws://localhost:3000/ws')
      // this.ws = new WebSocket('ws://expressinfo.leanapp.cn/ws')
      this.ws.onopen = () => {
        console.log('ws已连接')
        this.isWsConnected = true
        this.$message.success('图片服务已连接')
        if (this.wsPing) {
          clearInterval(this.wsPing)
        }
        this.wsPing = setInterval(() => {
          this.ws.send('ping')
        }, 20 * 1000)
      }
      this.ws.onmessage = evt => {
        console.log(evt)
        var userId = evt.data
        if (this.currentUser.id === userId) {
          this.getImg()
        }
      }
      this.ws.onclose = () => {
        console.warn('ws已断开')
        this.$message.error('图片服务已断开,正在重连')
        this.ws.close()
        setTimeout(() => {
          this.initWebsocket()
        }, 1000)
      }
    },
    showPreview(idx) {
      this.imgsIndex = idx
      this.isShowPreview = true
    },
    onImgSelect(idx) {
      this.imgsIndex = idx
      if (this.imgs[idx].code != 'null') {
        this.$set(this.form, 'id', this.imgs[idx].code)
      } else {
        this.$set(this.form, 'id', '')
      }
    },
    imgToBase64(url, cb) {
      this.decoding = true
      let img = new Image()
      img.crossOrigin = 'Anonymous'
      img.src = url
      img.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, img.width, img.height)
        let dataURL = canvas.toDataURL('image/jpeg')
        cb && cb(dataURL)
      }
    },
    /**
     * 初始化获取图片, 使用livequery
     * https://leancloud.cn/docs/livequery-guide.html#hash-238228344
     */
    initGetImg() {
      this.imgs = []
      this.imgsList = []
      let query = new this.AV.Query('fileStore')
      query.equalTo('user', this.currentUser)
      query.include('file')
      query.subscribe().then(subscription => {
        this.$message.success('图片服务已连接')
        this.liveQuery = subscription
        subscription.on('create', item => {
          this.imgsList = [item]
          this.imgs = [
            {
              id: item.id,
              url:
                item.get('url') + '?imageView/2/w/100/h/100/q/100/format/png',
              oUrl: item.get('url'),
              code: item.get('code'),
            },
          ]
          let id = item.get('code') != 'null' ? item.get('code') : ''
          this.$set(this.form, 'id', id)
        })
      })
    },

    // 获取最新图片
    getImg() {
      if (this.decoding) {
        return
      }
      this.decoding = true
      this.imgs = []
      this.imgsList = []
      let query = new this.AV.Query('fileStore')
      query.equalTo('user', this.currentUser)
      query.descending('createdAt')
      query.include('file')
      query.limit(1)
      query.greaterThanOrEqualTo('createdAt', this.timeline)
      query.find().then(list => {
        this.decoding = false
        if (list && list.length) {
          this.imgsList = list
          if (this.imgs.length == 0 || this.imgs[0].id != list[0].id) {
            this.imgs = list.map(n => {
              return {
                id: n.id,
                url: n.get('file').thumbnailURL(100, 100),
                oUrl: n.get('url'),
                code: n.get('code'),
              }
            })
            if (this.imgs[this.imgsIndex].code != 'null') {
              this.$set(this.form, 'id', this.imgs[this.imgsIndex].code)
            } else {
              this.$set(this.form, 'id', '')
            }
          }
        } else {
          this.imgs = []
          this.$message.error('没有最新照片, 请使用小程序登录并拍照')
        }
        this.$refs.form.clearValidate()
      })
    },
    // 识别网络图片
    decodeImg(url) {
      Quagga.decodeSingle(
        {
          src: url,
          inputStream: {
            size: 1920,
          },
          locator: {
            halfSample: true,
            // patchSize: "small"
            // x-small, small, medium, large, x-large
          },
          decoder: {
            readers: [
              'code_128_reader',
              'code_93_reader',
              'code_39_reader',
              'code_39_vin_reader',
              'ean_reader',
              'ean_8_reader',
              'codabar_reader',
              'upc_reader',
              'upc_e_reader',
              'i2of5_reader',
              '2of5_reader',
            ],
          },
        },
        result => {
          this.decoding = false
          if (result && result.codeResult) {
            this.$set(this.form, 'id', result.codeResult.code)
            this.$refs.form.clearValidate()
          } else {
            this.$message.error('无法识别单号, 请填写')
            this.$refs.idInput.focus()
          }
        }
      )
    },
    // 识别图片条形码
    decode(e) {
      let { files } = e.target
      if (!files) {
        return
      }
      let f = files[0]
      let src = URL.createObjectURL(f)
      var image = new Image()
      image.onload = () => {
        console.log(image.width)
        console.log(image.height)
      }
      image.src = src
      Quagga.decodeSingle(
        {
          src,
          inputStream: {
            size: 1920,
          },
          locator: {
            halfSample: true,
            // patchSize: 'x-small'
          },
          decoder: {
            readers: [
              'code_128_reader',
              'code_93_reader',
              'code_39_reader',
              'code_39_vin_reader',
              'ean_reader',
              'ean_8_reader',
              'codabar_reader',
              'upc_reader',
              'upc_e_reader',
              'i2of5_reader',
              '2of5_reader',
            ],
          },
        },
        () => {
          // console.log(ret)
        }
      )
    },
    // 键盘快捷保存
    onKeyup(e) {
      if (this.isShow) {
        if (e.code == 'F9') {
          this.saveAndPrint()
        }
      }
    },
    // 批量出库
    multiOut() {
      if (this.multiForm.cargoNumber && this.multiForm.mark) {
        if (this.currentSelection && this.currentSelection.length) {
          this.currentSelection.forEach(n => {
            if (n.get('status') != '出库') {
              n.get('storeId').increment('count', -1)
              n.set('status', '出库')
              n.set('deliveryDate', new Date())
              n.set('cargoNumber', this.multiForm.cargoNumber)
              n.set('mark', this.multiForm.mark)
            }
          })
          return this.AV.Object.saveAll(this.currentSelection).then(
            () => {
              this.$message.success('保存成功')
            },
            err => {
              this.$message.error(err)
            }
          )
        }
      } else {
        this.isShowMulti = true
      }
    },
    // 刷新
    reload() {
      location.reload()
      // this.refresh()
    },
    // 关闭弹窗清空内容
    onClosed() {
      this.imgs = []
      this.imgsList = []
      this.imgsIndex = 0
      this.currentItem = {}
      this.isReset = true
      this.extraInfo = [{ name: '', ename: '', count: '', isBrand: false }]
      this.$nextTick(() => {
        this.isReset = false
      })
    },
    // 点击打印二次确认
    onPrint(item, type = 'normal') {
      this.$prompt('请输入密码', '请确认是否要打印，避免重复打印！！').then(
        ({ value }) => {
          if (value == this.password) {
            setTimeout(() => {
              this.print(item, type)
            }, 1000)
            return
          }
          this.$message.error('密码错误')
        }
      )
    },
    printAll() {
      if (this.selection.length) {
        this.selection.forEach(n => {
          let extraInfo = n.get('extraInfo')
          let showInfo = extraInfo
            .map(e => {
              return `${e.name}*${e.count}`
            })
            .join(' ')
          n.showInfo = showInfo
        })
        this.printList = [...this.selection]
        this.$nextTick(() => {
          window.print()
        })
        return
      }
      this.$message.error('请选择出货信息')
    },
    // 打印
    print(item, type = 'normal') {
      let extraInfo = item.get('extraInfo')
      let showInfo = extraInfo
        .map(e => {
          return `${e.name}*${e.count}`
        })
        .join(' ')
      item.showInfo = showInfo
      item.printType = type
      this.currentItem = item
      this.printList = [item]
      this.$nextTick(() => {
        window.print()
        this.showNew()
      })
    },
    /**
     * 批量删除
     */
    removeAll() {
      this.$confirm('确定删除已选订单?').then(() => {
        this.AV.Object.destroyAll(this.selection).then(
          () => {
            this.$message.success('删除成功')
            this.query()
          },
          err => {
            this.$message.error(err)
          }
        )
      })
    },

    /**
     * 多选
     */
    onSelectionChange(selection) {
      this.selection = selection
    },

    /**
     * 批量出库
     */
    delivery() {
      this.$confirm('确定批量设置出库?').then(() => {
        this.currentSelection = this.selection
        this.multiOut()
      })
    },
    /**
     * 获取储位列表
     */
    async getStore(name) {
      // 清空储位
      this.stores = []
      this.$set(this.form, 'storeName', '')

      if (name !== '') {
        if (name === '未确认客户') {
          this.form.storeName = '未确认'
          this.form.status = '未确认'
          return
        }
        name = name.toUpperCase()
        let client = this.clients.find(c => c.get('name') == name)
        let keyword = client ? client.get('name') : name
        let firstKey = keyword.substr(0, 1).toUpperCase()

        let query = new this.AV.Query('storeInfo')
        query.startsWith('name', firstKey)
        query.ascending('name')
        query.limit(1000)
        let list = await query.find()

        // let list = this.storeList;

        let hasStore = client
          ? list.find(n => {
              return n.get('clientId') && n.get('clientId').id == client.id
            }) || null
          : null

        // 已存放
        if (hasStore) {
          this.stores = [hasStore]
        } else {
          this.stores = list.filter(n => n.get('count') == 0)
        }

        if (this.stores.length) {
          this.form.storeName = this.stores[0].get('name')
        } else {
          this.$message({
            message: '该字母下的储位已满',
            type: 'error',
            duration: 0,
            showClose: true,
            center: true,
          })
        }

        return
      }
      this.clients = []
    },
    /**
     * 获取客户列表
     */
    getClient(key) {
      if (this.clientTimer) {
        clearTimeout(this.clientTimer)
      }
      if (!key.length) {
        this.clients = []
        return
      }
      this.clientTimer = setTimeout(async () => {
        if (key && key.length) {
          // 以客户ID搜索
          if (key.length === 5) {
            let query = new this.AV.Query('clientInfo')
            query.equalTo('userId', key.toUpperCase())
            this.clientLoading = true
            let ret = await query.find()
            if (ret.length) {
              this.clients = ret
              this.clientLoading = false
            } else {
              let query = new this.AV.Query('clientInfo')
              query.startsWith('name', key.toUpperCase())
              this.clientLoading = true
              let list = await query.find()
              this.clients = list
              this.clientLoading = false
            }
          } else {
            let query = new this.AV.Query('clientInfo')
            query.startsWith('name', key.toUpperCase())
            this.clientLoading = true
            let list = await query.find()
            this.clients = list
            this.clientLoading = false
          }
        }
      }, 300)
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },

    // 导入出库
    importDelivery(e) {
      let { files } = e.target
      if (!files) {
        return
      }
      let f = files[0]
      var reader = new FileReader()
      reader.onload = e => {
        var data = e.target.result
        let wb = XLSX.read(data, {
          type: 'binary',
        })
        var excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        if (excelJson && excelJson.length) {
          let ids = excelJson.map(n => {
            return n['单号'] ? `"${n['单号'].toString()}"` : ''
          })
          if (ids && ids.length) {
            // 根据订单号批量查询
            var cql =
              'select include storeId, * from expressInfo where id in (' +
              ids.join(',') +
              ')'
            this.AV.Query.doCloudQuery(cql).then(
              data => {
                let { results } = data
                this.currentSelection = results
                this.multiOut().then(() => {
                  this.refresh()
                })
              },
              () => {}
            )
          }
        }
      }
      reader.readAsBinaryString(f)
    },

    // 导入旧订单
    importf(item) {
      if (!item.target.files) {
        return
      }
      let f = item.target.files[0]
      var reader = new FileReader()
      reader.onload = e => {
        var data = e.target.result
        let wb = XLSX.read(data, {
          type: 'binary',
        })
        var excelJson = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        if (excelJson && excelJson.length) {
          let objList = excelJson
            .filter(n => {
              return n['单号'] && n['客户'] && n['快递公司'] ? true : false
            })
            .map(n => {
              let obj = new this.expressInfo()
              obj.set('id', n['单号'].toString())
              obj.set('client', n['客户'].toString())
              obj.set('company', n['快递公司'].toString())
              let parseDate = XLSX.SSF.parse_date_code(n['日期'])
              let d = new Date(parseDate.y, parseDate.m - 1, parseDate.d)
              obj.set('createdDate', d)
              obj.set('status', n['状态'])
              return obj
            })
          this.AV.Object.saveAll(objList).then(
            () => {
              console.log('success')
            },
            err => {
              this.$message.error(JSON.stringify(err))
            }
          )
        }
      }
      reader.readAsBinaryString(f)
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop)
      }
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('expressInfo')
      query[this.defaultSort.order](this.defaultSort.prop)
      // query.setFields(`
      //   confirmStatus
      //   address
      //   updatedAt
      //   objectId
      //   extraInfo
      //   file
      //   username
      //   mode
      //   client
      //   createdAt
      //   weight
      //   status
      //   id
      //   operator {
      //     updatedAt
      //     objectId
      //     roles
      //     username
      //     createdAt
      //     emailVerified
      //     mobilePhoneVerified
      //   }
      //   cost
      //   createdDate
      //   cbm
      //   storeName
      //   company
      // `)
      query.include('storeId')
      query.include('clientId')
      query.include('operator')
      let keys = Object.keys(this.params)
      keys.forEach(key => {
        let val = this.params[key]
        if (val) {
          if (key === 'date') {
            let [startDate, endDate] = this.params.date
            if (startDate.getTime() == endDate.getTime()) {
              endDate.setDate(endDate.getDate() + 1)
            }
            query.greaterThanOrEqualTo('createdDate', startDate)
            query.lessThanOrEqualTo('createdDate', endDate)
          } else if (key === 'address') {
            const innerQuery = new this.AV.Query('clientInfo')
            innerQuery.equalTo('address', val)
            innerQuery.limit(1000)
            query.matchesQuery('clientId', innerQuery)
          } else {
            query.startsWith(key, val)
          }
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then(list => {
        this.dataList = list.map(n => {
          if (n.get('file')) {
            n.url = n.get('file').thumbnailURL(100, 100)
            n.oUrl = n.get('file').url()
          }
          return n
        })
        this.isLoading = false
        this.submitLoading = false

        this.$log({
          name: 'expressInfo',
          url: '/admin/list',
          action: 'find',
          params: this.params,
        })
      })
      return query.count().then(count => {
        this.page.total = count
        this.$log({
          name: 'expressInfo',
          url: '/admin/list',
          action: 'count',
          params: this.params,
        })
      })
    },
    showNew() {
      this.timeline = new Date()
      this.imgs = []
      this.currentItem = {}
      this.isDelivery = false
      this.form = {}
      if (localStorage.address) {
        this.$set(this.form, 'address', localStorage.address)
      }
      if (localStorage.company) {
        this.$set(this.form, 'company', localStorage.company)
      }
      this.$set(this.form, 'createdDate', new Date())
      this.$set(this.form, 'storeName', '')
      this.$set(this.form, 'status', '入库')
      this.$set(this.form, 'cbm', 0)
      this.$set(this.form, 'mode', 'AIR')
      this.$set(this.form, 'cost', 0)
      this.stores = []
      this.clients = []
      this.isShow = true
      this.$nextTick(() => {
        this.$refs.weightInput.focus()
      })
    },
    showEdit(item) {
      this.timeline = new Date()
      this.currentItem = item
      this.isDelivery = item.get('status') == '出库'
      this.columns.forEach(n => {
        if (n.show.includes('form')) {
          this.$set(this.form, n.prop, item.get(n.prop))
        }
      })
      this.$set(this.form, 'createdDate', new Date())
      if (item.get('extraInfo')) {
        this.extraInfo = item.get('extraInfo')
      }
      this.isShow = true
    },
    // 减少储位数量
    storeReduce(storeId) {
      if (storeId && storeId.get('count') > 0) {
        storeId.increment('count', -1)
        storeId.save()
      }
    },
    remove(item) {
      this.$confirm(`确认删除 ${item.get('id')}?`).then(() => {
        item.destroy().then(() => {
          this.$message.success('删除成功!')
          this.query()
        })
      })
    },
    onSaveMulti() {
      this.$refs.multiForm.validate().then(valid => {
        if (valid) {
          this.multiOut().then(() => {
            this.refresh()
          })
        }
      })
    },
    saveAndPrint() {
      this.onSave().then(data => {
        this.print(data)
      })
    },
    checkName(name) {
      let query = new this.AV.Query('clientInfo')
      query.equalTo('name', name)
      return query.first().then(ret => {
        this.$log({
          name: 'clientInfo',
          url: '/Client',
          action: 'first',
          params: { name },
        })
        return ret ? true : false
      })
    },
    async getAddress() {
      let query = new this.AV.Query('config')
      query.equalTo('key', 'address')
      let ret = await query.first()
      this.addressList = JSON.parse(ret.get('value'))
    },
    async onSave() {
      return new Promise(resolve => {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            if (this.extraInfo.some(n => n.name == '')) {
              this.$message.error('请选择品名')
              return
            }
            let postData = {
              ...this.form,
              extraInfo: this.extraInfo,
            }
            postData.client = postData.client.toUpperCase()

            // 处理数字类型
            let numberProps = ['cost', 'cbm']
            numberProps.forEach(p => {
              if (!isNaN(postData[p])) {
                postData[p] = Number(postData[p])
              }
            })

            if (
              postData.client != '未确认客户' &&
              postData.status == '未确认'
            ) {
              this.$message.error('有客户时,状态不能为"未确认"')
              return
            }

            this.submitLoading = true

            // 修改已有订单
            if (this.currentItem.id) {
              if (
                postData.status == '出库' &&
                this.currentItem.get('status') != '出库'
              ) {
                postData.deliveryDate = new Date()
                let storeId = this.currentItem.get('storeId')
                this.storeReduce(storeId)
              }

              if (!postData.remark) {
                this.$message.error('修改必须填写备注')
                this.submitLoading = false
                return
              }

              // 关联客户
              let client = this.clients.find(
                c => c.get('name') === postData.client
              )
              if (client) {
                postData.clientId = client
              }

              // 关联储位
              let storeId = this.stores.find(
                s => s.get('name') === postData.storeName
              )
              // 储位关联客户
              if (storeId) {
                // 判断储位是否变动
                let itemStore = this.currentItem.get('storeId')
                if (itemStore && itemStore.id != storeId.id) {
                  // 旧储位减一
                  itemStore.increment('count', -1)
                  itemStore.save()
                }
                // 新储位加一
                postData.storeId = storeId
                postData.storeId.increment('count')
                postData.storeId.set('clientId', postData.clientId)
                postData.storeId.set('client', postData.client)
              }

              // 关联图片
              if (this.currentImgObj.id) {
                postData.file = this.currentImgObj.get('file')
              }

              // 保存可以修改的部分
              this.currentItem.save(postData).then(data => {
                this.submitLoading = false
                this.$message.success('修改成功')
                this.isShow = false
                if (this.currentImgObj.id) {
                  this.currentImgObj.save({
                    express: data,
                  })
                }
                this.query()
              })
              return
            }

            // 新增订单必须有图片
            if (!this.currentImgObj.id) {
              this.$message.error('请拍照')
              this.submitLoading = false
              return
            }

            // 判断单号是否重复
            let { id } = postData
            let query = new this.AV.Query('expressInfo')
            query.equalTo('id', id)
            query.first().then(ret => {
              this.$log({
                name: 'expressInfo',
                url: '/admin/list',
                action: 'first',
                params: { id },
              })
              // 已存在相同单号
              if (ret) {
                this.submitLoading = false
                this.$message.error('该单号已存在, 请重试')
                return
              }
              // 关联图片
              if (this.currentImgObj.id) {
                postData.file = this.currentImgObj.get('file')
              }

              if (postData.client != '未确认客户') {
                // 关联客户
                let client = null
                client = this.clients.find(
                  c => c.get('name') === postData.client
                )
                if (client) {
                  postData.clientId = client
                } else {
                  client = new this.clientInfo()
                  client.set('name', postData.client)
                  client.set('status', '1')
                  postData.clientId = client
                }

                // 提交时判断储位是否正确
                let currentStore = this.stores.find(
                  s => s.get('name') === postData.storeName
                )
                let storeObj = this.AV.Object.createWithoutData(
                  'storeInfo',
                  currentStore.id
                )
                storeObj.fetch().then(storeObj => {
                  if (
                    storeObj.get('client') &&
                    storeObj.get('client') !== postData.client
                  ) {
                    this.$message.error('该储位已被抢, 请重试')
                    return
                  }

                  // 关联储位
                  postData.storeId = currentStore

                  // 储位关联客户
                  postData.storeId.increment('count')
                  postData.storeId.set('clientId', postData.clientId)
                  postData.storeId.set('client', postData.client)

                  // 记录操作者
                  postData.operator = this.currentUser
                  postData.username = this.currentUser.get('username')

                  // 创建订单
                  let obj = new this.expressInfo()
                  obj.fetchWhenSave(true)
                  this.$mongo('expressInfo', postData)
                  obj.save(postData).then(data => {
                    this.submitLoading = false
                    this.isShow = false
                    if (this.currentImgObj.id) {
                      this.currentImgObj.save({
                        express: data,
                      })
                    }
                    // if (data.get('file')) {
                    //   data.url = data.get('file').thumbnailURL(100, 100)
                    //   data.oUrl = data.get('file').url()
                    // }
                    // this.dataList.unshift(data)
                    this.$nextTick(() => {
                      // this.query();
                      this.$message.success('添加成功')
                      resolve(data)
                    })
                  })
                })
                return
              }

              // 创建订单
              let obj = new this.expressInfo()
              obj.fetchWhenSave(true)
              this.$mongo('expressInfo', postData)
              obj.save(postData).then(data => {
                this.submitLoading = false
                this.$message.success('添加成功')
                this.isShow = false
                if (this.currentImgObj.id) {
                  this.currentImgObj.save({
                    express: data,
                  })
                }
                this.$nextTick(() => {
                  resolve(data)
                })
                // this.query();
              })
            })
          }
        })
      })
    },
  },

  mounted() {
    this.currentUser = this.AV.User.current()
    this.expressInfo = this.AV.Object.extend('expressInfo')
    this.storeInfo = this.AV.Object.extend('storeInfo')
    this.clientInfo = this.AV.Object.extend('clientInfo')
    this.cargoInfo = this.AV.Object.extend('cargoInfo')
    this.getAddress()
    this.initGetImg()
    this.query()
    this.$store.dispatch('getProfile').then(list => {
      this.profileList = list
    })
    this.$store.dispatch('getCompanyInfo').then(list => {
      this.companyList = list
    })
    document.addEventListener('keyup', this.onKeyup)
  },

  beforeRouteLeave(to, from, next) {
    this.unsubscribe().then(() => {
      next()
    })
    document.removeEventListener('keyup', this.onKeyup)
  },
}
</script>

<style lang="less">
.popper-class {
  img {
    max-width: 600px;
    max-height: 600px;
  }
}
.ui {
  &-table {
    .el-tag {
      cursor: pointer;
    }
  }
  &-imgs {
    line-height: 1;
    .img {
      float: left;
      text-align: center;
      border: 1px solid #eee;
      padding: 5px;
      margin: 0 10px 10px 0;
      position: relative;
      cursor: pointer;

      &.active {
        border: 1px solid #409eff;
      }

      .btn {
        display: block;
        color: #999;
        font-size: 14px;
      }
    }
    .el-icon-success {
      color: #409eff;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -12px 0 0 -12px;
      font-size: 24px;
    }
  }

  &-img {
    &-thumbnail {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin: -2px 10px 0 0;
      outline: none;
      cursor: pointer;
    }
  }
  &-query {
    .el-form-item--mini.el-form-item {
      margin-bottom: 10px;
    }
  }
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
    .el-date-editor.el-input {
      width: 195px;
    }
  }

  &-print {
    display: none;
    height: 688px;
    box-sizing: border-box;
    overflow: hidden;
    // border: 1px solid #000;

    &:last-child {
      padding: 8% 0 0;
    }

    .title {
      margin: 0 0 60px;
      p {
        text-align: center;
        font-size: 42px;
        line-height: 1;
        margin: 0 0 10px;
        padding: 0;
      }
    }
    table {
      width: 100%;
      border: 1px solid #000;
      border-collapse: collapse;
    }
    td {
      text-align: center;
      padding: 12px 0;
      min-width: 120px;
      border: 1px solid #000;
      font-size: 25px;
    }
    .td {
      font-size: 28px;
    }
    .name {
      font-family: Tahoma;
      font-size: 50px;
      white-space: nowrap;
    }
    .name2 {
      font-family: Tahoma;
      font-size: 50px;
      white-space: nowrap;
    }
    .info {
      font-size: 40px;
    }
  }
}
@media print {
  .ui-print {
    display: block;
  }
  .ui-content,
  .el-header,
  .el-aside {
    display: none;
  }
}
</style>
