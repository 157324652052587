import Vue from 'vue'
import Vuex from 'vuex'
var AV = require('leancloud-storage/live-query')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 所有快递公司
    companyInfo: [],
    // 积分信息
    creditList: [],
    // 所有员工列表
    profile: [],
    // 所有品牌
    brandList: [],
    productType: [],
    // 所有配置信息
    config: [],
    // 所有客户信息
    clientList: [],
    // 所有储位信息
    storeList: [],
    // 统一表格高度
    tableHeight: window.innerHeight - 170
  },
  mutations: {
    setStore(state, { key, value }) {
      state[key] = value
    },
  },
  actions: {
    // 获取所有储位
    getStoreList(context) {
      let query = new AV.Query('storeInfo')
      let maxPage = 2
      let indexPage = 0
      let result = []

      let loop = function() {
        let skip = 1000 * indexPage
        query.limit(1000)
        query.skip(skip)
        query.find().then(list => {
          result = result.concat(list)
          if (indexPage < maxPage) {
            indexPage++
            loop()
          } else {
            context.commit('setStore', {
              key: 'storeList',
              value: result,
            })
          }
        })
      }
      loop()
    },
    getConfig(context) {
      let query = new AV.Query('config')
      return query.find().then(list => {
        context.commit('setStore', {
          key: 'config',
          value: list,
        })
        return list
      })
    },
    async getProductType(context, refresh = false) {
      const name = 'productType'
      let datalist = []
      if (context.state[name].length && refresh == false) {
        return new Promise(resolve => {
          resolve(context.state[name])
        })
      }

      // 返回缓存
      if (refresh == false) {
        let cache = await Vue.rget(name)
        if (cache && cache.data) {
          context.commit('setStore', {
            key: name,
            value: cache.data,
          })
          return Promise.resolve(cache.data)
        }
      }


      for (let index = 0; index < 5; index++) {
        let query = new AV.Query(name)
        query.limit(1000)
        query.skip(1000 * index)
        let list = await query.find()
        datalist = datalist.concat(list)
      }

      let result = datalist.map(n => {
        return {
          name: n.get('name'),
          ename: n.get('ename'),
          remark: n.get('remark'),
        }
      })
      context.commit('setStore', {
        key: name,
        value: result,
      })
      // 写入缓存
      await Vue.rset(name, result, 60 * 60 * 24 * 30)
      return Promise.resolve(result)
    },
    getBrandList(context, refresh = false) {
      const name = 'brandList'
      if (context.state[name].length && refresh == false) {
        return new Promise(resolve => {
          resolve(context.state[name])
        })
      }
      let query = new AV.Query(name)
      return query.find().then(list => {
        let result = list.map(n => {
          return {
            name: n.get('name'),
            remark: n.get('remark'),
          }
        })
        context.commit('setStore', {
          key: name,
          value: result,
        })
        return result
      })
    },
    // 获取用户信息
    async getProfile(context, refresh = false) {
      const name = 'profile'
      if (context.state[name].length && refresh == false) {
        return new Promise(resolve => {
          resolve(context.state[name])
        })
      }
      let query = new AV.Query(name)
      return query.find().then(list => {
        let result = list.map(n => {
          return {
            username: n.get('username'),
            nickname: n.get('nickname'),
          }
        })
        context.commit('setStore', {
          key: name,
          value: result,
        })
        return result
      })
    },
    // 获取所有快递公司
    getCompanyInfo(context, refresh = false) {
      const name = 'companyInfo'
      if (context.state[name].length && refresh == false) {
        return context.state[name]
      }
      let query = new AV.Query(name)
      return query.find().then(list => {
        let result = list.map(n => {
          return n.get('name')
        })
        context.commit('setStore', {
          key: name,
          value: result,
        })
        return result
      })
    },

    getCreditList(context, refresh = false) {
      const name = 'creditList'
      if (context.state[name].length && refresh == false) {
        return context.state[name]
      }
      let query = new AV.Query(name)
      query.ascending('credit')
      return query.find().then(list => {
        let result = list
        context.commit('setStore', {
          key: name,
          value: result,
        })
        return result
      })
    },
  },
})
