<template>
  <!-- <el-input
    v-model="result"
    :placeholder="placeholder || '请输入'"
    clearable
  ></el-input> -->

  <el-autocomplete
    v-model="result"
    :fetch-suggestions="fetch"
    :placeholder="placeholder || '请输入'"
    clearable
    @keyup.native.enter="enter"
    @select="onSelect"
    @clear="onClear"
  >
    <template slot-scope="{ item }">
      <span>{{ item.label }}</span>
      <span style="float: right; color: #999" v-if="item.count">{{
        item.count
      }}</span>
    </template>
  </el-autocomplete>
</template>

<script>
export default {
  props: {
    value: String,
    params: Object,
    placeholder: String,
    isUpperCase: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      result: '',
      defaultValue: this.value,
      timer: null,
    }
  },
  watch: {
    result(val) {
      this.$emit('input', val)
    },
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (oldVal === undefined && val) {
          this.result = val
        }
      },
    },
  },
  methods: {
    onClear() {
      this.$emit('select', {})
    },
    onSelect(obj) {
      console.log('obj', obj)
      this.$emit('select', obj)
    },
    getClient(key, field) {
      if (!key) {
        return
      }
      key = key.toUpperCase()
      let queryByClient = new this.AV.Query('clientInfo')
      queryByClient.startsWith('name', key)

      let queryByUserId = new this.AV.Query('clientInfo')
      queryByUserId.equalTo('userId', key)

      let query = this.AV.Query.or(queryByClient, queryByUserId)
      return query.find().then(list => {
        let result = list.map(n => {
          let val = n.get(field)
          let ret = {
            value: val,
            label: val,
          }
          if (n.get('count')) {
            ret.count = n.get('count')
          }
          return ret
        })
        return result
      })
    },
    query(params) {
      let { key, field, className } = params
      let query = new this.AV.Query(className)
      if (this.isUpperCase) {
        key = key.toUpperCase()
      }
      query.contains(field, key)
      // query.select([field, 'count'])
      return query.find().then(list => {
        let result = list.map(n => {
          let val = n.get(field)
          let ret = {
            // obj: n,
            value: val,
            label: val,
          }
          if (n.get('count')) {
            ret.count = n.get('count')
          }
          return ret
        })
        return result
      })
    },
    fetch(key, cb) {
      if (key) {
        if (this.timer) {
          clearTimeout(this.timer)
        }

        this.timer = setTimeout(() => {
          let { field, className } = this.params
          const rkey = `${className}_${field}_contains_${key}`
          // 读取缓存
          this.$rget(rkey).then(res => {
            if (res && res.data) {
              cb(res.data)
              return
            }
            if (className === 'clientInfo') {
              this.getClient(key, field).then(res => {
                // this.$rset(rkey, res)
                cb(res)
              })
              return
            }
            this.query({
              key,
              field,
              className,
            }).then(list => {
              cb(list)
              // 写入缓存
              // this.$rset(rkey, list)
            })
          })
        }, 1500)

        return
      }
      cb([])
    },
    enter() {
      this.$emit('enter')
    },
  },
}
</script>
