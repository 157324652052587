<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline>
        <el-form-item>
          <AutoQuery
            v-model="params.name"
            placeholder="品名"
            :params="{ className: 'productType', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button type="success" @click="showNew" size="small">
            新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        v-loading="isLoading"
      >
        <el-table-column align="center" label="-" type="index">
        </el-table-column>
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <!-- 邀请码 -->
              <template v-else-if="col.prop == 'code'">
                <template v-if="formatData(row, col)">
                  {{ formatData(row, col) }}
                </template>
                <template v-else>
                  <el-button type="primary" size="mini" @click="getCode(row)"
                    >生成邀请码</el-button
                  >
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          :width="actionsWidth + 'px'"
        >
          <template slot-scope="{ row }">
            <el-button
              :type="n.type"
              @click="n.click(row)"
              size="mini"
              v-for="(n, i) in actions"
              :key="i"
              :disabled="n.disabled ? n.disabled(row) : false"
            >
              {{ n.label }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="品名信息"
      :visible.sync="isShow"
      width="50%"
      @closed="onClosed"
    >
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="中文名称">
          <el-input
            v-model="form.name"
            placeholder="请输入"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文名称">
          <el-input
            v-model="form.ename"
            placeholder="请输入"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remark"
            placeholder="请输入"
            type="textarea"
            :rows="4"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productType: {},
      isShow: false,
      isShowDetail: false,
      isLoading: false,
      isShowCredit: false,
      currentUser: {},
      creditForm: { mode: 'increase', count: 0, remark: '' },
      form: {},
      page: { no: 1, size: 100, total: 0 },
      status: [
        { label: '入库', value: '1', type: 'success' },
        { label: '出库', value: '2', type: 'primary' },
        { label: '未确认', value: '3', type: 'danger' },
      ],
      dataList: [],
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'createdAt' },
      orderList: [],
      actions: [
        {
          label: '修改',
          type: 'primary',
          click: (item) => {
            this.showEdit(item)
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm(`确定删除 ${item.get('name')}`, {
              type: 'warning',
            }).then(() => {
              item.destroy().then(() => {
                this.$message.success('删除成功')
                this.query()
                this.$store.dispatch('getProductType', true)
              })
            })
          },
        },
      ],
      columns: [
        {
          label: '名称',
          prop: 'name',
          show: ['table', 'form'],
          width: 220,
        },
        {
          label: '英文名称',
          prop: 'ename',
          show: ['table', 'form'],
          width: 220,
        },
        {
          label: '备注',
          prop: 'remark',
          show: ['table', 'form'],
          width: 160,
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          show: ['table'],
          format: (item) => {
            return this.formatDate(item.createdAt)
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    actionsWidth() {
      return this.actions.length * 80
    },
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170
      }
      return 0
    },
    currentName() {
      return this.currentItem.id ? this.currentItem.get('name') : ''
    },
  },

  methods: {
    onClosed() {
      this.currentItem = {}
      this.orderList = []
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop)
      }
    },
    formatDate(d) {
      if (!d) {
        return ''
      }
      let fm = (n) => {
        return n < 10 ? '0' + n.toString() : n.toString()
      }
      let arr = []
      arr.push(d.getFullYear())
      arr.push(fm(d.getMonth() + 1))
      arr.push(fm(d.getDate()))
      return arr.join('-')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('productType')
      query[this.defaultSort.order](this.defaultSort.prop)
      let keys = Object.keys(this.params)
      keys.forEach((key) => {
        let val = this.params[key]
        if (val) {
          query.startsWith(key, val.toUpperCase())
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then((list) => {
        this.dataList = list
        this.isLoading = false
      })
      query.count().then((count) => {
        this.page.total = count
      })
    },

    showNew() {
      this.currentItem = {}
      this.form = {}
      this.isShow = true
      this.$nextTick(() => {
        this.$refs.idInput.focus()
      })
    },
    showEdit(item) {
      this.currentItem = item
      this.isDelivery = item.get('status') == '出库'
      this.columns.forEach((n) => {
        if (n.show.includes('form')) {
          this.$set(this.form, n.prop, item.get(n.prop))
        }
      })
      this.isShow = true
    },

    checkName(name) {
      let query = new this.AV.Query('productType')
      query.equalTo('name', name)
      return query.first().then((ret) => {
        return ret ? true : false
      })
    },

    onSave() {
      let postData = { ...this.form, status: '1' }

      // 修改已有客户
      if (this.currentItem.id) {
        this.currentItem.save(postData).then(() => {
          this.$message.success('修改成功')
          this.isShow = false
          this.query()
          this.$store.dispatch('getProductType', true)
        })
        return
      }

      this.checkName(postData.name).then((ret) => {
        if (ret) {
          this.$message.error('已存在该品牌, 请修改重试')
          return
        }

        // 创建客户
        let obj = new this.productType()
        obj.save(postData).then(() => {
          this.$message.success('添加成功')
          this.isShow = false
          this.query()
          this.$store.dispatch('getProductType', true)
        })
      })
    },
  },

  mounted() {
    this.productType = this.AV.Object.extend('productType')
    this.currentUser = this.AV.User.current()
    this.query()
  },
}
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
