<template>
  <div class="ui-select">
    <el-select
      v-model="local_vallue"
      :placeholder="placeholder"
      filterable
      clearable
      :filter-method="filterMethod"
      @change="onChange"
      @clear="onClear"
    >
      <el-option
        v-for="(o, i) in local_options"
        :key="`${o[keys.value]}-${i}`"
        :label="o[keys.label]"
        :value="o[keys.value]"
      >
        <span style="float: left">{{ o[keys.label] }}</span>
        <span style="float: right; color: #8492a6; font-size: 13px">{{
          o[keys.info]
        }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
    options: {
      default() {
        return []
      },
    },
    keys: {
      default() {
        return {
          label: 'label',
          value: 'value',
          info: 'info',
        }
      },
    },
  },
  computed: {},
  // watch: {
  //   options: {
  //     immediate: true,
  //     handler (val) {
  //       console.log('🚀 ~ file: UiSelect.vue:43 ~ handler ~ val:', val)
  //       this.local_options = val
  //       this.unwatchMyData()
  //     }
  //   }
  // },
  data() {
    return {
      init: false,
      local_options: [],
      local_vallue: this.value,
      max: 50,
    }
  },
  methods: {
    onChange(value) {
      this.$emit('change', value)
      this.$emit('input', value)
    },
    onClear() {
      this.local_options = this.options.slice(0, this.max)
    },
    filterMethod(val) {
      if (val) {
        this.local_options = this.options.filter(n => {
          const label = n[this.keys.label].toLowerCase()
          const _val = val.toLowerCase()
          return label.indexOf(_val) !== -1
        })
      } else {
        this.onClear()
      }
    },
  },
  created() {
    this.onClear()
  },
}
</script>

<style lang="less"></style>
