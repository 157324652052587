<template>
  <div class="ui-main">
    <div class="ui-content">
      <div class="ui-query">
        <el-form :model="params" size="mini" inline>
          <el-form-item>
            <el-date-picker
              clearable
              v-model="params.date"
              type="daterange"
              range-separator="-"
              start-placeholder="Start Date"
              end-placeholder="End Date"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.cargoNo"
              placeholder="Cargo No"
              clearable
              @change="query"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.copyNo"
              placeholder="Copy No"
              clearable
              @change="query"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="params.cargoNumber"
              placeholder="Shipping No"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.mark"
              placeholder="Mark"
              :params="{ className: 'cargoInfo', field: 'mark' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.clientName"
              placeholder="Customer"
              :params="{ className: 'clientInfo', field: 'name' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <el-select placeholder="VIP" v-model="params.isVip" clearable>
              <el-option
                v-for="(n, i) in isVipList"
                :key="i"
                :label="n.label"
                :value="n.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              placeholder="Payment"
              v-model="params.feeStatus"
              clearable
            >
              <el-option
                v-for="(n, i) in feeStatus"
                :key="i"
                :label="n.text"
                :value="n.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select placeholder="Owner" v-model="params.payee" clearable>
              <el-option
                v-for="(n, i) in payee"
                :key="i"
                :label="n.label"
                :value="n.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="info" @click="printAll" size="small"
              >批量打印</el-button
            >
          </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="query" size="small">
              Search
            </el-button>
            <el-button
              type="primary"
              @click="showPreview"
              size="small"
              :disabled="selection.length == 0"
            >
              Preview
            </el-button>
          </el-form-item>
          <el-form-item label="result:">{{ page.total }}</el-form-item>
        </el-form>
      </div>

      <div class="ui-table">
        <el-table
          border
          @selection-change="onSelectionChange"
          :data="tableDataList"
          :height="tableHeight"
          @sort-change="onSortChange"
          v-loading="isLoading"
        >
          <el-table-column type="selection"></el-table-column>
          <template v-for="col in columns">
            <el-table-column
              :sortable="col.sortable === false ? false : 'custom'"
              :prop="col.prop"
              :key="col.id"
              :label="col.label"
              :min-width="col.width || 120"
              :fixed="col.fixed"
              v-if="col.show.includes('table')"
            >
              <template slot="header">
                <el-tooltip
                  effect="dark"
                  :content="col.subLabel"
                  placement="top"
                >
                  <span>{{ col.label }}</span>
                </el-tooltip>
              </template>
              <template slot-scope="{ row }">
                <template v-if="col.props">
                  <template v-for="(p, idx) in col.props">
                    <p v-if="row.get(p)" :key="idx">
                      <el-tag
                        :type="idx == 1 ? 'primary' : 'success'"
                        size="mini"
                      >
                        {{ col.labels[idx] }}
                      </el-tag>
                      {{ formatDate(row.get(p)) }}
                    </p>
                  </template>
                </template>
                <template v-else-if="col.tableType === 'tag'">
                  <el-tag
                    v-if="formatData(row, col)"
                    :type="formatData(row, col).type"
                    size="small"
                    @click="col.click && col.click(row)"
                  >
                    {{ formatData(row, col).text }}
                  </el-tag>
                </template>
                <template v-else-if="col.tableType === 'html'">
                  <div v-html="formatData(row, col)"></div>
                </template>
                <template v-else>{{ formatData(row, col) }}</template>
              </template>
            </el-table-column>
          </template>

          <el-table-column
            label="操作"
            fixed="right"
            :width="actions.length * 80"
          >
            <template slot-scope="{ row }">
              <template v-for="(n, i) in actions">
                <template v-if="n.type === 'upload'">
                  <el-popover
                    placement="left"
                    trigger="hover"
                    v-if="row.get('previewImg')"
                    popper-class="popper-class"
                    :open-delay="300"
                    :key="i"
                    style="margin-left: 10px"
                  >
                    <img
                      :src="row.url"
                      slot="reference"
                      class="ui-img-thumbnail"
                    />
                    <img :src="row.oUrl" class="ui-img-preview" />
                  </el-popover>
                </template>
                <template v-else>
                  <el-button
                    :type="n.type || 'primary'"
                    @click="n.click(row)"
                    size="mini"
                    :key="i"
                    v-show="n.isShow ? n.isShow() : true"
                  >
                    {{ n.label }}
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="ui-page">
        <el-pagination
          layout="total, sizes, prev, pager, next"
          :total="page.total"
          :page-sizes="[100, 500, 1000]"
          :page-size="page.size"
          :current-page="page.no"
          @current-change="onPageChange"
          @size-change="onSizeChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      title="财务管理"
      width="90%"
      :visible.sync="isShowDialog"
      top="0"
      :show-close="false"
      :close-on-click-modal="false"
      @closed="onClosed"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="130px"
        class="ui-form"
        size="mini"
        v-if="isShowDialog"
      >
        <el-row>
          <el-col :span="8">
            <div class="ui-count-title">货物资料</div>
            <el-form-item label="订单号">{{ currentItem.id }}</el-form-item>
            <el-form-item label="客户">{{ form.clientName }}</el-form-item>
            <el-form-item label="订单数" v-if="form.expressInfo">{{
              form.expressInfo.length
            }}</el-form-item>

            <el-form-item label="出库单号">
              ATM{{ form.cargoNumber }}
            </el-form-item>

            <el-form-item label="麦头">
              {{ form.mark }}
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <div class="ui-count-title">积分信息</div>
            <template v-if="hasAccount">
              <el-form-item label="用户积分">
                <el-tag type="primary" style="margin-right: 10px">
                  剩余积分 {{ clientCredit.credit }}
                </el-tag>
                <el-tag type="success" style="margin-right: 10px">
                  等级 {{ clientCredit.level }} , 兑换率
                  {{ clientCredit.scale }}
                </el-tag>
                <el-tag type="info" style="margin-right: 10px">
                  累积总积分 {{ clientCredit.totalCredit }}
                </el-tag>
                <el-tag type="danger">
                  累积兑换积分 {{ clientCredit.costCredit }}
                </el-tag>
              </el-form-item>
              <el-form-item label="积分操作">
                <el-button
                  type="primary"
                  size="mini"
                  @click="isShowCredit = true"
                  :disabled="clientCredit.hasCostPoint"
                >
                  兑换折扣
                </el-button>
                <!-- <el-button
                  type="success"
                  size="mini"
                  @click="isShowCreditAdd = true"
                >
                  奖励积分
                </el-button> -->
                <el-button
                  type="danger"
                  size="mini"
                  @click="isShowCreditMinus = true"
                >
                  积分回退
                </el-button>
              </el-form-item>

              <template v-if="form.creditCost">
                <el-form-item label="兑换积分">
                  {{ form.creditCost }}
                </el-form-item>

                <el-form-item label="折扣费用">
                  {{ form.discount }} 元
                </el-form-item>
              </template>

              <template>
                <el-form-item
                  :label="form.hasIncomeCredit ? '已奖励积分' : '奖励积分'"
                >
                  {{ form.incomeCredit }}
                </el-form-item>
              </template>
            </template>

            <template v-else>
              <el-form-item label="">该用户暂未激活VIP</el-form-item>
            </template>
          </el-col>
        </el-row>

        <el-row type="flex" :gutter="5">
          <el-col>
            <div class="ui-count-title">带电产品</div>
            <template v-for="(n, i) in countInfo">
              <el-row :key="i" style="margin-bottom: 18px">
                <el-col :span="15">
                  <el-input v-model="n.price" placeholder="单价" size="mini">
                    <template slot="prepend">
                      <span style="width: 40px; display: inline-block">
                        {{ n.label }}
                      </span>
                    </template>
                  </el-input>
                </el-col>
                <el-col :span="9">
                  <el-input
                    v-model="n.count"
                    placeholder="数量"
                    size="mini"
                  ></el-input>
                </el-col>
              </el-row>
            </template>

            <el-form-item label="带电产品数量">
              {{ form.count }}
            </el-form-item>
            <el-form-item label="带电产品总价">
              {{ form.totalPrice }}
            </el-form-item>
          </el-col>

          <el-col>
            <div class="ui-count-title">货物信息</div>
            <el-form-item label="累积重量">
              {{ detailTitle.weight }}
            </el-form-item>
            <template v-for="(n, idx) in column2">
              <el-form-item
                :label="n.label"
                :key="idx"
                :prop="n.prop"
                :rules="n.rules || []"
              >
                <template v-if="n.type === 'select'">
                  <el-select v-model="form[n.prop]" placeholder="请选择">
                    <el-option
                      v-for="item in n.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
                <template v-else>
                  <el-input
                    v-model="form[n.prop]"
                    :placeholder="n.placeholder || '请输入'"
                  ></el-input>
                </template>
              </el-form-item>
            </template>
          </el-col>
          <el-col>
            <div class="ui-count-title">收款信息</div>
            <el-form-item
              label="客户备注"
              v-if="currentItem.id && currentItem.get('clientId')"
            >
              <div style="font-weight: bold; color: red">
                {{ currentItem.get('clientId').get('remark') || '-' }}
              </div>
            </el-form-item>
            <template v-for="(n, idx) in column3">
              <el-form-item :label="n.label" :key="idx">
                <template v-if="n.type == 'number'">
                  <el-input
                    v-model.trim.number="form[n.prop]"
                    :placeholder="n.placeholder || '请输入'"
                    type="number"
                    :disabled="n.disabled == true"
                  ></el-input>
                </template>
                <template v-else-if="n.type == 'textarea'">
                  <el-input
                    v-model="form[n.prop]"
                    type="textarea"
                    :placeholder="n.placeholder || '请输入'"
                    :disabled="n.disabled === true"
                  ></el-input>
                </template>
                <template v-else-if="n.type == 'select'">
                  <el-select
                    v-model="form[n.prop]"
                    :placeholder="n.placeholder || '请选择'"
                    :disabled="isDisabled(n)"
                  >
                    <ElOption
                      v-for="(n, i) in n.option"
                      :key="i"
                      :label="n.label"
                      :value="n.value"
                    ></ElOption>
                  </el-select>
                </template>
                <template v-else>
                  <el-input
                    v-model="form[n.prop]"
                    :placeholder="n.placeholder || '请输入'"
                    :disabled="isDisabled(n)"
                  ></el-input>
                </template>
              </el-form-item>
            </template>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="isShowDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSave" :disabled="onSaveLoading">{{
          onSaveLoading ? '提交中' : '确 定'
        }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="isShowDetail" width="80%" top="20px">
      <template slot="title">
        <el-row>
          <el-col :span="8"
            ><label style="padding: 0 10px 0 0">Quantity:</label>
            {{ detailTitle.quantity }}</el-col
          >
          <el-col :span="8"
            ><label style="padding: 0 10px 0 0">Total estimate weight:</label>
            {{ detailTitle.weight }}</el-col
          >
          <el-col :span="8"
            ><label style="padding: 0 10px 0 0">Total C/F:</label>
            {{ detailTitle.cost }}</el-col
          >
        </el-row>
      </template>
      <el-table :data="detailList">
        <el-table-column
          :label="col.label"
          v-for="(col, idx) in detailColumns"
          :key="idx"
        >
          <template slot="header">
            <el-tooltip effect="dark" :content="col.subLabel" placement="top">
              <span>{{ col.label }}</span>
            </el-tooltip>
          </template>
          <template slot-scope="{ row }">
            {{ col.format ? col.format(row) : row.get(col.prop) }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="财务预览"
      :visible.sync="isShowPreview"
      fullscreen=""
      custom-class="ui-xs"
    >
      <hot-table
        :settings="previewOptions"
        ref="hotTable"
        v-if="isShowPreview"
      ></hot-table>
      <span slot="footer">
        <el-button type="primary" @click="exportFile">导出</el-button>
      </span>
    </el-dialog>

    <el-dialog title="积分兑换折扣" :visible.sync="isShowCredit">
      <el-form :model="creditObj" label-width="80px">
        <el-form-item label="用户积分">
          <el-tag type="primary" style="margin-right: 10px">
            剩余积分 {{ clientCredit.credit }}
          </el-tag>
          <el-tag type="success" style="margin-right: 10px">
            等级 {{ clientCredit.level }} , 兑换率
            {{ clientCredit.scale }}
          </el-tag>
          <el-tag type="info" style="margin-right: 10px">
            累积总积分 {{ clientCredit.totalCredit }}
          </el-tag>
          <el-tag type="danger">
            累积兑换积分 {{ clientCredit.costCredit }}
          </el-tag>
        </el-form-item>
        <el-form-item label="兑换积分">
          <el-input v-model="creditObj.credit" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="抵扣费用">
          {{ creditObj.discount }} 元
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSaveCredit">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="积分奖励" :visible.sync="isShowCreditAdd">
      <el-form :model="creditObj" label-width="80px">
        <el-form-item label="剩余积分">
          <el-tag type="primary" style="margin-right: 10px">
            剩余积分 {{ form.credit }}
          </el-tag>
          <el-tag type="warning">
            等级 {{ form.level }} , 兑换率 {{ form.scale }}
          </el-tag>
        </el-form-item>
        <el-form-item label="奖励积分">
          {{ form.incomeCredit }}
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSaveCreditAdd">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="积分回退" :visible.sync="isShowCreditMinus">
      <el-form :model="creditObj" label-width="80px">
        <el-form-item label="剩余积分">
          <el-tag type="primary" style="margin-right: 10px">
            剩余积分 {{ clientCredit.credit }}
          </el-tag>
          <el-tag type="success" style="margin-right: 10px">
            等级 {{ clientCredit.level }} , 兑换率
            {{ clientCredit.scale }}
          </el-tag>
          <el-tag type="info" style="margin-right: 10px">
            累积总积分 {{ clientCredit.totalCredit }}
          </el-tag>
          <el-tag type="danger">
            累积兑换积分 {{ clientCredit.costCredit }}
          </el-tag>
        </el-form-item>
        <el-form-item label="退回积分">
          <el-input
            v-model="creditMinus.credit"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="creditMinus.remark"
            placeholder="请输入"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSaveCreditMinus">保存</el-button>
      </span>
    </el-dialog>

    <div class="ui-print" v-for="(item, index) in printList" :key="index">
      <div class="inner">
        <div class="title">
          <p>广州沃麦贸易有限公司</p>
          <p>WALLMAX TRADING(GUANGZHOU)CO.,LTD.</p>
        </div>
        <table v-if="item.id">
          <tr>
            <td>
              Name
              <br />(名字)
            </td>
            <td colspan="3">
              <span class="td name2">{{ item.get('clientName') }}</span>
            </td>
          </tr>
          <tr>
            <td>QTY</td>
            <td>
              <span class="td info"
                >{{ item.get('expressInfo').length }} Pcs</span
              >
            </td>
            <td>手机</td>
            <td>
              <span class="td info">{{ item.get('mobileCount') }} Pcs</span>
            </td>
          </tr>
          <tr>
            <td>NO.</td>
            <td>
              <span class="td info">{{ item.get('cargoNumber') }}</span>
            </td>
            <td>Mark</td>
            <td>
              <span class="td info">{{ item.get('mark') }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import 'handsontable/languages/zh-CN.js'
import 'handsontable/dist/handsontable.full.css'

let winHeight = window.innerHeight - 120

export default {
  components: {
    HotTable,
  },
  data() {
    return {
      isVipList: [
        { label: 'VIP', value: 'vip' },
        { label: 'Not VIP', value: 'not' },
      ],
      feeStatus: [
        { label: 'YES', value: 'YES', type: 'success', text: 'Paid' },
        { label: 'NO', value: 'NO', type: 'danger', text: 'Unpaid' },
      ],
      payee: [
        { label: 'Mike', value: 'Mike' },
        { label: 'Tracy', value: 'Tracy' },
        { label: 'Albert', value: 'Albert' },
        { label: 'Accounting', value: 'Accounting' },
        { label: 'DEO agent', value: 'DEO' },
        { label: 'DK agent', value: 'DK' },
      ],
      clientCredit: {},
      onSaveLoading: false,
      isShowCreditMinus: false,
      isShowCredit: false,
      isShowCreditAdd: false,
      creditObj: {},
      creditDetail: {},
      cargoLogistics: {},
      creditList: [],
      currentUser: {},
      creditMinus: {},
      previewOptions: {
        data: [],
        fixedRowsTop: 1,
        colHeaders: true,
        rowHeaders: true,
        height: winHeight,
        rowHeights: 24,
        hiddenColumns: {},
        language: 'zh-CN',
        colWidths(index) {
          return index === 1 || index === 2 ? 200 : 100
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
      printList: [],
      selection: [],
      isShow: false,
      isShowDialog: false,
      isShowPreview: false,
      isLoading: false,
      config: [],
      form: {
        unit: 'kg',
      },
      page: {
        no: 1,
        size: 100,
        total: 0,
      },
      dataList: [],
      otherInfo: [],
      expressInfo: {},
      airPrice: '',
      exchangeRate: '',
      storeInfo: {},
      clientInfo: {},
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'updatedAt' },
      detailList: [],
      isShowDetail: false,
      countInfo: [
        // { label: '手机', prop: 'phone', value: '', price: 0 },
        // { label: '小手机', prop: 'smallPhone', value: '', price: 0 },
        // { label: '笔记本', prop: 'laptop', value: '', price: 0 },
        // { label: 'iPad', prop: 'ipad', value: '', price: 0 },
        // { label: '相机', prop: 'camera', value: '', price: 0 },
        // { label: '其他', prop: 'other', value: '', price: 0 }
      ],
      actions: [
        {
          label: 'Edit',
          click: item => {
            this.showEdit(item)
          },
          isShow: () => {
            if (this.currentRole.includes('Agent')) {
              return false
            }
            return true
          },
        },
        {
          label: 'Detail',
          click: item => {
            this.showDetail(item)
          },
        },
        {
          label: 'preview',
          type: 'upload',
        },
        // {
        //   label: '打印',
        //   type: 'info',
        //   click: item => {
        //     this.currentItem = item
        //     this.printList = [item]
        //     this.$nextTick(_ => {
        //       window.print()
        //     })
        //   }
        // }
      ],
      column2: [
        {
          label: '单位',
          prop: 'unit',
          type: 'select',
          show: ['table', 'form', 'edit', 'excel'],
          options: [
            {
              label: '重量(KG)',
              value: 'kg',
            },
            {
              label: '方数(CBM)',
              value: 'cbm',
            },
          ],
        },
        {
          label: '量',
          prop: 'weight',
          show: ['table', 'form', 'edit', 'excel'],
          rules: [
            {
              validator: (rule, value, callback) => {
                if (value) {
                  if (Number(value) < Number(this.detailTitle.weight)) {
                    callback(new Error('注意:当前重量少于累积重量'))
                  } else {
                    callback()
                  }
                  return
                }
                callback()
              },
              trigger: 'blur',
            },
          ],
        },
        {
          label: '单价',
          prop: 'airPrice',
          show: ['form'],
        },
        {
          label: '打包费',
          prop: 'packingFee',
          show: ['form', 'edit'],
        },
        {
          label: '总费用(RMB)',
          prop: 'fee',
          placeholder: '自动计算',
          show: ['table', 'form', 'edit', 'excel'],
        },
        {
          label: '折后费用(RMB)',
          prop: 'paid',
          placeholder: '自动计算',
          show: ['table', 'form', 'edit', 'excel'],
        },
        {
          label: '汇率',
          prop: 'exchangeRate',
          show: ['form', 'edit'],
        },
        {
          label: 'Paypal(USD)',
          prop: 'paypal',
          width: 180,
          placeholder: '自动计算',
          show: ['table', 'form', 'edit', 'excel'],
        },
        {
          label: '手续费H/C',
          prop: 'handlingFee',
          width: 180,
          show: ['table', 'form', 'edit', 'excel'],
        },
        {
          label: '快递费C/F',
          prop: 'cost',
          width: 180,
          show: ['table', 'form', 'edit', 'excel'],
        },
      ],
      column3: [
        // {
        //   label: '客户备注',
        //   prop: 'clientRemark',
        //   show: ['form', 'edit'],
        //   disabled: () => {
        //     return true
        //   },
        // },
        {
          label: '收款情况',
          prop: 'feeStatus',
          type: 'select',
          option: [
            { label: 'YES', value: 'YES' },
            { label: 'NO', value: 'NO' },
          ],
          show: ['table', 'form', 'edit', 'excel'],
          disabled: () => {
            return true
          },
        },
        {
          label: '收款人',
          prop: 'payee',
          type: 'select',
          option: [
            { label: 'Wechat', value: 'Wechat' },
            { label: 'Alipay', value: 'Alipay' },
            { label: 'Mike', value: 'Mike' },
            { label: 'Tracy', value: 'Tracy' },
            { label: 'Albert', value: 'Albert' },
            { label: 'Accounting', value: 'Accounting' },
            { label: 'DEO Agent', value: 'DEO Agent' },
            { label: 'DK Agent', value: 'DK Agent' },
            { label: 'Paypal', value: 'Paypal' },
          ],
          show: ['table', 'form', 'edit', 'excel'],
          disabled: () => {
            return true
            // return this.form.payee === 'Wechat' || this.form.payee === 'Alipay'
          },
        },
        {
          label: '电话号码',
          prop: 'mobileNumber',
          show: ['table', 'form', 'edit'],
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          show: ['table', 'form', 'edit', 'excel'],
        },
      ],
      columns: [
        {
          label: 'Shipping No.',
          subLabel: '出库单号',
          prop: 'cargoNumber',
          width: 200,
          show: ['table', 'edit', 'excel'],
          sortable: false,
          fixed: 'left',
        },

        {
          label: 'Mark',
          subLabel: '麦头',
          prop: 'mark',
          width: 120,
          disabled: true,
          show: ['table', 'form', 'edit', 'excel'],
          sortable: false,
          fixed: 'left',
        },
        {
          label: 'Name',
          subLabel: '客户名',
          prop: 'clientName',
          show: ['table', 'edit', 'excel'],
          fixed: 'left',
          width: 200,
          // format: item => {
          //   let clientId = item.get("clientId");
          //   return clientId ? clientId.get("name") : "";
          // }
        },
        {
          label: 'Cargo No.',
          subLabel: '物流单号',
          prop: 'cargoNo',
          width: 200,
          show: ['table', 'excel'],
          sortable: false,
          fixed: 'left',
        },
        {
          label: 'Copy No.',
          subLabel: '物流单号(仿)',
          prop: 'copyNo',
          width: 200,
          show: ['table', 'excel'],
          sortable: false,
          fixed: 'left',
        },
        {
          label: 'Qty',
          subLabel: '订单数',
          prop: 'expressInfo',
          width: 60,
          show: ['table', 'excel'],
          format: item => {
            return item.get('expressInfo').length
          },
          sortable: false,
        },
        {
          label: 'Weight',
          subLabel: '重量',
          prop: 'weight',
          tableType: 'html',
          width: 70,
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            let weight = item.get('weight')
            let weightWarning = item.get('weightWarning')
            return weightWarning
              ? `<span style="color: red; font-weight: bold;">${weight}</span>`
              : weight || 0
          },
          sortable: false,
        },
        {
          label: 'E-Product',
          subLabel: '带电产品数量',
          prop: 'count',
          type: 'count',
          width: 100,
          show: ['table', 'form', 'edit'],
          sortable: false,
        },
        {
          label: '带电产品价格',
          prop: 'totalPrice',
          type: 'input',
          show: ['form', 'edit', 'excel'],
          sortable: false,
        },

        {
          label: '空运单价',
          prop: 'airPrice',
          show: ['form'],
          sortable: false,
        },
        {
          label: '打包费',
          prop: 'packingFee',
          show: ['form', 'edit'],
          sortable: false,
        },
        {
          label: 'RMB',
          subLabel: '费用(RMB)',
          prop: 'fee',
          width: 60,
          placeholder: '自动计算',
          show: ['table', 'form', 'edit', 'excel'],
          sortable: false,
        },
        {
          label: 'Discount',
          subLabel: '折扣',
          prop: 'discount',
          width: 90,
          placeholder: '自动计算',
          show: ['table', 'excel'],
          sortable: false,
        },
        {
          label: 'Act_Pay',
          subLabel: '实付',
          prop: 'paid',
          width: 80,
          placeholder: '自动计算',
          show: ['table', 'excel'],
          sortable: false,
        },
        {
          label: '汇率',
          prop: 'exchangeRate',
          show: ['form', 'edit'],
        },
        {
          label: 'Paypal(USD)',
          prop: 'paypal',
          width: 110,
          placeholder: '自动计算',
          show: ['table', 'form', 'edit', 'excel'],
          sortable: false,
        },
        {
          label: 'H/C',
          prop: 'handlingFee',
          width: 80,
          tableType: 'tag',
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            let handlingFee = item.get('handlingFee')
            return handlingFee
              ? { type: 'success', text: handlingFee }
              : { type: 'danger', text: '0' }
          },
          sortable: false,
        },

        {
          label: 'Payment',
          subLabel: '收款情况',
          prop: 'feeStatus',
          type: 'select',
          tableType: 'tag',
          width: 90,
          option: [
            { label: 'YES', value: 'YES', text: 'Paid' },
            { label: 'NO', value: 'NO', text: 'Unpaid' },
          ],
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            let value = item.get('feeStatus') || 'NO'
            return this.feeStatus.find(n => n.value === value)
          },
          sortable: false,
        },
        {
          label: 'Picked Up',
          subLabel: '是否提货',
          prop: 'pickedUp',
          tableType: 'tag',
          width: 120,
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            let options = [
              { text: 'YES', value: 'YES', type: 'success' },
              { text: 'NO', value: 'NO', type: 'danger' },
            ]
            let value = item.get('pickedUp') || 'NO'
            return options.find(n => n.value === value)
          },
          click: item => {
            let pickedUp = item.get('pickedUp')
            if (!pickedUp || pickedUp === 'NO') {
              this.$confirm(
                'Are you sure the client have picked up the goods?',
                {
                  type: 'warning',
                  confirmButtonText: 'Confirm',
                  cancelButtonText: 'Cancel',
                }
              ).then(() => {
                // 已收货物流信息
                let expressUpdate = {
                  expressTime: new Date(),
                  expressPlace: 'Warehouse',
                  expressDetail: 'Picked Up',
                  expressStatus: 'Picked Up',
                }
                // 出货单更新最新物流信息
                item.set({
                  pickedUp: 'YES',
                  ...expressUpdate,
                })

                // 创建新的物流信息
                let {
                  expressTime: time,
                  expressPlace: place,
                  expressDetail: detail,
                  expressStatus: status,
                } = expressUpdate

                // 同时保存
                let obj = new this.cargoLogistics()
                obj
                  .save({
                    time,
                    place,
                    detail,
                    status,
                    cargo: item,
                  })
                  .then(() => {
                    this.$message.success('Submitted successfully !')
                  })
              })
            }
          },
        },
        {
          label: 'Confirmer',
          subLabel: '提货人',
          prop: 'confirmer',
          width: 100,
          show: ['table'],
          format: item => {
            return item.get('confirmer')
              ? item.get('confirmer').get('username')
              : ''
          },
          sortable: false,
        },
        {
          label: 'H/C',
          prop: 'handlingFee',
          width: 80,
          show: ['excel'],
          format: item => {
            return item.get('handlingFee') || '0'
          },
          sortable: false,
        },
        {
          label: 'Payment',
          subLabel: '收款情况',
          prop: 'feeStatus',
          width: 90,
          show: ['excel'],
          format: item => {
            let value = item.get('feeStatus')
            return value && value === 'YES' ? 'Paid' : 'Unpaid'
          },
        },
        {
          label: 'Picked Up',
          subLabel: '是否提货',
          prop: 'pickedUp',
          width: 90,
          show: ['excel'],
          format: item => {
            return item.get('pickedUp') || 'NO'
          },
        },
        {
          label: 'Owner',
          subLabel: '收款人',
          width: 70,
          prop: 'payee',
          type: 'select',
          option: [
            { label: 'Mike', value: 'Mike' },
            { label: 'Tracy', value: 'Tracy' },
            { label: 'Albert', value: 'Albert' },
            { label: 'Accounting', value: 'Accounting' },
            { label: 'DEO Agent', value: 'DEO Agent' },
            { label: 'DK Agent', value: 'DK Agent' },
          ],
          show: ['table', 'form', 'edit', 'excel'],
          sortable: false,
        },
        {
          label: 'Tel',
          subLabel: '电话号码',
          width: 140,
          prop: 'mobileNumber',
          show: ['table', 'form', 'edit'],
          sortable: false,
        },
        {
          label: 'VIP',
          subLabel: '是否VIP',
          prop: 'isVip',
          width: 90,
          show: ['table', 'excel'],
          format: item => {
            let client = item.get('clientId')
            return client ? (client.get('userInfo') ? 'VIP' : '') : ''
          },
        },
        {
          label: 'Remark',
          subLabel: '备注',
          width: 90,
          prop: 'remark',
          type: 'textarea',
          show: ['table', 'form', 'edit', 'excel'],
          sortable: false,
        },
        {
          label: 'CS',
          subLabel: '关联客服',
          width: 220,
          prop: 'support',
          type: 'textarea',
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            if (item.get('clientId')) {
              return item.get('clientId').get('support')
            }
            return ''
          },
          sortable: false,
        },
        {
          label: 'Passport',
          subLabel: '身份证信息',
          width: 90,
          prop: 'passport',
          show: ['table', 'form', 'edit', 'excel'],
          format: item => {
            if (item.get('clientId')) {
              return item.get('clientId').get('passport')
            }
            return ''
          },
          sortable: false,
        },
        {
          label: 'Outputer',
          subLabel: '出库人',
          width: 90,
          prop: 'credit',
          show: ['table'],
          format: item => {
            if (item.get('operator')) {
              return item.get('operator').get('username')
            }
          },
          sortable: false,
        },
        {
          label: 'Time',
          subLabel: '创建时间',
          prop: 'createdAt',
          show: ['table'],
          format: item => {
            return this.formatDate(item.createdAt)
          },
        },
        {
          label: '是否已兑换积分',
          prop: 'hasCostPoint',
          show: ['edit'],
        },
        {
          label: '是否已奖励积分',
          prop: 'hasIncomeCredit',
          show: ['edit'],
        },
      ],
      detailColumns: [
        {
          label: 'Shipping No.',
          subLabel: '单号',
          prop: 'id',
        },
        {
          label: 'Name',
          subLabel: '客户名',
          prop: 'client',
        },
        {
          label: 'Supplier',
          subLabel: '供应商',
          prop: 'company',
        },
        {
          label: 'Weight',
          subLabel: '重量',
          prop: 'weight',
        },
        {
          label: 'C/F',
          subLabel: '垫付快递费',
          prop: 'cost',
        },
        {
          label: 'Time',
          subLabel: '出库时间',
          prop: 'deliveryDate',
          format: value => {
            return this.formatDate(value.get('deliveryDate'))
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    detailTitle() {
      let weight = this.detailList.reduce((total, item) => {
        let w = item.get('weight')
        if (w) {
          w = Number(w)
          return total + w
        }
        return total
      }, 0)
      let cost = this.detailList.reduce((total, item) => {
        let w = item.get('cost')
        if (w) {
          w = Number(w)
          return total + w
        }
        return total
      }, 0)
      return {
        quantity: this.detailList.length,
        weight: weight.toFixed(2),
        cost: cost.toFixed(2),
      }
    },
    tableDataList() {
      if (this.dataList.length) {
        return this.dataList.map(item => {
          let previewImg = item.get('previewImg')
          if (previewImg) {
            item.oUrl = previewImg.url()
            item.url = previewImg.thumbnailURL(100, 100)
          }
          return item
        })
      }
      return []
    },
    currentRole() {
      if (this.currentUser) {
        let roles = []
        let role = this.currentUser.get('role') || this.currentUser.get('roles')
        if (typeof role === 'string') {
          roles.push(role)
        } else {
          roles = role
        }
        return roles
      }
      return []
    },
    tableHeight() {
      return window.innerHeight - 200
    },

    // 判断是否已经绑定账号
    hasAccount() {
      if (this.currentItem && this.currentItem.id) {
        let client = this.currentItem.get('clientId')
        if (client) {
          let userInfo = client.get('userInfo')
          return userInfo
        }
        return false
      }
      return false
    },
  },

  watch: {
    'creditObj.credit': {
      handler(val) {
        let discount = 0
        if (val) {
          if (val > this.clientCredit.credit) {
            this.$message.error('剩余积分不足')
            this.creditObj.credit = this.clientCredit.credit
          }
          discount = (val * this.clientCredit.scale).toFixed(2)
        }
        this.creditObj.discount = discount
      },
    },
    form: {
      deep: true,
      handler() {
        this.calculate()
      },
    },
    countInfo: {
      deep: true,
      handler() {
        this.calculate()
      },
    },
  },

  methods: {
    isDisabled(item) {
      if (item.disabled) {
        return item.disabled()
      }
      return false
    },
    // 关闭弹窗重置
    onClosed() {
      this.currentItem = {}
      this.form = {
        unit: 'kg',
      }
      this.creditObj = {}
    },
    // 手动回退积分
    onSaveCreditMinus() {
      let currentClient = this.currentItem.get('clientId')
      let incomeCredit = Number(this.creditMinus.credit)
      let credit = currentClient.get('credit') + incomeCredit
      currentClient.set('credit', credit)
      let creditDetail = new this.creditDetail()
      creditDetail
        .save({
          credit: incomeCredit,
          client: currentClient,
          cargoInfo: this.currentItem,
          operator: this.currentUser,
          remark: this.creditMinus.remark,
          creditLeft: credit,
          type: 'payback',
        })
        .then(
          () => {
            this.$message.success('回退积分成功')
            this.form.credit += incomeCredit
          },
          err => {
            this.$message.error(err.message)
          }
        )
        .then(() => {
          this.isShowCreditMinus = false
        })
    },
    // 自动奖励积分
    onSaveCreditAdd() {
      let currentClient = this.currentItem.get('clientId')
      let incomeCredit = this.form.incomeCredit || 0
      let totalCredit = currentClient.get('totalCredit') + incomeCredit
      let idx = 0
      this.creditList.find((n, i) => {
        if (n.get('credit') > totalCredit) {
          idx = i
          return true
        }
      })
      this.currentItem.set('hasIncomeCredit', true)
      let index = idx == 0 ? this.creditList.length - 1 : idx - 1
      let curLevel = this.creditList[index].get('level')
      let creditLeft = this.clientCredit.credit + incomeCredit
      currentClient.set('level', curLevel)
      currentClient.increment('credit', incomeCredit)
      currentClient.increment('totalCredit', incomeCredit)
      let creditDetail = new this.creditDetail()
      return creditDetail
        .save({
          credit: incomeCredit,
          client: currentClient,
          cargoInfo: this.currentItem,
          operator: this.currentUser,
          creditLeft,
          type: 'increase',
        })
        .then(
          () => {
            this.$message.success('奖励积分成功')
          },
          err => {
            this.$message.error(err.message)
          }
        )
        .then(() => {
          this.isShowCreditAdd = false
        })
    },
    // 手动兑换积分操作
    onSaveCredit() {
      let discount = Number(this.creditObj.discount)
      let creditCost = Number(this.creditObj.credit)

      if (creditCost > Number(this.form.fee)) {
        this.$message.error('抵扣费用超过总费用, 请重试!')
        return
      }

      this.$set(this.form, 'discount', discount)
      this.$set(this.form, 'creditCost', creditCost)
      // 添加兑换记录
      let currentClient = this.currentItem.get('clientId')
      let credit = creditCost * -1
      let creditLeft = this.clientCredit.credit + credit
      this.currentItem.set('hasCostPoint', true)
      this.currentItem.set('discount', discount)
      this.currentItem.set('creditCost', creditCost)
      let creditDetail = new this.creditDetail()
      currentClient.increment('credit', credit)
      currentClient.increment('costCredit', creditCost)
      creditDetail
        .save({
          credit,
          client: currentClient,
          cargoInfo: this.currentItem,
          operator: this.currentUser,
          creditLeft,
          type: 'exchange',
        })
        .then(
          () => {
            this.$message.success('积分扣除成功')
            this.clientCredit.credit += credit
            this.clientCredit.costCredit -= credit
            this.clientCredit.hasCostPoint = true
          },
          err => {
            this.$message.error(err.message)
          }
        )
        .then(() => {
          this.isShowCredit = false
        })
    },

    exportFile() {
      let instance = this.$refs.hotTable.hotInstance
      let hidden = instance.getPlugin('hiddenColumns')
      let exp = instance.getPlugin('exportFile')
      hidden.hideColumns([12, 13, 14])
      hidden.showColumns([15, 16, 17])
      instance.render()
      exp.downloadFile('csv')
      setTimeout(() => {
        hidden.showColumns([12, 13, 14])
        hidden.hideColumns([15, 16, 17])
      }, 300)
    },
    showPreview() {
      let data = []
      let cols = this.columns.filter(c => c.show.includes('excel'))
      data = this.selection.map(n => {
        return cols.map(c => {
          let value = n.get(c.prop)
          if (c.format && c.prop !== 'weight') {
            value = c.format(n)
          }
          return value
        })
      })
      // 插入表格头
      data.unshift(cols.map(c => c.label))
      this.previewOptions.data = data

      this.previewOptions.cells = function () {
        this.renderer = (instance, td, row, col, prop, value) => {
          // 第一行表头颜色
          if (row === 0) {
            td.style.backgroundColor = '#409EFF'
            td.style.color = '#fff'
          } else if (col === 13 || col === 14) {
            if (value) {
              td.style.backgroundColor =
                value.value === 'NO' ? '#F56C6C' : '#67C23A'
              td.style.color = '#fff'
              value = value.text
            }
          } else if (col === 12) {
            if (value) {
              td.style.backgroundColor =
                value.text === '0' ? '#F56C6C' : '#67C23A'
              td.style.color = '#fff'
              value = value.text
            }
          } else if (col === 6) {
            let tmp = new DOMParser().parseFromString(value, 'text/html').body
              .childNodes
            if (tmp[0].tagName) {
              value = tmp[0].innerText
              td.style.color = 'red'
            }
          }
          if (value) {
            td.innerHTML = value
          }
        }
      }
      this.previewOptions.hiddenColumns = {
        columns: [15, 16, 17],
        indicators: true,
      }
      this.isShowPreview = true
    },
    // 动态计算结果
    calculate() {
      // 计算带电产品价格
      let total = 0
      let count = 0
      let charge = this.otherInfo.find(c => c.key == 'charge').value
      this.countInfo.forEach(item => {
        if (item.count && Number(item.count) > 0) {
          count += Number(item.count)
          let price = Number(item.price)
          total += price * Number(item.count)
        }
      })
      let rate = this.form.exchangeRate
      this.form.totalPrice = total
      this.form.count = count

      // 计算获得积分,（1kgs=1分，一个数码产品=1分）
      this.form.incomeCredit = Number(this.form.weight) + Number(count)

      this.form.fee = (
        total +
        this.form.weight * this.form.airPrice +
        Number(this.form.packingFee) +
        Number(this.form.cost) +
        Number(this.form.handlingFee ? this.form.handlingFee : 0)
      ).toString()

      this.form.paid = this.form.fee - Number(this.form.discount || 0)

      this.form.paypal = (this.form.paid / charge / Number(rate))
        .toFixed(2)
        .toString()
    },
    printAll() {
      if (this.selection.length) {
        this.printList = [...this.selection]
        this.$nextTick(() => {
          window.print()
        })
        return
      }
      this.$message.error('请选择出货信息')
    },
    onSelectionChange(selection) {
      this.selection = selection
    },
    showDetail(item, showDialog = true) {
      let expressInfo = item.get('expressInfo')
      if (expressInfo.length) {
        var cql =
          'select * from expressInfo where objectId in (' +
          expressInfo.map(n => `"${n.toString()}"`).join(',') +
          ')'
      }
      return this.AV.Query.doCloudQuery(cql).then(data => {
        let { results } = data
        this.detailList = results
        if (showDialog) {
          this.isShowDetail = true
        }
        return results
      })
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop)
      }
    },
    formatDate(d) {
      if (!d) {
        return ''
      }
      let fm = n => {
        return n < 10 ? '0' + n.toString() : n.toString()
      }
      let arr = []
      arr.push(d.getFullYear())
      arr.push(fm(d.getMonth() + 1))
      arr.push(fm(d.getDate()))
      return arr.join('-')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let params = { ...this.params }
      let { isVip } = params
      let innerQuery = {}
      let query = new this.AV.Query('cargoInfo')
      if (isVip == 'vip') {
        innerQuery = new this.AV.Query('clientInfo')
        innerQuery.exists('userInfo')
        query.matchesQuery('clientId', innerQuery)
      }
      if (isVip == 'not') {
        innerQuery = new this.AV.Query('clientInfo')
        innerQuery.doesNotExist('userInfo')
        query.matchesQuery('clientId', innerQuery)
      }
      query.include('clientId')
      query.include('operator')
      query.include('confirmer')
      query[this.defaultSort.order](this.defaultSort.prop)

      let keys = Object.keys(params)
      keys.forEach(key => {
        let val = params[key]
        if (key == 'date' && val) {
          let [startDate, endDate] = params.date
          if (startDate.getTime() == endDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1)
          }
          query.greaterThanOrEqualTo('createdAt', startDate)
          query.lessThanOrEqualTo('createdAt', endDate)
        } else if (val && key === 'cargoNo') {
          query.contains('cargoNo', val)
        } else if (val && key === 'copyNo') {
          query.equalTo('copyNo', val)
        } else if (val && key != 'isVip') {
          val = key === 'payee' ? val : val.toUpperCase()
          query.startsWith(key, val)
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then(list => {
        this.dataList = list
        this.isLoading = false
      })
      query.count().then(count => {
        this.page.total = count
      })
    },
    showEdit(item) {
      this.currentItem = item
      this.showDetail(item, false).then(() => {
        this.$set(this.form, 'cost', item.get('cost') || this.detailTitle.cost)
      })
      let clientId = item.get('clientId')
      if (clientId) {
        let level = clientId.get('level')
        let scale = this.creditList
          .find(n => n.get('level') == level)
          .get('scale')

        this.$set(this.form, 'mobileNumber', clientId.get('mobileNumber'))
        // this.$set(this.form, 'clientRemark', clientId.get('remark'))
        this.$set(this.clientCredit, 'credit', clientId.get('credit'))
        this.$set(this.clientCredit, 'totalCredit', clientId.get('totalCredit'))
        this.$set(this.clientCredit, 'costCredit', clientId.get('costCredit'))
        this.$set(this.clientCredit, 'level', level)
        this.$set(this.clientCredit, 'scale', scale)
        this.$set(this.clientCredit, 'hasCostPoint', item.get('hasCostPoint'))
      }
      this.isDelivery = item.get('status') == '出库'

      let airPrice =
        this.currentItem.get('airPrice') ||
        this.otherInfo.find(c => c.key == 'airPrice').value
      this.$set(this.form, 'airPrice', airPrice)

      let exchangeRate =
        this.currentItem.get('exchangeRate') ||
        this.otherInfo.find(c => c.key == 'exchangeRate').value
      this.$set(this.form, 'exchangeRate', exchangeRate)

      this.$set(this.form, 'weight', '0')
      this.$set(this.form, 'packingFee', '0')
      this.columns.forEach(n => {
        if (n.show.includes('edit')) {
          let val = item.get(n.prop)
          if (n.prop == 'cargoNumber') {
            val = val.replace('ATM', '')
          }
          if (val !== undefined) {
            this.$set(this.form, n.prop, val)
          }
        }
      })
      if (item.get('countInfo')) {
        this.countInfo = item.get('countInfo')
      } else {
        this.countInfo = JSON.parse(
          this.config.find(c => c.get('key') == 'countInfo').get('value')
        )
      }
      if (!item.get('feeStatus')) {
        this.$set(this.form, 'feeStatus', 'NO')
      }
      if (this.currentItem.get('creditCost')) {
        this.$set(this.form, 'creditCost', this.currentItem.get('creditCost'))
      }
      if (this.currentItem.get('discount')) {
        this.$set(this.form, 'discount', this.currentItem.get('discount'))
      }
      this.$set(this.form, 'unit', this.currentItem.get('unit') || 'kg')
      this.isShowDialog = true
      setTimeout(() => {
        this.$refs.form.validate()
      }, 1000)
    },

    async onSave() {
      this.$refs.form.validateField('weight', err => {
        const weightWarning = err ? true : false
        this.onSaveLoading = true
        let postData = {
          ...this.form,
          weight: this.form.weight.toString(),
          discount: Number(this.form.discount) || 0,
          packingFee: this.form.packingFee.toString(),
          airPrice: this.form.airPrice.toString(),
          weightWarning,
        }
        if (this.form.handlingFee) {
          postData.handlingFee = Number(this.form.handlingFee)
        }
        if (postData.credit) {
          delete postData.credit
        }

        // 修改已有
        if (this.currentItem.id) {
          // 添加ATM前缀
          let { cargoNumber } = postData
          if (cargoNumber && cargoNumber.indexOf('ATM') == -1) {
            postData.cargoNumber = 'ATM' + cargoNumber
          }

          // 带电产品
          postData.countInfo = this.countInfo

          // 保存用户号码到用户信息
          let clientId = this.currentItem.get('clientId')
          if (clientId) {
            clientId.set('mobileNumber', postData.mobileNumber)
          }

          // 兑换积分
          if (this.form.creditCost) {
            postData.creditCost = Number(this.form.creditCost)
          }

          if (!clientId.get('user')) {
            delete postData.incomeCredit
          }

          // 保存可以修改的部分
          this.currentItem.save(postData).then(
            () => {
              this.onSaveLoading = false
              this.$message.success('修改成功')
              this.isShowDialog = false
              // 只对绑定账号生效, 首次奖励积分
              if (clientId.get('user') && !postData.hasIncomeCredit) {
                this.onSaveCreditAdd()
              }
              this.$nextTick(() => {
                this.query()
              })
            },
            err => {
              this.$message.error(err.message)
              this.onSaveLoading = false
            }
          )
        }
      })
      this.onSaveLoading = true
      let postData = {
        ...this.form,
        weight: this.form.weight.toString(),
        discount: Number(this.form.discount) || 0,
        packingFee: this.form.packingFee.toString(),
        airPrice: this.form.airPrice.toString(),
      }
      if (this.form.handlingFee) {
        postData.handlingFee = Number(this.form.handlingFee)
      }
      if (postData.credit) {
        delete postData.credit
      }

      // 修改已有
      if (this.currentItem.id) {
        // 添加ATM前缀
        let { cargoNumber } = postData
        if (cargoNumber && cargoNumber.indexOf('ATM') == -1) {
          postData.cargoNumber = 'ATM' + cargoNumber
        }

        // 带电产品
        postData.countInfo = this.countInfo

        // 保存用户号码到用户信息
        let clientId = this.currentItem.get('clientId')
        if (clientId) {
          clientId.set('mobileNumber', postData.mobileNumber)
        }

        // 兑换积分
        if (this.form.creditCost) {
          postData.creditCost = Number(this.form.creditCost)
        }

        if (!clientId.get('user')) {
          delete postData.incomeCredit
        }

        // 保存可以修改的部分
        this.currentItem.save(postData).then(
          () => {
            this.onSaveLoading = false
            this.$message.success('修改成功')
            this.isShowDialog = false
            // 只对绑定账号生效, 首次奖励积分
            if (clientId.get('user') && !postData.hasIncomeCredit) {
              this.onSaveCreditAdd()
            }
            this.$nextTick(() => {
              this.query()
            })
          },
          err => {
            this.$message.error(err.message)
            this.onSaveLoading = false
          }
        )
      }
    },

    getConfig() {
      let query = new this.AV.Query('config')
      query.find().then(list => {
        this.config = list

        this.countInfo = JSON.parse(
          list.find(c => c.get('key') == 'countInfo').get('value')
        )

        this.otherInfo = JSON.parse(
          list.find(c => c.get('key') == 'otherInfo').get('value')
        )
      })
    },
  },

  mounted() {
    this.currentUser = this.AV.User.current()
    this.expressInfo = this.AV.Object.extend('expressInfo')
    this.storeInfo = this.AV.Object.extend('storeInfo')
    this.clientInfo = this.AV.Object.extend('clientInfo')
    this.creditDetail = this.AV.Object.extend('creditDetail')
    this.cargoLogistics = this.AV.Object.extend('cargoLogistics')
    this.$store.dispatch('getCreditList').then(list => {
      this.creditList = list
    })
    this.getConfig()
    this.query()
  },
}
</script>

<style lang="less">
.popper-class {
  img {
    max-width: 600px;
    max-height: 600px;
  }
}
.ui {
  &-xs {
    .el-dialog__body {
      padding: 0;
    }
  }
  &-count-title {
    border-left: 3px solid #409eff;
    font-size: 16px;
    padding: 5px 10px;
    margin: 0 0 20px;
  }
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
@media print {
  .ui-print {
    display: block;
    h1,
    h2 {
      text-align: center;
    }
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
  }
  .ui-content,
  .el-header,
  .el-aside {
    display: none;
  }
}
</style>
