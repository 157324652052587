<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline="">
        <el-form-item>
          <AutoQuery
            v-model="params.name"
            placeholder="Name"
            :params="{ className: 'companyInfo', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button type="success" @click="showNew" size="small"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        v-loading="isLoading"
      >
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                      >{{ col.labels[idx] }}</el-tag
                    >
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <template v-else>
                {{ formatData(row, col) }}
              </template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          :width="actions.length * 80"
          v-if="actions.length"
        >
          <template slot-scope="{ row }">
            <el-button
              :type="n.type || 'primary'"
              @click="n.click(row)"
              size="mini"
              plain
              v-for="(n, i) in actions"
              :key="i"
              >{{ n.label }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="供应商"
      :visible.sync="isShow"
      width="50%"
      v-if="!isReset"
      @closed="onClosed"
    >
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="名称" prop="name" :rules="formRules.text">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      isReset: false,
      isLoading: false,
      form: {},
      page: {
        no: 1,
        size: 100,
        total: 0,
      },
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      companyInfo: {},
      currentParams: {},
      params: {},
      defaultSort: { order: "descending", prop: "updatedAt" },
      actions: [
        {
          label: "修改",
          click: (item) => {
            this.showEdit(item);
          },
        },
        {
          label: "删除",
          type: "danger",
          click: (item) => {
            this.$confirm("确认删除?").then(() => {
              item.destroy().then(() => {
                this.$message.success("删除成功!");
                this.query();
              });
            });
          },
        },
      ],
      columns: [
        {
          label: "名称",
          prop: "name",
          show: ["table", "form"],
        },
        {
          label: "创建时间",
          prop: "createdAt",
          show: ["table"],
          format: (item) => {
            return this.formatDate(item.createdAt);
          },
        },
      ],
      currentItem: {},
    };
  },

  computed: {
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170;
      }
      return 0;
    },
  },

  methods: {
    onClosed() {
      this.isReset = true;
      this.$nextTick(() => {
        this.isReset = false;
      });
    },
    onPageChange(page) {
      this.page.no = page;
      this.query();
    },
    onSizeChange(size) {
      this.page.size = size;
      this.query();
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item);
      } else {
        return item.get(col.prop);
      }
    },
    formatDate(d) {
      if (!d) {
        return "";
      }
      let fm = (n) => {
        return n < 10 ? "0" + n.toString() : n.toString();
      };
      let arr = [];
      arr.push(d.getFullYear());
      arr.push(fm(d.getMonth() + 1));
      arr.push(fm(d.getDate()));
      return arr.join("-");
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order };
      this.page.no = 1;
      this.query();
    },
    query() {
      this.isLoading = true;
      let query = new this.AV.Query("companyInfo");
      query[this.defaultSort.order](this.defaultSort.prop);
      let keys = Object.keys(this.params);
      keys.forEach((key) => {
        let val = this.params[key];
        if (val) {
          query.contains(key, val);
        }
      });

      query.limit(this.page.size);
      query.skip(this.page.size * (this.page.no - 1));

      query.find().then((list) => {
        this.dataList = list;
        this.isLoading = false;
      });
      query.count().then((count) => {
        this.page.total = count;
      });
    },

    showNew() {
      this.currentItem = {};
      this.form = {};
      this.isShow = true;
    },
    showEdit(item) {
      this.currentItem = item;
      this.columns.forEach((n) => {
        if (n.show.includes("form")) {
          this.$set(this.form, n.prop, item.get(n.prop));
        }
      });
      this.isShow = true;
    },

    checkName(name) {
      let query = new this.AV.Query("storeInfo");
      query.equalTo("name", name);
      return query.first().then((ret) => {
        return ret ? true : false;
      });
    },

    onSave() {
      let postData = { ...this.form };

      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // 修改已有客户
          if (this.currentItem.id) {
            // 保存可以修改的部分
            this.currentItem.save(postData).then(() => {
              this.$message.success("修改成功");
              this.isShow = false;
              this.query();
            });
            return;
          }

          let { name } = postData;
          this.checkName(name).then((ret) => {
            if (ret) {
              this.$message.error("改储位号已被使用, 请修改重试");
              return;
            }
            let obj = new this.companyInfo();
            obj.save(postData).then(() => {
              this.$message.success("添加成功");
              this.isShow = false;
              this.query();
            });
          });
        }
      });
    },
  },

  mounted() {
    this.expressInfo = this.AV.Object.extend("expressInfo");
    this.storeInfo = this.AV.Object.extend("storeInfo");
    this.companyInfo = this.AV.Object.extend("companyInfo");
    this.query();
  },
};
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
