<template>
  <div class="ui-main">
    <div class="ui-content">
      <div class="ui-query">
        <el-form :model="params" size="small" inline>
          <el-form-item>
            <el-input
              v-model="params.cargoNo"
              placeholder="Cargo No"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.cargoNumber"
              placeholder="Shipping No"
              :params="{
                className: 'cargoInfo',
                field: 'cargoNumber',
              }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.mark"
              placeholder="Mark"
              :params="{ className: 'cargoInfo', field: 'mark' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <AutoQuery
              v-model="params.clientName"
              placeholder="Customer"
              :params="{ className: 'clientInfo', field: 'name' }"
              @enter="query"
            ></AutoQuery>
          </el-form-item>
          <el-form-item>
            <el-popover
              placement="bottom"
              trigger="click"
              title="选择打印模式"
              v-model="isShowPopover"
            >
              <el-row>
                <el-button type="info" @click="printAll">普通</el-button>
                <el-button type="primary" @click="printAll('encryption')">
                  加密
                </el-button>
                <el-button type="danger" @click="printAll('combination')">
                  组合码
                </el-button>
              </el-row>
              <el-button slot="reference" type="info">批量打印</el-button>
            </el-popover>
            <!-- <el-popconfirm
              confirm-button-text="普通"
              cancel-button-text="加密"
              icon="el-icon-info"
              icon-color="red"
              cancel-button-type="info"
              title="选择打印模式"
              @confirm="printAll"
              @cancel="printAll('encryption')"
            >
              <el-button slot="reference" type="info" size="mini">
                批量打印
              </el-button>
            </el-popconfirm> -->
            <!-- <el-button type="info" @click="printAll" size="small">
              批量打印
            </el-button> -->
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="createLogistics" size="small">
              设置物流信息
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query" size="small">
              搜索
            </el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="multiSetCargoNo" size="small">
              批量修复
            </el-button>
          </el-form-item> -->
        </el-form>
      </div>

      <div class="ui-table">
        <el-table
          @selection-change="onSelectionChange"
          :data="tableDataList"
          :height="tableHeight"
          @sort-change="onSortChange"
          v-loading="isLoading"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            :sortable="col.sortable === false ? false : 'custom'"
            v-for="col in columns"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :fixed="col.fixed"
            :min-width="col.width || 120"
          >
            <template slot-scope="{ row }" v-if="col.show.includes('table')">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            fixed="right"
            :width="actions.length * 60"
          >
            <template slot-scope="{ row }">
              <template v-for="(n, i) in actions">
                <template v-if="n.type === 'upload'">
                  <el-upload
                    action="##"
                    accept="image/*"
                    :show-file-list="false"
                    :key="i"
                    style="display: inline-block"
                    :on-change="n.upload"
                    :auto-upload="false"
                  >
                    <el-popover
                      placement="left"
                      trigger="hover"
                      v-if="row.get('previewImg')"
                      popper-class="popper-class"
                      :open-delay="300"
                    >
                      <img
                        :src="row.url"
                        slot="reference"
                        class="ui-img-thumbnail"
                        @click="n.click(row)"
                      />
                      <img :src="row.oUrl" class="ui-img-preview" />
                    </el-popover>
                    <el-button
                      v-else
                      type="text"
                      size="mini"
                      :class="n.type ? 'ui-btn-' + n.type : 'ui-btn-primary'"
                      @click="n.click(row)"
                    >
                      上传
                    </el-button>
                  </el-upload>
                </template>
                <template v-else>
                  <el-button
                    type="text"
                    @click="n.click(row)"
                    size="mini"
                    :class="n.type ? 'ui-btn-' + n.type : 'ui-btn-primary'"
                    :key="i"
                  >
                    {{ n.label }}
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="ui-page">
        <el-pagination
          layout="total, sizes, prev, pager, next"
          :total="page.total"
          :page-sizes="[50, 100, 500, 1000]"
          :page-size="page.size"
          :current-page="page.no"
          @current-change="onPageChange"
          @size-change="onSizeChange"
        ></el-pagination>
      </div>
    </div>

    <el-dialog title="出库信息" :visible.sync="isShow" width="50%">
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="客户">{{ form.clientName }}</el-form-item>
        <el-form-item label="订单数" v-if="form.expressInfo">
          {{ form.expressInfo.length }}
        </el-form-item>
        <el-form-item label="出库单号">
          <el-input v-model="form.cargoNumber" placeholder="请输入">
            <template slot="prepend">ATM</template>
          </el-input>
        </el-form-item>
        <template v-for="(item, idx) in columns">
          <el-form-item
            :label="item.label"
            v-if="item.show.includes('form')"
            :key="idx"
          >
            <template v-if="item.type == 'number'">
              <el-input
                v-model.trim.number="form[item.prop]"
                placeholder="请输入"
                type="number"
              ></el-input>
            </template>
            <template v-else-if="item.type == 'textarea'">
              <el-input
                v-model="form[item.prop]"
                type="textarea"
                placeholder="请输入"
              ></el-input>
            </template>
            <template v-else>
              <el-input
                v-model="form[item.prop]"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-form-item>
        </template>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'订单明细 总数量: ' + detailList.length"
      :visible.sync="isShowDetail"
      width="80%"
      top="20px"
    >
      <template slot="title">
        <el-row>
          <el-col :span="5"
            ><label style="padding: 0 10px 0 0"> 订单明细 总数量: </label>
            {{ detailTitle.quantity }}
          </el-col>
          <el-col :span="5">
            <label style="padding: 0 10px 0 0"> Total estimate weight: </label>
            {{ detailTitle.weight }}
          </el-col>
        </el-row>
      </template>
      <el-table :data="detailList">
        <el-table-column
          :label="col.label"
          v-for="(col, idx) in detailColumns"
          :key="idx"
        >
          <template slot-scope="{ row }">{{
            col.format ? col.format(row) : row.get(col.prop)
          }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="物流信息明细"
      :visible.sync="isShowLogistics"
      width="80%"
      top="20px"
    >
      <el-table :data="logisticsList">
        <el-table-column
          :label="col.label"
          v-for="(col, idx) in logisticsColumns"
          :key="idx"
        >
          <template slot-scope="{ row }">{{
            col.format ? col.format(row) : row.get(col.prop)
          }}</template>
        </el-table-column>

        <el-table-column
          label="操作"
          fixed="right"
          :width="logisticsActions.length * 80"
        >
          <template slot="header">
            <el-button
              type="primary"
              size="mini"
              @click="showLogisticsNew"
              :disabled="
                currentItem.get &&
                currentItem.get('expressStatus') === 'Picked Up'
              "
              >创建</el-button
            >
          </template>
          <template slot-scope="{ row }">
            <el-button
              :type="n.type || 'primary'"
              @click="n.click(row)"
              size="mini"
              v-for="(n, i) in logisticsActions"
              :key="i"
              >{{ n.label }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="物流信息" :visible.sync="isShowLogisticsForm" width="50%">
      <el-form
        :model="logisticsForm"
        label-width="100px"
        class="ui-form"
        label-position="left"
      >
        <template v-for="(item, idx) in logisticsColumns">
          <el-form-item :label="item.label" :key="idx">
            <template v-if="item.type == 'number'">
              <el-input
                v-model.trim.number="logisticsForm[item.prop]"
                placeholder="请输入"
                type="number"
                clearable
              ></el-input>
            </template>
            <template v-else-if="item.type == 'textarea'">
              <el-input
                v-model="logisticsForm[item.prop]"
                type="textarea"
                placeholder="请输入"
                clearable
              ></el-input>
            </template>
            <template v-else-if="item.type == 'datetime'">
              <el-date-picker
                v-model="logisticsForm[item.prop]"
                type="datetime"
                placeholder="选择日期时间"
                style="width: 100%"
                clearable
              ></el-date-picker>
            </template>
            <template v-else-if="item.type == 'select-create'">
              <el-select
                v-model="logisticsForm[item.prop]"
                filterable
                allow-create
                default-first-option
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template>
            <template v-else-if="item.type == 'select'">
              <el-select
                v-model="logisticsForm[item.prop]"
                filterable
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="o in item.options"
                  :key="o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-input
                v-model.trim="logisticsForm[item.prop]"
                placeholder="请输入"
                clearable
              ></el-input>
            </template>
          </el-form-item>
        </template>
      </el-form>

      <span slot="footer">
        <el-button @click="isShowLogisticsForm = false">取 消</el-button>
        <el-button type="primary" @click="onSaveLogistics">确 定</el-button>
      </span>
    </el-dialog>

    <div class="ui-print" v-for="(item, idx) in printList" :key="idx">
      <template v-if="printType === 'combination'">
        <div class="inner inner-combination">
          <table
            style="border: none; border-collapse: collapse"
            border="0"
            cellpadding="0"
            cellspacing="0"
          >
            <tr>
              <td align="center">
                <img :id="'barcode-combination-' + idx" />
              </td>
            </tr>
            <tr>
              <td align="center" style="font-size: 60px">
                {{ item.combinationCode }}
              </td>
            </tr>
            <tr>
              <td align="center" style="font-size: 60px">
                {{ item.plusInfo }}
              </td>
            </tr>
          </table>
        </div>
      </template>
      <template v-else>
        <div class="inner">
          <div class="title">
            <p style="font-size: 35px; float: left; line-height: 120px">
              ATM CARGO
            </p>
            <img
              :id="'barcode-' + idx"
              style="float: right; max-height: 120px"
            />
          </div>
          <table v-if="item.id">
            <tr>
              <td>CN</td>
              <td colspan="2">
                <span class="td name2">{{
                  item.printType === 'encryption'
                    ? item.get('clientName').substring(0, 3)
                    : item.get('clientName')
                }}</span>
              </td>
              <td>{{ item.userId }}</td>
            </tr>
            <tr>
              <td>QTY</td>
              <td>
                <span class="td info"
                  >{{ item.get('expressInfo').length }} Pcs</span
                >
              </td>
              <td>PH</td>
              <td>
                <span class="td info">{{ item.get('mobileCount') }} Pcs</span>
              </td>
            </tr>
            <tr>
              <td>NO.</td>
              <td>
                <span class="td info">{{ item.get('cargoNumber') }}</span>
              </td>
              <td>Mark</td>
              <td>
                <span class="td info">{{ item.get('mark') }}</span>
              </td>
            </tr>
            <tr>
              <td>PHONE NO</td>
              <td>
                <span class="td info">
                  {{
                    item.printType === 'encryption'
                      ? desensitization(
                          item.get('clientId').get('mobileNumber')
                        )
                      : item.get('clientId').get('mobileNumber')
                  }}
                </span>
              </td>
              <td>ADDRESS</td>
              <td>
                <span class="td info">
                  {{ item.get('clientId').get('address') }}
                </span>
              </td>
            </tr>
            <!-- <tr>
            <td>ADRESS</td>
            <td colspan="3">
              <span class="td info">{{
                item.get('clientId').get('address')
              }}</span>
            </td>
          </tr>-->
            <tr>
              <td>CARGO NO.</td>
              <td>
                <span class="td info">
                  {{ item.get('cargoNo') }}
                </span>
              </td>
              <td>Shelves No.</td>
              <td>
                <span class="td info">{{ item.get('storeName') }}</span>
              </td>
            </tr>
            <tr>
              <td>REMARK</td>
              <td colspan="3">
                <span class="td info">
                  {{ item.get('remark') }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const JsBarcode = require('jsbarcode')
export default {
  data() {
    return {
      printType: '',
      isShowPopover: false,
      configList: [],
      isShowLogistics: false,
      isShowLogisticsForm: false,
      logisticsList: [],
      logisticsForm: {},
      logisticsColumns: [
        {
          label: 'Time时间',
          prop: 'time',
          key: 'expressTime',
          type: 'datetime',
          format: item => {
            let val = item.get('time')
            return this.$dayjs(val).format('YYYY-MM-DD HH:mm')
          },
        },
        {
          label: 'Place地点',
          prop: 'place',
          key: 'expressPlace',
          type: 'select',
          options: [],
        },
        {
          label: 'Detail明细',
          prop: 'detail',
          key: 'expressDetail',
          type: 'select',
          options: [],
        },
        {
          label: 'Status状态',
          prop: 'status',
          key: 'expressStatus',
          type: 'select',
          options: [],
        },
      ],
      logisticsActions: [
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$confirm(`确认删除 ?`).then(() => {
              item.destroy().then(() => {
                this.$message.success('删除成功!')
                this.queryLogistics()
              })
            })
          },
        },
      ],
      currentUser: {},
      printList: [],
      selection: [],
      isShow: false,
      isLoading: false,
      form: {},
      page: {
        no: 1,
        size: 50,
        total: 0,
      },
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      clientInfo: {},
      cargoLogistics: {},
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'updatedAt' },
      detailList: [],
      isShowDetail: false,
      actions: [
        {
          label: '上传',
          icon: 'el-icon-upload',
          type: 'upload',
          click: item => {
            this.currentItem = item
          },
          upload: e => {
            let $file = e.raw
            let suffix = $file.name.split('.').pop().toLowerCase()
            let name = new Date().getTime() + '.' + suffix
            let file = new this.AV.File(name, $file)
            this.currentItem
              .save({
                previewImg: file,
              })
              .then(() => {
                this.$message.success('上传成功 !')
              })
          },
        },
        {
          label: '修改',
          click: item => {
            this.showEdit(item)
          },
        },
        {
          label: '明细',
          click: item => {
            this.showDetail(item)
          },
        },
        {
          label: '物流',
          click: item => {
            this.showLogistics(item)
          },
        },
        {
          label: '打印',
          type: 'info',
          click: item => {
            this.currentItem = item
            this.printList = [item]
            this.$nextTick(() => {
              this.goPrint()
            })
          },
        },
      ],
      columns: [
        {
          label: '出库单号',
          prop: 'cargoNumber',
          width: 160,
          show: ['table', 'edit'],
          fixed: 'left',
        },
        {
          label: '物流单号',
          prop: 'cargoNo',
          width: 200,
          show: ['table'],
          fixed: 'left',
        },

        {
          label: '麦头',
          prop: 'mark',
          show: ['table', 'form', 'edit'],
        },
        {
          label: '客户名',
          prop: 'clientName',
          show: ['table', 'edit'],
          width: 160,
          format: item => {
            if (item.get('clientId')) {
              return item.get('clientId').get('name')
            }
            return item.get('client')
          },
        },
        {
          label: '订单数',
          prop: 'expressInfo',
          show: ['table'],
          format: item => {
            return item.get('expressInfo').length
          },
        },
        {
          label: '储位号',
          prop: 'storeName',
          type: 'number',
          show: ['table', 'form', 'edit'],
        },
        {
          label: '手机数',
          prop: 'mobileCount',
          type: 'number',
          show: ['table', 'form', 'edit'],
        },
        {
          label: '时间',
          prop: 'expressTime',
          show: ['table'],
          width: 160,
          format: item => this.formatDate(item.get('expressTime')),
        },
        {
          label: '地点',
          prop: 'expressPlace',
          show: ['table'],
          width: 160,
        },
        {
          label: '明细',
          prop: 'expressDetail',
          show: ['table'],
          width: 160,
        },
        {
          label: '状态',
          prop: 'expressStatus',
          show: ['table'],
          width: 160,
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          show: ['table', 'form', 'edit'],
        },
        {
          label: '出库人',
          prop: 'credit',
          show: ['table'],
          format: item => {
            if (item.get('operator')) {
              return item.get('operator').get('username')
            }
          },
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          show: ['table'],
          width: 160,
          format: item => {
            return this.formatDate(item.createdAt)
          },
        },
      ],
      detailColumns: [
        {
          label: '单号',
          prop: 'id',
        },
        {
          label: '客户名',
          prop: 'client',
        },
        {
          label: '储位号',
          prop: 'storeName',
        },
        {
          label: '确认情况',
          prop: 'confirmStatus',
        },
        {
          label: '重量',
          prop: 'weight',
        },
        {
          label: '备注',
          prop: 'confirmRemark',
        },
        {
          label: '供应商',
          prop: 'company',
        },
        {
          label: '出库时间',
          prop: 'deliveryDate',
          format: value => {
            return this.formatDate(value.get('deliveryDate'))
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    detailTitle() {
      let weight = this.detailList.reduce((total, item) => {
        let w = item.get('weight')
        if (w) {
          w = Number(w)
          return total + w
        }
        return total
      }, 0)
      return {
        quantity: this.detailList.length,
        weight: weight.toFixed(2),
      }
    },
    tableDataList() {
      if (this.dataList.length) {
        return this.dataList.map(item => {
          let previewImg = item.get('previewImg')
          if (previewImg) {
            item.oUrl = previewImg.url()
            item.url = previewImg.thumbnailURL(100, 100)
          }
          return item
        })
      }
      return []
    },
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170
      }
      return 0
    },
  },

  methods: {
    desensitization(name) {
      if (!name) {
        return
      }
      return name.substr(0, 4) + '****'
    },
    goPrint(type = 'normal') {
      this.printList.forEach((n, idx) => {
        const clientId = n.get('clientId')
        const expressInfo = n.get('expressInfo') || []
        const storeName = n.get('storeName') || ''
        // const cargoNumber = n.get('cargoNumber') || ''
        const cargoNo = n.get('cargoNo') || ''
        const mark = n.get('mark')

        let userId = ''
        let name = ''
        let address = ''
        if (clientId) {
          userId = clientId.get('userId')
          name = clientId.get('name')
          address = clientId.get('address')
          this.$set(n, 'userId', userId)
        }

        const combinationCode = `${userId}-${name.substring(0, 3)}-${
          expressInfo.length
        }-${cargoNo.substring(
          cargoNo.length - 11,
          cargoNo.length - 5
        )}-${cargoNo.substring(cargoNo.length - 5)}-${mark}`

        const plusInfo = `${storeName}-${address}`

        this.$set(n, 'combinationCode', combinationCode)
        this.$set(n, 'plusInfo', plusInfo)

        this.$set(n, 'printType', type)

        const barcodeConfig = {
          height: type === 'combination' ? 200 : 120,
          width: 7,
          fontSize: 40,
          font: 'Microsoft Yahei',
          flat: true,
          displayValue: type !== 'combination',
        }

        const imgId = `#barcode-${
          type === 'combination' ? 'combination-' : ''
        }${idx}`
        try {
          if (cargoNo) {
            JsBarcode(imgId, cargoNo, barcodeConfig)
          }
        } catch (e) {
          console.log('JsBarcode error', e)
        }
        // if (/^[a-zA-Z0-9_-]{1,100}$/.test(cargoNo)) {
        //   JsBarcode(id, cargoNo, barcodeConfig)
        // }
      })
      setTimeout(() => {
        window.print()
      }, 300)
    },
    // 批量更新物流信息
    createLogistics() {
      if (this.selection.length) {
        this.currentItem = {}
        this.showLogisticsNew()
        return
      }
      this.$message.error('请选择出货信息')
    },
    getConfig() {
      let query = new this.AV.Query('config')
      return query.find().then(list => {
        this.configList = list
        this.logisticsColumns.forEach(n => {
          if (n.key) {
            let ret = list.find(m => m.get('key') === n.key)
            ret &&
              (n.options = JSON.parse(ret.get('value')).map(m => {
                return {
                  label: m.value,
                  value: m.value,
                }
              }))
          }
        })
        return list
      })
    },
    showLogisticsNew() {
      this.logisticsColumns.forEach(n => {
        if (n.value) {
          this.$set(this.logisticsForm, n.prop, n.value)
        }
        if (n.type === 'datetime') {
          this.$set(this.logisticsForm, n.prop, new Date())
        }
      })

      this.isShowLogisticsForm = true
    },
    queryLogistics() {
      let query = new this.AV.Query('cargoLogistics')
      query.equalTo('cargo', this.currentItem)
      query.descending('createdAt')
      query.find().then(list => {
        this.logisticsList = list
      })
    },
    showLogistics(item) {
      this.currentItem = item
      this.queryLogistics()
      this.isShowLogistics = true
    },
    printAll(type = 'normal') {
      this.printType = type
      this.isShowPopover = false
      if (this.selection.length) {
        this.printList = [...this.selection]
        this.$nextTick(() => {
          this.goPrint(type)
        })
        return
      }
      this.$message.error('请选择出货信息')
    },
    onSelectionChange(selection) {
      this.selection = selection
    },
    showDetail(item) {
      let expressInfo = item.get('expressInfo')
      if (expressInfo.length) {
        var cql =
          'select * from expressInfo where objectId in (' +
          expressInfo.map(n => `"${n.toString()}"`).join(',') +
          ')'
      }
      this.AV.Query.doCloudQuery(cql).then(data => {
        let { results } = data
        this.detailList = results
        this.isShowDetail = true
      })
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop) || item[col.prop]
      }
    },
    formatDate(d) {
      return this.$dayjs(d).format('YYYY-MM-DD HH:mm')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('cargoInfo')
      query.include('clientId')
      query.include('operator')
      query[this.defaultSort.order](this.defaultSort.prop)
      let keys = Object.keys(this.params)
      keys.forEach(key => {
        let val = this.params[key]
        if (val) {
          query.startsWith(key, val.toUpperCase())
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then(list => {
        this.dataList = list
        this.isLoading = false
      })
      query.count().then(count => {
        this.page.total = count
      })
    },

    showNew() {
      this.currentItem = {}
      this.form = {}
      this.isShow = true
    },
    showEdit(item) {
      this.currentItem = item
      this.isDelivery = item.get('status') == '出库'
      this.columns.forEach(n => {
        if (n.show.includes('edit')) {
          let val = item.get(n.prop)
          if (n.prop == 'cargoNumber') {
            val = val.replace('ATM', '')
          }
          this.$set(this.form, n.prop, val)
        }
      })
      this.isShow = true
    },

    onSaveLogistics() {
      // 以单个出货单优先
      let selection = this.currentItem.id ? [this.currentItem] : this.selection

      let keys = Object.keys(this.logisticsForm)
      let itemObj = {}
      keys.forEach(key => {
        let value = this.logisticsForm[key]
        let prop = this.logisticsColumns.find(n => n.prop === key)
        let setKey = prop.key
        itemObj[setKey] = value
      })

      let objs = selection.map(item => {
        // 储存最新一条物流信息
        item.set({ ...itemObj })
        // 创建新的物流
        let obj = new this.cargoLogistics()
        obj.set({
          ...this.logisticsForm,
          cargo: item,
        })
        return obj
      })

      this.AV.Object.saveAll(objs).then(() => {
        this.$message.success('提交成功 !')
        this.isShowLogisticsForm = false
        this.queryLogistics()
      })
    },

    onSave() {
      let postData = { ...this.form }

      // 修改已有
      if (this.currentItem.id) {
        let { cargoNumber } = postData
        if (cargoNumber && cargoNumber.indexOf('ATM') == -1) {
          postData.cargoNumber = 'ATM' + cargoNumber
        }
        // 保存可以修改的部分
        this.currentItem.save(postData).then(() => {
          this.$message.success('修改成功')
          this.isShow = false
          this.query()
        })
      }
    },
    /**
     * 批量设置物流单号
     * 临时修复用
     */
    multiSetCargoNo() {
      let query = new this.AV.Query('cargoInfo')
      query.equalTo('cargoNumber', 'ATM230523')
      query.limit(1000)
      query.doesNotExist('cargoNo')
      // query.greaterThanOrEqualTo('createdAt', new Date('2020-07-01 00:00:00'))
      query.find().then(list => {
        list.forEach(item => {
          let cargoNo = `${item.get('cargoNumber')}${item.id
            .substr(-4)
            .toUpperCase()}`
          item.set('cargoNo', cargoNo)
        })
        // console.log('🚀 ~ file: Cargo.vue:1008 ~ list.forEach ~ list:', list)
        this.AV.Object.saveAll(list).then(res => {
          console.log('res', res)
        })
      })
    },
    // 批量设置单号
    multiSetLogisticsNumber() {
      let query = new this.AV.Query('expressInfo')
      query.limit(10)
      query.equalTo('status', '出库')
      query.doesNotExist('logisticsNumber')
      query.greaterThanOrEqualTo('createdAt', new Date('2020-07-01 00:00:00'))
      query.find().then(list => {
        this.$log({
          name: 'expressInfo',
          url: '/caogo',
          action: 'find',
          params: { status: '出库' },
        })
        list.forEach(item => {
          let cargoNumber = item.get('cargoNumber')
          let qu = new this.AV.Query('cargoInfo')
          qu.equalTo('cargoNumber', cargoNumber)
          qu.first().then(ret => {
            if (ret) {
              item.set('cargoInfo', ret)
              let cargoNo = ret.get('cargoNo')
              if (cargoNo) {
                item.set('logisticsNumber', cargoNo)
              }
            }
          })
        })
        setTimeout(() => {
          this.AV.Object.saveAll(list).then(() => {
            console.log('保存成功')
          })
        }, 1000 * 20)
      })
    },
  },

  mounted() {
    this.currentUser = this.AV.User.current()
    this.expressInfo = this.AV.Object.extend('expressInfo')
    this.storeInfo = this.AV.Object.extend('storeInfo')
    this.clientInfo = this.AV.Object.extend('clientInfo')
    this.cargoLogistics = this.AV.Object.extend('cargoLogistics')
    this.query()
    this.getConfig()
  },
}
</script>

<style lang="less">
.popper-class {
  img {
    max-width: 600px;
    max-height: 600px;
  }
}
.ui {
  &-print {
    .title {
      padding: 0 20px;
    }
    .combination {
      font-size: 40px;
      padding: 140px 0 0;
    }
    img {
      // max-height: 120px;
    }
    &:last-child {
      padding: 1% 0 0;
    }
  }
  &-btn {
    &-primary {
      color: #409eff !important;
    }
    &-danger {
      color: #f56c6c !important;
    }
    &-info {
      color: #909399 !important;
    }
  }
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
@media print {
  .ui-print {
    display: block;
    h1,
    h2 {
      text-align: center;
    }
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
  }
  .ui-content,
  .el-header,
  .el-aside {
    display: none;
  }
  .inner-combination {
    text-align: center;
  }
}
</style>
