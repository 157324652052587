import Vue from 'vue'

Vue.use({
  install: Vue => {
    Vue.mixin({
      data() {
        return {
          formRules: {
            text: [
              {
                required: true,
                message: '不能为空',
                trigger: 'blur',
              },
            ],
            select: [
              {
                required: true,
                message: '不能为空',
                trigger: 'change',
              },
            ],
            selectNum: [
              {
                type: 'number',
                required: true,
                message: '不能为空',
                trigger: 'change',
              },
            ],
            userName: [
              {
                required: true,
                message: '不能为空',
                trigger: 'blur',
              },
              {
                message: '账号仅支持英文、数字、下划线，且不能为纯数字',
                trigger: 'blur',
                pattern: /^[a-zA-Z]\w{4,15}$/
              },
            ],
            password: [
              {
                required: true,
                message: '不能为空',
                trigger: 'blur',
              },
              {
                message: '密码长度不能小于10位',
                trigger: 'blur',
                min: 10,
                max: 20,
              },
              {
                message: '密码需包含字母和数字',
                trigger: 'blur',
                pattern: /^\S*(?=\S{10,})(?=\S*\d)(?=\S*[a-z])\S*$/,
              },
            ],
          },
          password: 'ATM123456',
        }
      },

      methods: {
        goPage(path) {
          this.$router.push(path)
        },
        isUpper(str) {
          return str === str.toUpperCase()
        },
        formatDate(d) {
          if (!d) {
            return ''
          }
          let fm = n => {
            return n < 10 ? '0' + n.toString() : n.toString()
          }
          let arr = []
          arr.push(d.getFullYear())
          arr.push(fm(d.getMonth() + 1))
          arr.push(fm(d.getDate()))
          return arr.join('-')
        },
      },
    })
  },
})
