import Vue from 'vue'

import { createProvider } from './vue-apollo'
import gql from 'graphql-tag'

var AV = require('leancloud-storage/live-query')
let appId = 'IcOMrk7EMP7fU20WeKGAKnmL-gzGzoHsz'
let appKey = 'n3KI1uOx7FKBmUukpxbOcs94'
let masterKey = 'KtxkQBvY07WJDXfoiaPD6gwS'

// if (location.host.match('atmcargomall.com')) {
//   appId = 'MMCiIzRMDwk6PuqemBqjU8Bb-MdYXbMMI'
//   appKey = 'yEutV1pEWqOp5qR3huDk9Vp3'
// }

const apollo = createProvider().defaultClient

class Query extends AV.Query {
  constructor(name) {
    super(name)
    this._fields = `objectId`
  }

  setFields(data) {
    this._fields = data
  }

  async _request(params = this._getParams()) {
    let { className, _fields } = this
    let ret = await apollo.query({
      query: gql`
        query find($params: FindInput) {
          find(params: $params) {
            ... on ${className} {
              ${_fields}
            }
          }
        }
    `,
      variables: {
        params: {
          className,
          ...params,
          ...(params.where ? { where: JSON.stringify(params.where) } : {}),
        },
      },
    })

    return {
      results: ret.data.find,
    }
  }

  _find(options) {
    const request = this._request(undefined, options)
    return request.then(this._parseResponse.bind(this))
  }
}

AV.init({
  appId,
  appKey,
  serverURLs: 'https://atm-api.wallmax.online',
  masterKey
  // serverURLs: 'http://127.0.0.1:7001',
  // realtime: new AV._sharedConfig.liveQueryRealtime({
  //   appId,
  //   appKey
  //   // server: 'avoscloud.com'
  // })
})

Vue.use({
  install(Vue) {
    Vue.AV = AV
    Vue.prototype.AV = AV
    Vue.prototype.$Query = Query
  },
})

export default AV
