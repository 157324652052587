<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline>
        <el-form-item>
          <AutoQuery
            v-model="params.name"
            placeholder="Customer"
            :params="{ className: 'clientInfo', field: 'name' }"
            @enter="query"
            @select="onSelect"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <!-- <el-button type="success" @click="showNew" size="small">
            新增
          </el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        v-loading="isLoading"
      >
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>
        </template>

        <!-- <el-table-column label="操作" fixed="right" width="240px">
          <template slot-scope="{ row }">
            <el-button
              :type="n.type"
              @click="n.click(row)"
              size="mini"
              v-for="(n, i) in actions"
              :key="i"
            >
              {{ n.label }}
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog title="客户信息" :visible.sync="isShow" width="50%">
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="客户名">
          <el-input
            v-model="form.name"
            placeholder="请输入"
            ref="idInput"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="currentName + ' - 关联订单明细'"
      :visible.sync="isShowDetail"
      width="80%"
      @closed="onClosed"
    >
      <el-table :data="orderList">
        <el-table-column label="入库日期">
          <template slot-scope="{ row }">{{
            formatDate(row.get("createdDate"))
          }}</template>
        </el-table-column>
        <el-table-column label="出库日期">
          <template slot-scope="{ row }">{{
            formatDate(row.get("deliveryDate"))
          }}</template>
        </el-table-column>
        <el-table-column label="订单号">
          <template slot-scope="{ row }">{{ row.get("id") }}</template>
        </el-table-column>
        <el-table-column label="储位号">
          <template slot-scope="{ row }">{{ row.get("storeName") }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">{{ row.get("status") }}</template>
        </el-table-column>
        <el-table-column label="供应商">
          <template slot-scope="{ row }">{{ row.get("company") }}</template>
        </el-table-column>
        <el-table-column label="仓库">
          <template slot-scope="{ row }">{{ row.get("address") }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="积分明细" :visible.sync="isShowCredit">
      <el-table :data="creditDetail">
        <el-table-column label=""></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      isShow: false,
      isShowDetail: false,
      isLoading: false,
      isShowCredit: false,
      creditDetail: [],
      form: {},
      page: {
        no: 1,
        size: 100,
        total: 0,
      },
      status: [
        {
          label: "入库",
          value: "1",
          type: "success",
        },
        {
          label: "出库",
          value: "2",
          type: "primary",
        },
        {
          label: "未确认",
          value: "3",
          type: "danger",
        },
      ],
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      clientInfo: {},
      currentParams: {},
      params: {},
      defaultSort: { order: "descending", prop: "createdAt" },
      orderList: [],
      actions: [
        {
          label: "修改",
          click: (item) => {
            this.showEdit(item);
          },
        },
        {
          label: "明细",
          type: "info",
          click: (item) => {
            this.showDetail(item);
          },
        },
        // {
        //   label: '积分明细',
        //   type: 'info',
        //   click: item => {
        //     this.showCredit(item)
        //   }
        // },
        {
          label: "删除",
          type: "danger",
          click: (item) => {
            this.$confirm(`确定删除 ${item.get("name")}`, {
              type: "warning",
            }).then(() => {
              item.destroy().then(() => {
                this.$message.success("删除成功");
                this.query();
              });
            });
          },
        },
      ],
      columns: [
        {
          label: "创建时间",
          prop: "createdAt",
          width: 200,
          show: ["table"],
          format: (item) => {
            return dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          label: "客户名",
          prop: "client",
          width: 200,
          show: ["table", "form"],
          format: (item) => {
            if (item.get("client")) {
              return item.get("client").get("name");
            }
          },
        },
        {
          label: "出库单号",
          prop: "client",
          show: ["table", "form"],
          format: (item) => {
            if (item.get("cargoInfo")) {
              return item.get("cargoInfo").get("cargoNumber");
            }
          },
        },
        {
          label: "麦头",
          prop: "client",
          show: ["table", "form"],
          format: (item) => {
            if (item.get("cargoInfo")) {
              return item.get("cargoInfo").get("mark");
            }
          },
        },
        {
          label: "操作",
          prop: "type",
          show: ["table", "form"],
          format: (item) => {
            let maps = {
              payback: "补偿",
              increase: "奖励",
              exchange: "兑换",
              reward: "手动奖励",
              reduce: "手动扣除",
            };
            return maps[item.get("type")];
          },
        },
        {
          label: "积分",
          prop: "credit",
          show: ["table"],
        },
        {
          label: "剩余积分",
          prop: "creditLeft",
          show: ["table"],
        },
        {
          label: "操作者",
          prop: "credit",
          show: ["table"],
          format: (item) => {
            if (item.get("operator")) {
              return item.get("operator").get("username");
            }
          },
        },
        {
          label: "备注",
          prop: "remark",
          show: ["table"],
        },
      ],
      currentItem: {},
      currentObj: null,
    };
  },

  computed: {
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170;
      }
      return 0;
    },
    currentName() {
      return this.currentItem.id ? this.currentItem.get("name") : "";
    },
  },

  watch: {
    "form.name"(val) {
      if (val && !this.isUpper(val)) {
        this.form.name = val.toUpperCase();
      }
    },
  },

  methods: {
    onSelect(ret) {
      this.currentObj = ret;
    },
    // showCredit(item) {},
    getCode(item) {
      let code = Math.random().toString(36).substr(2);
      item.save({ code }).then(() => {});
    },
    onClosed() {
      this.orderList = [];
    },
    getOrderList(item) {
      let query = new this.AV.Query("expressInfo");
      query.equalTo("clientId", item);
      query.find().then((list) => {
        this.$log({
          name: "expressInfo",
          url: "/Credit",
          action: "find",
          params: { clientId: item },
        });
        this.orderList = list;
      });
    },
    showDetail(item) {
      this.currentItem = item;
      this.getOrderList(item);
      this.isShowDetail = true;
    },

    onPageChange(page) {
      this.page.no = page;
      this.query();
    },
    onSizeChange(size) {
      this.page.size = size;
      this.query();
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item);
      } else {
        return item.get(col.prop);
      }
    },
    formatDate(d) {
      if (!d) {
        return "";
      }
      let fm = (n) => {
        return n < 10 ? "0" + n.toString() : n.toString();
      };
      let arr = [];
      arr.push(d.getFullYear());
      arr.push(fm(d.getMonth() + 1));
      arr.push(fm(d.getDate()));
      return arr.join("-");
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order };
      this.page.no = 1;
      this.query();
    },
    query() {
      this.isLoading = true;
      let query = new this.AV.Query("creditDetail");
      query[this.defaultSort.order](this.defaultSort.prop);
      query.include(["client", "operator", "cargoInfo"]);
      if (this.currentObj) {
        const innerQuery = new this.AV.Query('clientInfo');
        innerQuery.equalTo('name', this.currentObj.value)
        query.matchesQuery("client", innerQuery);
      }
      // let keys = Object.keys(this.params)
      // keys.forEach(key => {
      //   let val = this.params[key]
      //   if (val) {
      //     query.startsWith(key, val.toUpperCase())
      //   }
      // })

      query.limit(this.page.size);
      query.skip(this.page.size * (this.page.no - 1));

      query.find().then((list) => {
        this.dataList = list;
        this.isLoading = false;
      });
      query.count().then((count) => {
        this.page.total = count;
      });
    },

    showNew() {
      this.currentItem = {};
      this.form = {};
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.idInput.focus();
      });
    },
    showEdit(item) {
      this.currentItem = item;
      this.isDelivery = item.get("status") == "出库";
      this.columns.forEach((n) => {
        if (n.show.includes("form")) {
          this.$set(this.form, n.prop, item.get(n.prop));
        }
      });
      this.isShow = true;
    },

    checkName(name) {
      let query = new this.AV.Query("clientInfo");
      query.equalTo("name", name);
      return query.first().then((ret) => {
        this.$log({
          name: "clientInfo",
          url: "/Credit",
          action: "first",
          params: { name },
        });
        return ret ? true : false;
      });
    },

    onSave() {
      let postData = { ...this.form, status: "1" };

      this.checkName(postData.name).then((ret) => {
        if (ret) {
          this.$message.error("已存在该客户, 请修改重试");
          return;
        }
        // 修改已有客户
        if (this.currentItem.id) {
          this.currentItem.save(postData).then(() => {
            this.$message.success("修改成功");
            this.isShow = false;
            this.query();
          });
          return;
        }
        // 创建客户
        let obj = new this.clientInfo();
        obj.save(postData).then(() => {
          this.$message.success("添加成功");
          this.isShow = false;
          this.query();
        });
      });
    },
  },

  mounted() {
    this.expressInfo = this.AV.Object.extend("expressInfo");
    this.storeInfo = this.AV.Object.extend("storeInfo");
    this.clientInfo = this.AV.Object.extend("clientInfo");
    this.query();
  },
};
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
