import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'normalize.css'
import 'element-ui/lib/theme-chalk/index.css'

// import Vant from 'vant'
// import 'vant/lib/index.css'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import AutoQuery from './components/AutoQuery.vue'

import './lean'
import './mixin'
import './plugin'
import { createProvider } from './vue-apollo'

Vue.use(ElementUI)
// Vue.use(Vant)
Vue.use(mavonEditor)

Vue.component('AutoQuery', AutoQuery)

Vue.config.devtools = true
Vue.config.productionTip = false

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
