<template>
  <div class="ui-main">
    <div class="ui-title">物流信息</div>
    <el-row :gutter="10">
      <template v-for="item in configKeys">
        <el-col :span="6" :key="item.value">
          <div class="ui-item-hd">
            {{ item.label }}
            <el-button
              icon="el-icon-plus"
              circle
              size="mini"
              type="primary"
              @click="plus(item)"
            ></el-button>
          </div>
          <div class="ui-item-bd">
            <div class="item" v-for="(n, idx) in item.config" :key="idx">
              <el-row :gutter="10" type="flex">
                <el-col :span="20">
                  <el-input
                    v-model.trim="n.value"
                    :placeholder="n.label"
                    size="mini"
                  >
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-button
                    icon="el-icon-minus"
                    circle
                    size="mini"
                    type="danger"
                    @click="remove(item, idx)"
                  ></el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </template>
    </el-row>

    <div class="ui-item-ft">
      <el-button type="primary" size="mini" @click="onSaveInfo">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    configList() {
      this.init();
    },
  },
  computed: {
    configList() {
      return this.$store.state.config;
    },
  },
  data() {
    return {
      configKeys: [
        {
          label: "Place 地点",
          value: "expressPlace",
        },
        {
          label: "Detail 明细",
          value: "expressDetail",
        },
        {
          label: "Status 状态",
          value: "expressStatus",
        },
        {
          label: "Transport 物流方式 (-no表示客户不显示)",
          value: "mode",
        },
      ],
      config: [],
    };
  },

  methods: {
    remove(item, idx) {
      item.config.splice(idx, 1);
    },
    plus(item) {
      if (!item.config) {
        this.$set(item, "config", []);
      }
      item.config.push({
        value: "",
      });
    },
    onSaveInfo() {
      let objes = this.configKeys.map((item) => {
        let { value, config } = item;
        let target = this.configList.find((i) => i.get("key") === value);
        target.set({ value: JSON.stringify(config) });
        return target;
      });

      this.AV.Object.saveAll(objes).then(() => {
        this.$message.success("保存成功");
      });
    },
    getConfig() {
      let query = new this.AV.Query("config");
      return query.find().then((list) => {
        this.configList = list;
        return list;
      });
    },
    init() {
      this.configKeys.forEach((config) => {
        let content = JSON.parse(
          this.configList.find((c) => c.get("key") == config.value).get("value")
        );
        this.$set(config, "config", content);
      });
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
.ui {
  &-title {
    border-left: 3px solid #409eff;
    padding: 0 0 0 20px;
    margin: 0 0 20px;
  }
  &-item {
    margin: 0 0 40px;
    &-hd {
      margin: 0 0 20px;
      line-height: 30px;
    }
    &-bd {
      .item {
        margin: 0 0 10px;
      }
    }
  }
}
</style>
