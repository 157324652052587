<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline>
        <el-form-item>
          <el-input
            v-model="params.cargoNumber"
            placeholder="Shipping No"
            clearable
            @keyup.native.enter="query"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="params.mark"
            placeholder="Mark"
            clearable
            @keyup.native.enter="query"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <AutoQuery
            v-model="params.id"
            placeholder="Tracking Number"
            :params="{ className: 'expressInfo', field: 'id' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <AutoQuery
            v-model="params.client"
            placeholder="Customer"
            :params="{ className: 'clientInfo', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button
            type="primary"
            @click="showPreview"
            size="small"
            :disabled="selection.length == 0"
          >
            预览
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="showList"
        :height="tableHeight"
        @sort-change="onSortChange"
        @selection-change="onSelectionChange"
        v-loading="isLoading"
        size="mini"
      >
        <el-table-column type="selection"> </el-table-column>

        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <!-- 邀请码 -->
              <template v-else-if="col.prop == 'code'">
                <template v-if="formatData(row, col)">
                  {{ formatData(row, col) }}
                </template>
                <template v-else>
                  <el-button type="primary" size="mini" @click="getCode(row)"
                    >生成邀请码</el-button
                  >
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          v-if="actions.length"
          :width="actionsWidth + 'px'"
        >
          <template slot-scope="{ row }">
            <el-button
              :type="n.type"
              @click="n.click(row)"
              size="mini"
              v-for="(n, i) in actions"
              :key="i"
              :disabled="n.disabled ? n.disabled(row) : false"
            >
              {{ n.label }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="财务预览"
      :visible.sync="isShowPreview"
      fullscreen=""
      custom-class="ui-xs"
    >
      <hot-table
        :settings="previewOptions"
        ref="hotTable"
        v-if="isShowPreview"
      ></hot-table>
      <span slot="footer">
        <el-button type="primary" @click="exportFile">导出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'
import 'handsontable/languages/zh-CN.js'
import 'handsontable/dist/handsontable.full.css'

let winHeight = window.innerHeight - 120
export default {
  components: {
    HotTable,
  },
  data() {
    return {
      isShowPreview: false,
      selection: [],
      // productType: [],
      isShow: false,
      isShowDetail: false,
      isLoading: false,
      isShowCredit: false,
      currentUser: {},
      previewOptions: {
        data: [],
        fixedRowsTop: 1,
        colHeaders: true,
        rowHeaders: true,
        height: winHeight,
        rowHeights: 24,
        language: 'zh-CN',
        colWidths(index) {
          let len = 100
          if (index == 1 || index == 2) {
            len = 220
          }
          return len
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
      creditForm: { mode: 'increase', count: 0, remark: '' },
      form: {},
      page: { no: 1, size: 100, total: 0 },
      status: [
        { label: '入库', value: '1', type: 'success' },
        { label: '出库', value: '2', type: 'primary' },
        { label: '未确认', value: '3', type: 'danger' },
      ],
      dataList: [],
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'createdAt' },
      orderList: [],
      actions: [
        // {
        //   label: "修改",
        //   type: "primary",
        //   click: (item) => {
        //     this.showEdit(item);
        //   },
        // },
        // {
        //   label: "删除",
        //   type: "danger",
        //   click: (item) => {
        //     this.$confirm(`确定删除 ${item.get("name")}`, {
        //       type: "warning",
        //     }).then(() => {
        //       item.destroy().then(() => {
        //         this.$message.success("删除成功");
        //         this.query();
        //       });
        //     });
        //   },
        // },
      ],
      columns: [
        {
          label: 'deliveryDate',
          prop: 'deliveryDate',
          show: ['table', 'form', 'excel'],
          format: (item) => this.formatDate(item.deliveryDate),
        },
        {
          label: 'cargoNumber',
          prop: 'cargoNumber',
          show: ['table', 'form', 'excel'],
          width: 150,
        },
        {
          label: 'Marks',
          prop: 'mark',
          show: ['table', 'form', 'excel'],
        },
        {
          label: 'Tracking No',
          prop: 'id',
          show: ['table', 'form', 'excel'],
          width: 220,
        },
        {
          label: 'Customer',
          prop: 'client',
          width: 160,
          show: ['table', 'form', 'excel'],
        },
        {
          label: '名称',
          prop: 'name',
          show: ['table', 'form', 'excel'],
          width: 220,
        },
        {
          label: '英文名称',
          prop: 'ename',
          show: ['table', 'form', 'excel'],
          width: 220,
        },
        {
          label: '数量',
          prop: 'count',
          show: ['table', 'form', 'excel'],
          width: 220,
        },
        {
          label: '重量',
          prop: 'weight',
          show: ['table', 'form', 'excel'],
          width: 220,
        },
        {
          label: '是否牌子',
          prop: 'isBrand',
          show: ['table', 'form', 'excel'],
          width: 220,
          format: (item) => {
            return item.isBrand ? '是' : '不是'
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    productType() {
      return this.$store.state.productType
    },
    actionsWidth() {
      return this.actions.length * 80
    },
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170
      }
      return 0
    },
    currentName() {
      return this.currentItem.id ? this.currentItem.get('name') : ''
    },
    showList() {
      let list = []
      let props = [
        'deliveryDate',
        'cargoNumber',
        'mark',
        'id',
        'client',
        'weight',
      ]
      let keys = ['name', 'ename', 'count', 'isBrand', 'remark']
      this.dataList.forEach((item) => {
        let extraInfo = item.get('extraInfo')
        if (extraInfo && extraInfo.length) {
          extraInfo.forEach((ext) => {
            let newItem = {}
            props.forEach((key) => {
              newItem[key] = item.get(key)
            })
            keys.forEach((key) => {
              let value = ext[key]
              if (key === 'ename') {
                let ret = this.productType.find((n) => n.name == ext.name)
                value = ret ? ret.ename : value
              }
              newItem[key] = value
            })
            list.push(newItem)
          })
        } else {
          let newItem = {}
          props.forEach((key) => {
            newItem[key] = item.get(key)
          })
          list.push(newItem)
        }
      })
      return list
    },
  },

  methods: {
    exportFile() {
      let instance = this.$refs.hotTable.hotInstance
      let exp = instance.getPlugin('exportFile')
      exp.downloadFile('csv')
    },
    showPreview() {
      let data = []
      let cols = this.columns.filter((c) => c.show.includes('excel'))
      data = this.selection.map((n) => {
        return cols.map((c) => {
          let value = n[c.prop]
          if (c.format) {
            value = c.format(n)
          }
          return value
        })
      })
      // 插入表格头
      data.unshift(cols.map((c) => c.label))
      this.previewOptions.data = data
      this.previewOptions.cells = function (row, col) {
        if (row == 0) {
          this.renderer = function (instance, td, row, col, prop, value) {
            td.style.backgroundColor = '#409EFF'
            td.style.color = '#fff'
            td.innerHTML = value
          }
        } else if (col == 7) {
          this.renderer = function (instance, td, row, col, prop, value) {
            if (value) {
              if (value == 'NO') {
                td.style.backgroundColor = '#c00'
                td.style.color = '#fff'
              }
              td.innerHTML = value
            }
          }
        }
      }
      this.isShowPreview = true
    },
    onSelectionChange(selection) {
      this.selection = selection
    },
    onClosed() {
      this.currentItem = {}
      this.orderList = []
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item[col.prop]
      }
    },
    formatDate(d) {
      if (!d) {
        return ''
      }
      let fm = (n) => {
        return n < 10 ? '0' + n.toString() : n.toString()
      }
      let arr = []
      arr.push(d.getFullYear())
      arr.push(fm(d.getMonth() + 1))
      arr.push(fm(d.getDate()))
      return arr.join('-')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('expressInfo')
      query[this.defaultSort.order](this.defaultSort.prop)
      let keys = Object.keys(this.params)
      keys.forEach((key) => {
        let val = this.params[key]
        if (val) {
          query.startsWith(key, val.toUpperCase())
        }
      })

      query.equalTo('status', '出库')

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then((list) => {
        this.$log({
          name: 'expressInfo',
          url: '/Package',
          action: 'find',
          params: { status: '出库' },
        })
        this.dataList = list
        this.isLoading = false
      })
      query.count().then((count) => {
        this.page.total = count
      })
    },

    showNew() {
      this.currentItem = {}
      this.form = {}
      this.isShow = true
      this.$nextTick(() => {
        this.$refs.idInput.focus()
      })
    },
    showEdit(item) {
      this.currentItem = item
      this.isDelivery = item.status == '出库'
      this.columns.forEach((n) => {
        if (n.show.includes('form')) {
          this.$set(this.form, n.prop, item[n.prop])
        }
      })
      this.isShow = true
    },

    checkName(name) {
      let query = new this.AV.Query('productType')
      query.equalTo('name', name)
      return query.first().then((ret) => {
        return ret ? true : false
      })
    },

    onSave() {
      let postData = { ...this.form, status: '1' }

      // 修改已有客户
      if (this.currentItem.id) {
        this.currentItem.save(postData).then(() => {
          this.$message.success('修改成功')
          this.isShow = false
          this.query()
        })
        return
      }

      this.checkName(postData.name).then((ret) => {
        if (ret) {
          this.$message.error('已存在该品牌, 请修改重试')
          return
        }

        // 创建客户
        let obj = new this.productType()
        obj.save(postData).then(() => {
          this.$message.success('添加成功')
          this.isShow = false
          this.query()
        })
      })
    },
    getProductType() {
      this.$store.dispatch('getProductType').then((list) => {
        this.productType = list
      })
    },
  },

  mounted() {
    // this.getProductType();
    this.currentUser = this.AV.User.current()
    this.query()
  },
}
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
