<template>
  <div class="UI-EXPRESSDETAIL">
    <el-row>
      <el-col :span="4">
        <el-button type="primary" size="mini" @click="deliveryAll">
          批量出库
        </el-button>
      </el-col>
      <el-col :span="12">
        <el-tag type="danger" v-if="errMessage">{{ errMessage }}</el-tag>
      </el-col>
    </el-row>
    <el-table :data="detailList" @selection-change="onSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        :label="col.label"
        v-for="(col, idx) in detailColumns"
        :key="idx"
      >
        <template slot-scope="{ row }">{{
          col.format ? col.format(row) : row.get(col.prop)
        }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-popover
            placement="left"
            trigger="click"
            v-if="row.get('file')"
            popper-class="popper-class"
          >
            <img :src="row.url" slot="reference" class="ui-img-thumbnail" />
            <img :src="row.oUrl" class="ui-img-preview" />
          </el-popover>
          <el-button type="primary" @click="setDelivery(row)" size="mini">
            出库
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="批量设置出库" :visible.sync="isShowMulti" append-to-body>
      <el-form :model="multiForm" label-width="100px" ref="multiForm">
        <el-form-item
          label="出库单号"
          prop="cargoNumber"
          :rules="formRules.text"
        >
          <el-input
            v-model.trim="multiForm.cargoNumber"
            placeholder="请输入"
            clearable
          >
            <template slot="prepend"> ATM </template>
          </el-input>
        </el-form-item>
        <el-form-item label="麦头" prop="mark" :rules="formRules.text">
          <el-input
            v-model.trim="multiForm.mark"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input
            v-model="multiForm.remark"
            placeholder="请输入"
            type="textarea"
            :rows="5"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="isShowMulti = false">取 消</el-button>
        <el-button type="primary" @click="onSaveMulti">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    storeId: Object,
    len: Number,
    weight: Number,
    cbm: Number,
  },

  watch: {
    "multiForm.cargoNumber"(val) {
      localStorage.cargoNumber = val;
    },
    "multiForm.mark"(val) {
      localStorage.mark = val;
    },
  },

  data() {
    return {
      cargoLogistics: {},
      currentUser: {},
      cargoInfo: {},
      errMessage: "",
      detailList: [],
      selection: [],
      currentSelection: [],
      multiForm: {},
      isShowMulti: false,
      detailColumns: [
        {
          label: "Tracking No",
          prop: "id",
        },
        {
          label: "Customer",
          prop: "client",
          format: (item) => {
            let clientId = item.get("clientId");
            return clientId ? clientId.get("name") : "";
          },
        },
        {
          label: "Supplier",
          prop: "company",
        },
        {
          label: "Shelves",
          prop: "storeName",
        },
        {
          label: "Transport",
          prop: "mode",
        },
        {
          label: "Weight",
          prop: "weight",
        },
        {
          label: "CBM",
          prop: "cbm",
        },
        {
          label: "In Date",
          prop: "createdDate",
          format: (value) => {
            return dayjs(value.get("createdDate")).format("YYYY-MM-DD");
          },
        },
        {
          label: "Status",
          prop: "status",
          format: (item) => {
            let maps = [
              {
                value: "入库",
                label: "In",
              },
              {
                value: "出库",
                label: "Out",
              },
              {
                value: "未确认",
                label: "Unknow",
              },
            ];
            return maps.find((n) => n.value == item.get("status")).label;
          },
        },
      ],
    };
  },

  methods: {
    // 批量出库弹窗
    deliveryAll() {
      if (this.selection.length) {
        this.currentSelection = this.selection;
        this.isShowMulti = true;
        return;
      }
      this.$message.error("请选择订单");
    },
    onSelectionChange(selection) {
      this.selection = selection;
    },
    getCargoInfo(params) {
      let query = new this.AV.Query("cargoInfo");
      query.equalTo("cargoNumber", params.cargoNumber);
      query.equalTo("mark", params.mark);
      query.equalTo("clientId", params.clientId);
      return query.first().then((ret) => {
        return ret;
      });
    },

    // 批量出库
    multiOut() {
      if (this.multiForm.cargoNumber && this.multiForm.mark) {
        if (this.currentSelection && this.currentSelection.length) {
          // 客户
          let clientId = {};
          let clientName = "";
          let ids = [];
          let storeName = "";
          this.currentSelection.forEach((n) => {
            if (!clientName) {
              clientId = n.get("clientId");
              clientName = n.get("client");
              storeName = n.get("storeName");
            }
            if (n.get("status") != "出库") {
              n.get("storeId").increment("count", -1);
              n.set("status", "出库");
              n.set("deliveryDate", new Date());
              n.set("cargoNumber", "ATM" + this.multiForm.cargoNumber);
              n.set("mark", this.multiForm.mark);
              ids.push(n.id);
            }
          });
          // 订单保存出库信息
          return this.AV.Object.saveAll(this.currentSelection).then(
            () => {
              this.$message.success("保存成功");
              this.isShowMulti = false;

              // 查询出库信息,判断是否已存在
              this.getCargoInfo({
                cargoNumber: "ATM" + this.multiForm.cargoNumber,
                mark: this.multiForm.mark,
                clientId,
              }).then((obj) => {
                // 已存在
                if (obj) {
                  ids.forEach((id) => {
                    obj.addUnique("expressInfo", id);
                  });
                  obj.save();
                } else {
                  // 默认物流信息
                  let expressUpdate = {
                    expressTime: new Date(),
                    expressPlace: "GZ China",
                    expressDetail: "Finish packing ,Sending to HK",
                    expressStatus: "In Transit",
                  };

                  // 新建出库单
                  let newObj = new this.cargoInfo();
                  let postData = {
                    cargoNumber: "ATM" + this.multiForm.cargoNumber,
                    mark: this.multiForm.mark,
                    remark: this.multiForm.remark,
                    clientId,
                    clientName,
                    storeName,
                    expressInfo: ids,
                    operator: this.currentUser,
                    username: this.currentUser.get("username"),
                    ...expressUpdate, // 保存最新物流信息
                  };
                  newObj.save(postData);
                  this.$mongo("cargoInfo", postData);
                }
              });
            },
            (err) => {
              this.$message.error(err);
            }
          );
        }
      } else {
        this.isShowMulti = true;
        return Promise.resolve("");
      }
    },
    onSaveMulti() {
      this.$refs.multiForm.validate().then((valid) => {
        if (valid) {
          this.multiOut().then(() => {
            this.$emit("afterOut");
          });
        }
      });
    },
    setDelivery(item) {
      this.currentSelection = [item];
      this.isShowMulti = true;
    },
    getData() {
      let query = new this.AV.Query("expressInfo");
      query.equalTo("storeId", this.storeId);
      query.notEqualTo("status", "出库");
      query.include("storeId");
      query.include("clientId");
      query.limit(1000);
      return query.find().then((data) => {
        this.$log({
          name: "expressInfo",
          url: "/expressDetail",
          action: "find",
          params: { storeId: this.storeId },
        });
        this.detailList = data.map((n) => {
          if (n.get("file")) {
            n.url = n.get("file").thumbnailURL(100, 100);
            n.oUrl = n.get("file").url();
          }
          return n;
        });
        let weight = data.reduce((total, item) => {
          let w = item.get("weight");
          if (w) {
            w = Number(w);
            return total + w;
          }
          return total;
        }, 0);
        let cbm = data.reduce((total, item) => {
          let w = item.get("cbm");
          if (w) {
            w = Number(w);
            return total + w;
          }
          return total;
        }, 0);
        this.$emit("update:len", data.length);
        this.$emit("update:weight", weight.toFixed(2));
        this.$emit("update:cbm", cbm.toFixed(2));
      });
    },
  },

  mounted() {
    this.currentUser = this.AV.User.current();
    this.cargoInfo = this.AV.Object.extend("cargoInfo");
    this.cargoLogistics = this.AV.Object.extend("cargoLogistics");
    this.getData();

    this.multiForm = {
      cargoNumber: localStorage.cargoNumber || "",
      mark: localStorage.mark || "",
    };
  },
};
</script>

