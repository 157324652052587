<template>
  <div class="ui-main">
    <div class="ui-title">客户地址</div>
    <div class="ui-item">
      <div class="ui-item-bd">
        <div class="item" v-for="(n, idx) in config" :key="idx">
          <el-row :gutter="10" type="flex">
            <el-col :span="20">
              <el-input v-model="n.value" size="small"> </el-input>
            </el-col>
            <el-col :offset="0">
              <el-button
                v-if="idx === 0"
                icon="el-icon-plus"
                circle
                size="small"
                type="primary"
                @click="plus"
              ></el-button>
              <el-button
                v-if="idx !== 0"
                icon="el-icon-minus"
                circle
                size="small"
                type="danger"
                @click="remove(idx)"
              ></el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="ui-item-ft">
        <el-button type="primary" size="mini" @click="onSaveInfo">
          保存
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configList: [],
      countInfoObj: {},
      countInfo: [],
      config: [{ value: '' }],
      objName: 'address',
    }
  },

  methods: {
    plus() {
      this.config.push({ value: '' })
    },
    remove(idx) {
      this.config.splice(idx, 1)
    },
    onSaveInfo() {
      this.configList
        .find((item) => item.get('key') == this.objName)
        .set({
          value: JSON.stringify(this.config),
        })
        .save()
        .then(() => {
          this.$message.success('保存成功')
        })
    },
    getConfig() {
      let query = new this.AV.Query('config')
      return query.find().then((list) => {
        this.configList = list
        return list
      })
    },
  },

  mounted() {
    this.getConfig().then((list) => {
      this.config = JSON.parse(
        list.find((c) => c.get('key') == this.objName).get('value')
      )
    })
  },
}
</script>

<style lang="less">
.ui {
  &-title {
    border-left: 3px solid #409eff;
    padding: 0 0 0 20px;
    margin: 0 0 20px;
  }
  &-item {
    margin: 0 0 40px;
    &-hd {
      margin: 0 0 20px;
    }
    &-bd {
      .item {
        margin: 0 0 10px;
      }
    }
  }
}
</style>
