'use strict'

import Vue from 'vue'
import axios from 'axios'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const baseURL = window.location.host.match('localhost')
  ? 'http://127.0.0.1:3000'
  : ''

const config = {
  baseURL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response.data
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error)
  }
)

Vue.use({
  install(Vue) {
    Vue.axios = _axios
    window.axios = _axios
    Vue.rget = function() {
      return Promise.resolve(null)
      // return _axios.post('/redis/get', {
      //   key,
      // })
    }
    Vue.rset = function() {
      return Promise.resolve({})
      // return _axios.post('/redis/set', {
      //   key,
      //   value,
      //   expire,
      // })
    }
    Vue.prototype.$log = function(params) {
      return _axios.post('/mongo/save', {
        collectionName: 'log',
        data: [params],
      })
    }
    Vue.prototype.$mongo = function(collectionName, params) {
      return _axios.post('/mongo/save', {
        collectionName,
        data: [params],
      })
    }
    Vue.prototype.$rget = function() {
      return Promise.resolve(null)
      // return _axios.post('/redis/get', {
      //   key,
      // })
    }
    Vue.prototype.$rset = function() {
      return Promise.resolve(null)
      // return _axios.post('/redis/set', {
      //   key,
      //   value,
      //   expire,
      // })
    }
    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return _axios
        },
      },
      $axios: {
        get() {
          return _axios
        },
      },
      $http: {
        get() {
          return _axios
        },
      },
    })
  },
})
