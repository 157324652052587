<template>
  <div class="ui-main">
    <div class="ui-title">
      积分设置
    </div>
    <div class="ui-item">
      <div class="ui-item-hd text-right">
        <el-button type="primary" size="mini" @click="showNew">新增</el-button>
      </div>
      <div class="ui-item-bd">
        <el-table :data="dataList">
          <el-table-column :label="n.label" v-for="(n, i) in colums" :key="i">
            <template slot-scope="{ row }">
              {{ row.get(n.prop) }}
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="showEdit(row)">
                修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog title="积分设置" :visible.sync="isShow">
      <el-form :model="form" label-width="80px">
        <el-form-item :label="n.label" v-for="(n, i) in colums" :key="i">
          <el-input v-model="form[n.prop]" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSave">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      creditList: {},
      isShow: false,
      form: {},
      dataList: [],
      colums: [
        { label: '等级', prop: 'level', type: 'number' },
        { label: '积分', prop: 'credit', type: 'number' },
        { label: '兑换率', prop: 'scale', type: 'number' },
        { label: '描述', prop: 'desc' }
      ]
    }
  },

  methods: {
    showEdit(item) {
      this.form = item
      this.colums.forEach(n => {
        this.$set(this.form, n.prop, item.get(n.prop))
      })
      this.isShow = true
    },
    onSave() {
      if (this.form.id) {
        let form = {}
        this.colums.forEach(n => {
          let val = this.form[n.prop]
          if (n.type == 'number') {
            form[n.prop] = Number(val)
          }
        })
        this.form.save(form).then(() => {
          this.isShow = false
          this.getData()
        })
        return
      }
      let obj = new this.creditList()
      this.colums.forEach(n => {
        let val = this.form[n.prop]
        if (n.type == 'number') {
          this.form[n.prop] = Number(val)
        }
      })
      obj.save(this.form).then(() => {
        this.isShow = false
        this.getData()
      })
    },
    showNew() {
      this.form = {}
      this.isShow = true
    },
    getData() {
      let query = new this.AV.Query('creditList')
      query.ascending('level')
      query.find().then(list => {
        this.dataList = list
      })
    }
  },

  mounted() {
    this.creditList = this.AV.Object.extend('creditList')
    this.getData()
  }
}
</script>

<style lang="less">
.ui {
  &-title {
    border-left: 3px solid #409eff;
    padding: 0 0 0 20px;
    margin: 0 0 20px;
  }
  &-item {
    margin: 0 0 40px;
    &-hd {
      margin: 0 0 20px;
    }
    &-bd {
      .item {
        margin: 0 0 10px;
      }
    }
  }
}
.text-right {
  text-align: right;
}
</style>
