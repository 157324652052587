<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline>
        <el-form-item>
          <el-input
            v-model="params.userId"
            placeholder="Client ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="params.mobileNumber"
            placeholder="Mobile"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <AutoQuery
            v-model="params.name"
            placeholder="Customer"
            :params="{ className: 'clientInfo', field: 'name' }"
            @enter="query"
          ></AutoQuery>
        </el-form-item>
        <el-form-item>
          <el-autocomplete
            style="width: 100%"
            v-model="params.address"
            :fetch-suggestions="querySearch"
            placeholder="Client Address"
            clearable
          ></el-autocomplete>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button type="success" @click="showNew" size="small">
            新增
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        v-loading="isLoading"
      >
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <!-- 邀请码 -->
              <template v-else-if="col.prop == 'code'">
                <template v-if="formatData(row, col)">
                  {{ formatData(row, col) }}
                </template>
                <template v-else>
                  <el-button type="primary" size="mini" @click="getCode(row)">
                    生成邀请码
                  </el-button>
                </template>
              </template>
              <template v-else>{{ formatData(row, col) }}</template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          :width="actionsWidth + 'px'"
        >
          <template slot-scope="{ row }">
            <template v-for="(n, i) in actions">
              <el-button
                v-if="n.show ? n.show(row) : true"
                :type="n.type"
                @click="n.click(row)"
                size="mini"
                :key="i"
                :disabled="n.disabled ? n.disabled(row) : false"
              >
                {{ n.setLabel ? n.setLabel(row) : n.label }}
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      title="客户信息"
      :visible.sync="isShow"
      width="50%"
      @closed="onClosed"
    >
      <el-form :model="form" ref="form" label-width="120px" class="ui-form">
        <el-form-item label="客户名">
          <el-input
            v-model="form.name"
            placeholder="请输入"
            ref="idInput"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input
            v-model="form.mobileNumber"
            placeholder="请输入"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户地址">
          <el-autocomplete
            style="width: 100%"
            v-model="form.address"
            :fetch-suggestions="querySearch"
            placeholder="请输入"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="关联客服">
          <el-autocomplete
            style="width: 100%"
            v-model="form.support"
            :fetch-suggestions="supportSearch"
            placeholder="请输入"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="客户身份证信息">
          <el-input
            v-model="form.passport"
            placeholder="请输入"
            @keyup.native.enter="onSave"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="currentName + ' - 关联订单明细'"
      :visible.sync="isShowDetail"
      width="80%"
      @closed="onClosed"
    >
      <el-table :data="orderList">
        <el-table-column label="入库日期">
          <template slot-scope="{ row }">{{
            formatDate(row.get('createdDate'))
          }}</template>
        </el-table-column>
        <el-table-column label="出库日期">
          <template slot-scope="{ row }">{{
            formatDate(row.get('deliveryDate'))
          }}</template>
        </el-table-column>
        <el-table-column label="订单号">
          <template slot-scope="{ row }">{{ row.get('id') }}</template>
        </el-table-column>
        <el-table-column label="储位号">
          <template slot-scope="{ row }">{{ row.get('storeName') }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{ row }">{{ row.get('status') }}</template>
        </el-table-column>
        <el-table-column label="供应商">
          <template slot-scope="{ row }">{{ row.get('company') }}</template>
        </el-table-column>
        <el-table-column label="仓库">
          <template slot-scope="{ row }">{{ row.get('address') }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="积分操作" :visible.sync="isShowCredit">
      <el-form
        :model="creditForm"
        label-width="100px"
        label-position="left"
        v-if="currentItem.id"
      >
        <el-form-item label="当前用户">
          {{ currentName }}
        </el-form-item>
        <el-form-item label="当前积分">
          {{ currentItem.get('credit') }}
        </el-form-item>
        <el-form-item label="积分类型">
          <el-radio label="increase" v-model="creditForm.mode">
            奖励积分
          </el-radio>
          <el-radio label="reduce" v-model="creditForm.mode">扣除积分</el-radio>
        </el-form-item>
        <el-form-item label="积分数值">
          <el-input-number
            controls-position="right"
            v-model="creditForm.count"
            :mini="0"
            step-strictly
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="creditForm.remark"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="" @click="isShowCredit = false">取消</el-button>
        <el-button type="primary" @click="onSaveCredit">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="开通账号" :visible.sync="isShowReg" width="50%">
      <div>
        <el-form
          :model="regForm"
          ref="form"
          label-width="170px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="客户（Name）">
            {{ regForm.name }}
          </el-form-item>
          <el-form-item label="账号（ID）">
            <el-input v-model="regForm.username"></el-input>
          </el-form-item>
          <el-form-item label="初始密码（Password）">
            <el-input v-model="regForm.password" disabled=""></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="isShowReg = false">关闭</el-button>
        <el-button
          type="primary"
          @click="onSaveReg"
          v-if="!(currentItem.get && currentItem.get('password'))"
        >
          点击创建
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: {
        superadmin: '超级管理员',
        admin: '管理员',
        staff: '员工',
        finance: '财务',
        agent: '代理商',
        supplier: '供应商',
      },
      isShow: false,
      isShowDetail: false,
      isLoading: false,
      isShowCredit: false,
      isShowReg: false,
      regForm: {
        name: '',
        username: '',
        password: '',
      },
      creditDetail: {},
      currentUser: {},
      creditForm: { mode: 'increase', count: 0, remark: '' },
      form: {},
      page: { no: 1, size: 100, total: 0 },
      password: 'ATM123456',
      status: [
        { label: '入库', value: '1', type: 'success' },
        { label: '出库', value: '2', type: 'primary' },
        { label: '未确认', value: '3', type: 'danger' },
      ],
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      clientInfo: {},
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'createdAt' },
      orderList: [],
      addressList: [],
      accountList: [],
      actions: [
        {
          label: '开通账号',
          type: 'warning',
          show: item => {
            return !(item.get('username') && item.get('password'))
          },
          click: item => {
            this.showReg(item)
          },
        },
        {
          label: '查看账号',
          type: 'success',
          show: item => {
            return item.get('username') && item.get('password')
          },
          click: item => {
            this.showReg(item)
          },
        },
        {
          label: '积分',
          click: item => {
            this.showCredit(item)
          },
          disabled: item => {
            return item.get('startDate') ? false : true
          },
        },
        {
          label: '修改',
          type: 'primary',
          click: item => {
            this.showEdit(item)
          },
        },
        {
          label: '明细',
          type: 'info',
          click: item => {
            this.showDetail(item)
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: item => {
            this.$prompt('请输入管理密码').then(({ value }) => {
              if (value === this.password) {
                this.$confirm(`确定删除 ${item.get('name')}`, {
                  type: 'warning',
                }).then(() => {
                  item.destroy().then(() => {
                    this.$message.success('删除成功')
                    this.query()
                  })
                })
              } else {
                this.$message.error('密码错误')
              }
            })
          },
        },
        {
          label: '复制地址',
          type: 'success',
          click: async item => {
            const text = `ATMCARGO (CHINA)Warehouse Address

地址Address：广州市白云区龙归夏良东路26号之二ATM CARGO仓-${item.get('userId')}

客户名：${item.get('name')}

入仓号(shipping mark)：${item.get('userId')}

电话(Tel）：13512709725/020-86606683

仓库上班时间：周一至周日 9:00-20:00

请务必在外箱上写上入仓号，.##中文装箱清单(很重要)##请随货到仓库,请供应商按照上面的入仓号跟收件人贴唛，以免货物丢失，谢谢合作 (由于快递为保护隐私会屏蔽收件人姓名，请在快递面单或者货物外箱上备注入仓号，谢谢)`
            await navigator.clipboard.writeText(text)
            this.$message.success('复制成功!')
          },
        },
      ],
      columns: [
        {
          label: '客户ID',
          prop: 'userId',
          show: ['table'],
          width: 120,
        },
        { label: '客户名', prop: 'name', show: ['table', 'form'], width: 220 },
        {
          label: '手机号码',
          prop: 'mobileNumber',
          show: ['table', 'form'],
          width: 160,
        },
        {
          label: '详细地址',
          prop: 'address',
          show: ['table', 'form'],
          width: 220,
        },
        {
          label: '关联客服',
          prop: 'support',
          show: ['table', 'form'],
          width: 220,
        },
        {
          label: '身份证信息',
          prop: 'passport',
          show: ['table', 'form'],
          width: 220,
        },
        {
          label: '账号名',
          prop: 'username',
          show: ['table', 'form'],
          width: 220,
          format: item => {
            const user = item.get('user')
            return user ? user.get('username') : ''
          },
        },
        { label: '邀请码', prop: 'code', show: ['table'] },
        {
          label: '微信号',
          prop: 'userInfo',
          show: ['table'],
          format: item => {
            let userInfo = item.get('userInfo')
            return userInfo ? userInfo.nickName : ''
          },
        },
        { label: '备注', prop: 'remark', show: ['table'] },
        { label: '当前积分', prop: 'credit', show: ['table'] },
        { label: '累积积分', prop: 'totalCredit', show: ['table'] },
        { label: '兑换积分', prop: 'costCredit', show: ['table'] },
        {
          label: '升级vip时间',
          prop: 'startDate',
          show: ['table'],
          format: item => {
            return this.formatDate(item.get('startDate'))
          },
        },
        {
          label: '创建时间',
          prop: 'createdAt',
          show: ['table'],
          format: item => {
            return this.formatDate(item.createdAt)
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    actionsWidth() {
      return this.actions.length * 55
    },
    tableHeight() {
      return window.innerHeight - 170
    },
    currentName() {
      return this.currentItem.id ? this.currentItem.get('name') : ''
    },
  },

  watch: {
    'form.name'(val) {
      if (val && !this.isUpper(val)) {
        this.form.name = val.toUpperCase()
      }
    },
  },

  methods: {
    /**
     * 为客户注册账号
     */
    async onSaveReg() {
      const { username, password } = this.regForm

      /**
       * 针对已有账号的vip
       * 修改账号名, 创建密码
       */
      // const hasUser = this.currentItem.get('user')
      // const user = this.currentItem.get('user') || new this.AV.User()
      // user.setUsername(username)
      // user.setPassword(password)
      // user.set('roles', ['client'])
      // user.set('role', 'client')
      const postData = {
        userInfo: {
          username,
          password,
        },
      }
      if (this.currentItem.get('user')) {
        postData.objectId = this.currentItem.get('user').id
      }
      try {
        const loggedInUser = await this.AV.Cloud.run('createUser', postData)
        // 注册完成,关联关系
        const user = this.AV.Object.createWithoutData(
          '_User',
          loggedInUser.objectId
        )
        await this.currentItem.save({
          user,
          username,
          password,
        })
        // 注册完成,重新以管理员登录
        // await this.AV.User.logOut()
        // await this.AV.User.logIn(localStorage.username, localStorage.password)
        this.$message.success('开通成功!')
        this.isShowReg = false
        this.query()
      } catch (error) {
        const message = error.code === 202 ? '账号名已被使用' : '提交信息有误'
        this.$message.error(message)
      }
    },
    // 打开开通账号弹窗
    showReg(item) {
      this.currentItem = item
      this.regForm.name = item.get('name')
      // 已开通账号
      if (item.get('username') && item.get('password')) {
        this.regForm.username = item.get('username')
        this.regForm.password = item.get('password')
      } else {
        // 未开通账号
        this.regForm.username = item.get('userId')
        this.regForm.password = this.$generateRandomNumber()
      }
      this.isShowReg = true
    },
    querySearch(queryString, cb) {
      cb(this.addressList)
    },
    supportSearch(str, cb) {
      const list = str
        ? this.accountList.filter(n => n.value.match(str))
        : this.accountList
      cb(list)
    },
    async getAddress() {
      let query = new this.AV.Query('config')
      query.equalTo('key', 'address')
      let ret = await query.first()
      this.addressList = JSON.parse(ret.get('value'))
    },
    async getAccount() {
      let query = new this.AV.Query('profile')
      query.equalTo('roles', ['Admin'])
      query.include('User')
      query.limit(1000)
      const list = await query.find()
      console.log('🚀 ~ getAccount ~ list:', list)
      this.accountList = list.map(n => {
        return {
          value: `${n.get('username')}（${n.get('nickname')}）`,
        }
      })
    },
    onSaveCredit() {
      let { creditForm, currentItem } = this
      let creditChange = 0
      let type = ''
      let creditLeft = currentItem.get('credit')
      if (creditForm.mode == 'increase') {
        currentItem.increment('credit', creditForm.count)
        creditChange = creditForm.count
        creditLeft += creditForm.count
        type = 'reward'
      } else {
        currentItem.increment('credit', creditForm.count * -1)
        creditChange = creditForm.count * -1
        creditLeft -= creditForm.count
        type = 'reduce'
      }
      let creditDetail = new this.creditDetail()
      creditDetail
        .save({
          credit: creditChange,
          client: currentItem,
          operator: this.currentUser,
          remark: this.creditForm.remark,
          creditLeft,
          type,
        })
        .then(() => {
          this.$message.success('积分操作成功')
          this.isShowCredit = false
        })
    },
    clearAll() {
      let query = new this.AV.Query('clientInfo')
      query.greaterThan('totalCredit', 0)
      query.limit(500)
      query.descending('totalCredit')
      query.find().then(list => {
        this.$log({
          name: 'clientInfo',
          url: '/Client',
          action: 'find',
          params: {},
        })
        let idx = 0
        let loop = () => {
          let n = list[idx]
          if (!n) return
          n.set('totalCredit', 0)
          n.set('level', 0)
          n.set('costCredit', 0)
          n.set('credit', 0)
          n.save().then(() => {
            idx++
            loop()
          })
        }
        loop()
      })
    },
    showCredit(item) {
      this.currentItem = item
      this.isShowCredit = true
    },
    getCode(item) {
      let code = Math.random().toString(36).substr(2)
      item.save({ code }).then(() => {})
    },
    onClosed() {
      this.currentItem = {}
      this.orderList = []
    },
    getOrderList(item) {
      let query = new this.AV.Query('expressInfo')
      query.equalTo('clientId', item)
      query.find().then(list => {
        this.$log({
          name: 'expressInfo',
          url: '/Client',
          action: 'find',
          params: { clientId: item },
        })
        this.orderList = list
      })
    },
    showDetail(item) {
      this.currentItem = item
      this.getOrderList(item)
      this.isShowDetail = true
    },

    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop)
      }
    },
    formatDate(d) {
      if (!d) {
        return ''
      }
      let fm = n => {
        return n < 10 ? '0' + n.toString() : n.toString()
      }
      let arr = []
      arr.push(d.getFullYear())
      arr.push(fm(d.getMonth() + 1))
      arr.push(fm(d.getDate()))
      return arr.join('-')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('clientInfo')
      query[this.defaultSort.order](this.defaultSort.prop)
      let keys = Object.keys(this.params)
      keys.forEach(key => {
        let val = this.params[key]
        if (val) {
          if (key === 'mobileNumber') {
            query.contains('mobileNumber', val)
          } else {
            query.startsWith(key, key === 'name' ? val.toUpperCase() : val)
          }
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.include('user')

      query.find().then(list => {
        this.$log({
          name: 'clientInfo',
          url: '/Client',
          action: 'find',
          params: this.params,
        })
        this.dataList = list
        this.isLoading = false
        this.setAllUserId()
      })
      query.count().then(count => {
        this.page.total = count
      })
    },

    // 批量设置客户id
    async setAllUserId() {
      let list = this.dataList.filter(item => !item.get('userId'))
      if (list.length) {
        list.forEach(item => {
          let id = item.id.slice(-5).toUpperCase()
          item.set('userId', id)
        })
        let ret = await this.AV.Object.saveAll(list)
        console.log('ret', ret)
      }
    },

    showNew() {
      this.currentItem = {}
      this.form = {}
      this.isShow = true
      this.$nextTick(() => {
        this.$refs.idInput.focus()
      })
    },
    showEdit(item) {
      this.currentItem = item
      this.isDelivery = item.get('status') == '出库'
      this.columns.forEach(n => {
        if (n.show.includes('form')) {
          this.$set(this.form, n.prop, item.get(n.prop))
        }
      })
      this.isShow = true
    },

    checkName(name) {
      let query = new this.AV.Query('clientInfo')
      query.equalTo('name', name)
      return query.first().then(ret => {
        this.$log({
          name: 'clientInfo',
          url: '/Client',
          action: 'first',
          params: { name },
        })
        return ret ? true : false
      })
    },

    async onSave() {
      let postData = { ...this.form, status: '1' }

      // 修改已有客户
      if (this.currentItem.id) {
        this.currentItem.save(postData).then(() => {
          this.$message.success('修改成功')
          this.isShow = false
          this.query()
        })
        return
      }

      let ret = await this.checkName(postData.name)

      if (ret) {
        this.$message.error('已存在该客户, 请修改重试')
        return
      }

      // 随机字符串创建账号
      // const user = new AV.User()
      // const userName = this.$generateRandomString()
      // console.log('🚀 ~ file: Client.vue:611 ~ onSave ~ userName:', userName)
      // const userPassword = this.$generateRandomNumber()
      // console.log(
      //   '🚀 ~ file: Client.vue:613 ~ onSave ~ userPassword:',
      //   userPassword
      // )
      // user.setUsername('Tom')
      // user.setPassword('cat!@#123')
      // 保存随机密码
      // 修改账号名为id后5位

      // 创建客户
      let obj = new this.clientInfo()
      let retObj = await obj.save(postData)
      let userId = retObj.id.slice(-5).toUpperCase()
      await retObj.save({ userId })
      this.$message.success('添加成功')
      this.isShow = false
      this.query()

      this.$mongo('clientInfo', postData)
    },
  },

  mounted() {
    this.expressInfo = this.AV.Object.extend('expressInfo')
    this.storeInfo = this.AV.Object.extend('storeInfo')
    this.clientInfo = this.AV.Object.extend('clientInfo')
    this.creditDetail = this.AV.Object.extend('creditDetail')
    this.currentUser = this.AV.User.current()
    this.query()
    this.getAddress()
    this.getAccount()
  },
}
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
