<template>
  <div class="ui-main">
    <div class="ui-title">
      分类预设
    </div>
    <div class="ui-item">
      <!-- <div class="ui-item-hd">
        <el-button type="primary" size="mini">添加</el-button>
      </div> -->
      <div class="ui-item-bd">
        <div class="item" v-for="(n, idx) in countInfo" :key="idx">
          <el-row :gutter="10" type="flex">
            <ElCol :span="6">
              <el-input v-model="n.label" placeholder="类别" size="mini">
                <span slot="prepend">类别</span>
              </el-input>
            </ElCol>
            <ElCol :span="6">
              <el-input v-model="n.price" placeholder="单价" size="mini">
                <span slot="prepend">单价</span>
              </el-input>
            </ElCol>
            <!-- <ElCol :span="6">
              <el-button type="danger" size="mini">删除</el-button>
            </ElCol> -->
          </el-row>
        </div>
      </div>
      <div class="ui-item-ft">
        <el-button type="primary" size="mini" @click="onSaveCountInfo"
          >保存</el-button
        >
      </div>
    </div>

    <div class="ui-title">
      单价预设
    </div>
    <div class="ui-item">
      <!-- <div class="ui-item-hd">
        <el-button type="primary" size="mini">添加</el-button>
      </div> -->
      <div class="ui-item-bd">
        <div class="item" v-for="(n, idx) in config" :key="idx">
          <el-row :gutter="10" type="flex">
            <ElCol :span="6">
              <el-input v-model="n.value" :placeholder="n.label" size="mini">
                <span slot="prepend">{{ n.label }}</span>
              </el-input>
            </ElCol>
          </el-row>
        </div>
      </div>
      <div class="ui-item-ft">
        <el-button type="primary" size="mini" @click="onSaveInfo"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      configList: [],
      countInfoObj: {},
      countInfo: [
        // { label: '手机', prop: 'phone', count: 0, price: 80 },
        // { label: '小手机', prop: 'smallPhone', count: 0, price: 30 },
        // { label: '笔记本', prop: 'laptop', count: 0, price: 200 },
        // { label: 'iPad', prop: 'ipad', count: 0, price: 120 },
        // { label: '相机', prop: 'camera', count: 0, price: 200 },
        // { label: '其他', prop: 'other', count: 0, price: 0 }
      ],
      config: [
        // {
        //   label: '汇率',
        //   key: 'exchangeRate',
        //   value: '6.6'
        // },
        // {
        //   label: '空运单价',
        //   key: 'airPrice',
        //   value: '42'
        // },
        // {
        //   label: '手续费',
        //   key: 'charge',
        //   value: '0.92'
        // }
      ]
    }
  },

  methods: {
    onSaveInfo() {
      let list = this.config.map(c => {
        return this.configList
          .find(item => item.get('key') == c.key)
          .set({
            value: c.value
          })
      })
      this.AV.Object.saveAll(list).then(() => {
        this.$message.success('保存成功')
      })
    },
    onSaveCountInfo() {
      this.countInfoObj
        .save({
          key: 'countInfo',
          value: JSON.stringify(this.countInfo)
        })
        .then(() => {
          this.$message.success('保存成功')
        })
    },
    getConfig() {
      let query = new this.AV.Query('config')
      return query.find().then(list => {
        this.configList = list
        return list
      })
    }
  },

  mounted() {
    this.getConfig().then(list => {
      this.countInfoObj = list.find(c => c.get('key') == 'countInfo')
      this.countInfo = JSON.parse(this.countInfoObj.get('value'))
      this.config = JSON.parse(
        list.find(c => c.get('key') == 'otherInfo').get('value')
      )
    })
  }
}
</script>

<style lang="less">
.ui {
  &-title {
    border-left: 3px solid #409eff;
    padding: 0 0 0 20px;
    margin: 0 0 20px;
  }
  &-item {
    margin: 0 0 40px;
    &-hd {
      margin: 0 0 20px;
    }
    &-bd {
      .item {
        margin: 0 0 10px;
      }
    }
  }
}
</style>
