import Vue from 'vue'
const dayjs = require('dayjs')

Vue.use({
  install: function (Vue) {
    Vue.prototype.$dayjs = dayjs

    Vue.prototype.$generateRandomString = (len = 12) => {
      const chars = 'abcdefghijklmnopqrstuvwxyz'
      let result = ''
      for (let i = 0; i < len; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length)
        result += chars.charAt(randomIndex)
      }
      return result
    }

    Vue.prototype.$generateRandomNumber = () => {
      return (Math.floor(Math.random() * 90000) + 10000).toString()
    }
  },
})
