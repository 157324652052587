<template>
  <div class="ui-main">
    <div class="ui-query">
      <el-form :model="params" size="small" inline="">
        <el-form-item>
          <el-input
            v-model="params.username"
            placeholder="Name"
            clearable
            @keyup.native.enter="query"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query" size="small">搜索</el-button>
          <el-button type="success" @click="showNew" size="small">
            新增
          </el-button>
          <!-- <el-button type="success" @click="create" size="small">
            创建
          </el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <div class="ui-table">
      <el-table
        :data="dataList"
        :height="tableHeight"
        @sort-change="onSortChange"
        v-loading="isLoading"
      >
        <template v-for="col in columns">
          <el-table-column
            sortable="custom"
            :prop="col.prop"
            :key="col.id"
            :label="col.label"
            :min-width="col.width || 120"
            v-if="col.show.includes('table')"
          >
            <template slot-scope="{ row }">
              <template v-if="col.props">
                <template v-for="(p, idx) in col.props">
                  <p v-if="row.get(p)" :key="idx">
                    <el-tag
                      :type="idx == 1 ? 'primary' : 'success'"
                      size="mini"
                    >
                      {{ col.labels[idx] }}
                    </el-tag>
                    {{ formatDate(row.get(p)) }}
                  </p>
                </template>
              </template>
              <template v-else>
                {{ formatData(row, col) }}
              </template>
            </template>
          </el-table-column>
        </template>

        <el-table-column
          label="操作"
          fixed="right"
          :width="actions.length * 80"
          v-if="actions.length"
        >
          <template slot-scope="{ row }">
            <el-button
              :type="n.type || 'primary'"
              @click="n.click(row)"
              size="mini"
              plain
              v-for="(n, i) in actions"
              :key="i"
              >{{ n.label }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="ui-page">
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :total="page.total"
        :page-sizes="[100, 500, 1000]"
        :page-size="page.size"
        :current-page="page.no"
        @current-change="onPageChange"
        @size-change="onSizeChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="账号管理"
      :visible.sync="isShow"
      width="50%"
      v-if="!isReset"
      @closed="onClosed"
    >
      <el-form :model="form" ref="form" label-width="80px" class="ui-form">
        <el-form-item label="角色" prop="role" :rules="formRules.select">
          <el-select v-model="form.role" placeholder="请选择">
            <el-option
              v-for="(n, i) in roleList"
              :key="i"
              :label="n.get('label')"
              :value="n.get('name')"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账号名"
          prop="username"
          :rules="formRules.userName"
        >
          <el-input
            v-model="form.username"
            placeholder="请输入英文,注册后不可修改"
            :disabled="!!currentItem.id"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname" :rules="formRules.text">
          <el-input v-model="form.nickname" placeholder="请输入中文"></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
          :rules="formRules.password"
          v-if="!currentItem.id"
        >
          <el-input v-model="form.password" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button type="primary" @click="onSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      isReset: false,
      isLoading: false,
      form: {},
      page: {
        no: 1,
        size: 100,
        total: 0,
      },
      roleList: [],
      role: [
        { label: '超级管理员', value: 'supueradmin' },
        { label: '管理员', value: 'admin' },
        { label: '员工', value: 'user' },
        { label: '财务', value: 'finance' },
        { label: '代理商', value: 'agent' },
      ],
      dataList: [],
      expressInfo: {},
      storeInfo: {},
      clientInfo: {},
      companyInfo: {},
      profile: {},
      currentParams: {},
      params: {},
      defaultSort: { order: 'descending', prop: 'updatedAt' },
      actions: [
        {
          label: '修改',
          click: (item) => {
            this.showEdit(item)
          },
        },
        {
          label: '删除',
          type: 'danger',
          click: (item) => {
            this.$confirm('确认删除?').then(() => {
              this.AV.Cloud.run('deleteUser', {
                username: item.get('username'),
              }).then((ret) => {
                console.log(ret)
              })
              item.destroy().then(() => {
                this.$message.success('删除成功!')
                this.query()
              })
            })
          },
        },
      ],
      columns: [
        {
          label: '账号',
          prop: 'username',
          show: ['table', 'form'],
        },
        {
          label: '昵称',
          prop: 'nickname',
          show: ['table', 'form'],
        },
        {
          label: '角色',
          prop: 'role',
          show: ['table', 'form'],
          format: (item) => {
            let roles = item.get('roles')
            if (roles && roles.length) {
              let cRoles = this.roleList.filter((n) =>
                roles.includes(n.get('name'))
              )
              return cRoles.map((n) => n.get('label')).join(',')
            }
          },
        },
      ],
      currentItem: {},
    }
  },

  computed: {
    tableHeight() {
      if (this.dataList.length) {
        return window.innerHeight - 170
      }
      return 0
    },
  },
  methods: {
    getRoles() {
      let query = new this.AV.Query(this.AV.Role)
      query.find().then((list) => {
        this.roleList = list
      })
    },
    create() {
      //新建角色
      var roleAcl = new this.AV.ACL()
      roleAcl.setPublicReadAccess(true)
      roleAcl.setPublicWriteAccess(true)

      //新建角色
      var administratorRole = new this.AV.Role('Client', roleAcl)
      administratorRole
        .set('label', '客户')
        .save()
        .then(function (role) {
          console.log(role)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    onClosed() {
      this.isReset = true
      this.$nextTick(() => {
        this.isReset = false
      })
    },
    onPageChange(page) {
      this.page.no = page
      this.query()
    },
    onSizeChange(size) {
      this.page.size = size
      this.query()
    },
    formatData(item, col) {
      if (col.format) {
        return col.format(item)
      } else {
        return item.get(col.prop)
      }
    },
    formatDate(d) {
      if (!d) {
        return ''
      }
      let fm = (n) => {
        return n < 10 ? '0' + n.toString() : n.toString()
      }
      let arr = []
      arr.push(d.getFullYear())
      arr.push(fm(d.getMonth() + 1))
      arr.push(fm(d.getDate()))
      return arr.join('-')
    },
    onSortChange({ prop, order }) {
      this.defaultSort = { prop, order }
      this.page.no = 1
      this.query()
    },
    query() {
      this.isLoading = true
      let query = new this.AV.Query('profile')
      query[this.defaultSort.order](this.defaultSort.prop)
      query.include('User')
      let keys = Object.keys(this.params)
      keys.forEach((key) => {
        let val = this.params[key]
        if (val) {
          query.contains(key, val)
        }
      })

      query.limit(this.page.size)
      query.skip(this.page.size * (this.page.no - 1))

      query.find().then((list) => {
        this.dataList = list
        this.isLoading = false
      })
      query.count().then((count) => {
        this.page.total = count
      })
    },

    showNew() {
      this.$prompt('请输入管理密码').then(({ value }) => {
        if (this.password !== value) {
          this.$message.error('密码错误')
          return
        }
        this.currentItem = {}
        this.form = {}
        this.isShow = true
      })
    },
    showEdit(item) {
      this.currentItem = item
      this.columns.forEach((n) => {
        if (n.show.includes('form')) {
          this.$set(this.form, n.prop, item.get(n.prop))
        }
      })
      if (item.get('roles')) {
        const role = item.get('roles')[0]
        this.$set(this.form, 'role', role)
      }
      this.isShow = true
    },

    checkName(name) {
      let query = new this.AV.Query('storeInfo')
      query.equalTo('name', name)
      return query.first().then((ret) => {
        return ret ? true : false
      })
    },

    onSave() {
      let postData = { ...this.form }

      this.$refs.form.validate().then((valid) => {
        if (valid) {
          // 修改已有用户
          if (this.currentItem.id) {
            let user = this.currentItem.get('User')
            this.AV.Cloud.run('userUpdate', {
              username: user.get('username'),
              role: postData.role,
              roles: [postData.role],
            }).then((ret) => {
              console.log('ret', ret)
            })
            // 保存可以修改的部分
            this.currentItem
              .save({
                role: postData.role,
                roles: [postData.role],
                nickname: postData.nickname,
              })
              .then(() => {
                this.$message.success('修改成功')
                this.isShow = false
                this.query()
              })
            return
          }

          let role = this.roleList.find((n) => n.get('name') == postData.role)
          let user = new this.AV.User()
          user.setUsername(postData.username)
          user.setPassword(postData.password)
          user.set('roles', [postData.role])
          user.set('role', postData.role)
          user.signUp().then(
            (loggedInUser) => {
              // 用户关联角色
              role.getUsers().add(loggedInUser)
              role.save().then(() => {
                let profile = new this.profile()
                profile
                  .save({
                    User: loggedInUser,
                    username: postData.username,
                    nickname: postData.nickname,
                    roles: [postData.role],
                  })
                  .then(() => {
                    this.AV.User.logOut()
                    this.AV.User.logIn(
                      localStorage.username,
                      localStorage.password
                    ).then(() => {
                      this.query()
                    })
                  })
                this.$message.success('添加成功')
                this.isShow = false
              })
            },
            (error) => {
              let message = '提交信息有误'
              if (error.code == 202) {
                message = '账号名已被使用'
              }
              this.$message.error(message)
            }
          )
        }
      })
    },
  },

  mounted() {
    this.expressInfo = this.AV.Object.extend('expressInfo')
    this.storeInfo = this.AV.Object.extend('storeInfo')
    this.clientInfo = this.AV.Object.extend('clientInfo')
    this.companyInfo = this.AV.Object.extend('companyInfo')
    this.profile = this.AV.Object.extend('profile')
    this.query()
    this.getRoles()
  },
}
</script>

<style lang="less">
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
}
</style>
