<template>
  <div>
    <el-container>
      <el-header class="ui-header" height="50px">
        <el-row>
          <ElCol :span="12">
            <div class="logo">ATM CARGO</div>
          </ElCol>
          <ElCol :span="12" class="ui-topmenu" v-if="hasLogged">
            <span>当前版本: {{ version }}</span>
            <span>{{ currentUser.get('username') }}</span>
            <a href="javascript:;" @click="logout">退出</a>
          </ElCol>
        </el-row>
      </el-header>

      <el-container>
        <!-- 已登录 -->
        <template v-if="hasLogged">
          <el-aside width="120px">
            <el-menu router :default-active="defaultActive">
              <template v-for="(m, idx) in menus">
                <template v-if="m.sub && m.role && checkRole(m.role)">
                  <el-submenu :index="m.path" :key="idx">
                    <template slot="title">
                      <span>{{ m.label }}</span>
                    </template>

                    <el-menu-item
                      :index="item.path"
                      v-for="item in m.sub"
                      :key="item.path"
                    >
                      {{ item.label }}
                    </el-menu-item>
                  </el-submenu>
                </template>

                <template v-else>
                  <el-menu-item
                    :index="m.path"
                    :key="idx"
                    v-if="m.role && checkRole(m.role)"
                  >
                    {{ m.label }}
                  </el-menu-item>
                </template>
              </template>
            </el-menu>
          </el-aside>
          <el-main> <router-view v-if="isRefresh"></router-view> </el-main>
        </template>

        <!-- 未登录 -->
        <template v-else>
          <div class="ui-login">
            <el-form :model="form" label-width="120px" ref="form">
              <el-form-item
                label="账号"
                :rules="formRules.text"
                prop="username"
              >
                <el-input
                  v-model="form.username"
                  placeholder="请输入"
                  @keyup.enter.native="onLogin"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="密码"
                :rules="formRules.text"
                prop="password"
              >
                <el-input
                  v-model="form.password"
                  placeholder="请输入"
                  type="password"
                  @keyup.enter.native="onLogin"
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button type="primary" @click="onLogin">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import version from '../version'
export default {
  data() {
    return {
      loading: true,
      hasLogged: false,
      defaultActive: '',
      isRefresh: true,
      form: {},
      currentUser: null,
      version,
      menus: [
        {
          label: '订单管理',
          path: '/admin/list',
          role: ['Superadmin', 'admin', 'user', 'Admin', 'Staff'],
        },
        {
          label: '储位管理',
          path: '/admin/store',
          role: ['Superadmin', 'admin', 'user', 'Admin', 'Staff'],
        },
        {
          label: '出货管理',
          path: '/admin/cargo',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '财务管理',
          path: '/admin/finance',
          role: ['Superadmin', 'admin', 'finance', 'Admin', 'Finance', 'Agent'],
        },
        {
          label: '积分管理',
          path: '/admin/credit',
          role: ['Superadmin', 'admin', 'finance', 'Admin', 'Finance'],
        },
        {
          label: '客户管理',
          path: '/admin/client',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '供应商管理',
          path: '/admin/company',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '品名管理',
          path: '/admin/productType',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '装箱单管理',
          path: '/admin/package',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '账号管理',
          path: '/admin/account',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '更新文档',
          path: '/admin/article',
          role: ['Superadmin', 'admin', 'Admin'],
        },
        {
          label: '系统设置',
          path: '/admin/setting',
          role: ['Superadmin', 'admin', 'Admin'],
          sub: [
            {
              label: '客户地址',
              path: '/admin/setting/country',
            },
            {
              label: '物流设置',
              path: '/admin/setting/express',
            },
            {
              label: '分类设置',
              path: '/admin/setting/category',
            },
            {
              label: '单价设置',
              path: '/admin/setting/price',
            },
            {
              label: '积分设置',
              path: '/admin/setting/credit',
            },
          ],
        },
      ],
    }
  },

  provide() {
    return {
      refresh: () => {
        this.isRefresh = false
        this.$nextTick(() => {
          this.isRefresh = true
        })
      },
    }
  },

  computed: {
    currentRole() {
      if (this.currentUser) {
        return this.currentUser.get('role')
      }
      return ''
    },
  },

  methods: {
    checkRole(role) {
      // if (this.currentRole) {
      //   return role.includes(this.currentRole)
      // }
      if (this.currentUser.get('roles')) {
        let roles = this.currentUser.get('roles')
        return roles.some((r) => {
          return role.includes(r)
        })
      }
      return false
    },
    checkVersion() {
      let query = new this.AV.Query('config')
      query.equalTo('key', 'version')
      query.subscribe().then((subscription) => {
        subscription.on('update', (item) => {
          if (item.get('value') > this.version) {
            setTimeout(() => {
              this.$notify.info({
                title: '系统更新',
                message: '系统有新的版本，点击刷新页面',
                showClose: false,
                duration: 0,
                onClick: () => {
                  location.reload()
                },
              })
            }, 3 * 60 * 1000)
          }
        })
      })
    },
    logout() {
      this.AV.User.logOut()
      this.hasLogged = false
      // this.$router.replace({ path: '/admin' })
    },
    onLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let { username, password } = this.form
          localStorage.username = username
          localStorage.password = password
          this.AV.User.logIn(username, password).then(
            (loggedInUser) => {
              // 记录设备登录token
              const token = Math.random().toString(36).slice(-12)
              localStorage.loginToken = token
              this.hasLogged = true
              loggedInUser.save({ loginToken: token })
              this.currentUser = loggedInUser
              // this.$router.replace('/admin')
            },
            () => {
              this.$message.error('账号或密码错误, 登录失败')
            }
          )
        }
      })
    },
  },

  mounted() {
    localStorage.lastLoginTime = new Date().getTime()
    this.defaultActive = this.$route.fullPath
    let currentUser = this.AV.User.current()

    // 判断当前账号是否已登录
    if (currentUser) {
      currentUser.isAuthenticated().then((authenticated) => {
        // 判断当前账号是否已删除
        if (authenticated) {
          // 获取最新的用户信息
          currentUser.fetch().then((user) => {
            if (localStorage.loginToken !== user.get('loginToken')) {
              this.$message.error('您的账号已在其他设备登录，请重新登录')
              return
            }
            this.hasLogged = true
            this.currentUser = user
            this.checkVersion()
            this.loading = false
          })
        }
      })
    }
    this.$store.dispatch('getProductType')
    this.$store.dispatch('getConfig')
    // this.$store.dispatch("getStoreList");
  },
}
</script>

<style lang="less">
.el-main {
  padding: 10px 10px !important;
  background: #fff;
  margin: 0 0 0 20px;
}
.el-container {
  background: #f0f2f5;
}
.ui {
  &-header {
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
  }
  &-topmenu {
    text-align: right;
    font-size: 14px;
    span {
      display: inline-block;
      padding: 0 20px;
    }

    a {
      color: #fff;
    }
  }
  &-main {
    .file {
      position: relative;
      overflow: hidden;
      input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-page {
    padding: 10px 0 0;
    text-align: right;
  }
  &-form {
    .el-select {
      display: block;
    }
  }
  &-login {
    padding: 100px 0 0;
    width: 300px;
    margin: 0 auto;
  }
}

.el-submenu .el-menu-item {
  min-width: auto !important;
}

.el-aside {
  border-right: 1px solid #eee;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fff;

  .el-menu {
    border: none !important;
  }

  .el-menu-item,
  .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
  }
}
.ui-table {
  &-th {
    background: #fafafa !important;
    font-weight: bold !important;
    color: #000;
  }

  .el-button--mini {
    padding: 3px 8px;
  }
}
::-webkit-scrollbar-track-piece {
  //滚动条凹槽的颜色，还可以设置边框属性
  background-color: #fff;
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 4px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  //滚动条的设置
  background-color: #eee;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
</style>
